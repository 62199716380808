import { useReducer } from "react";
import SystemContext from "./appContext";

import { AppManager } from "../../TypeScript/AppManager/AppManager.ts";
import getConfiguration from "./config/config.js";

// Configuracion por cliente
const SystemState = (props) => {
  const _Config = getConfiguration(); // Que cliente es y si esta en produccion
  const initialState = {
    configuration: _Config, // Obtenemos la configuracion por cliente

    // app manager   
    AppManager: new AppManager({
      urlServer: _Config.url,
      loginByCredential: _Config.showIdentificaciónBtn,
      publicidad: _Config.verPublicidad,
      produccion: _Config.production,
      withHeaders: _Config.withHeaders,
    }),
  };
  const [state, dispatch] = useReducer({}, initialState);

  return (
    <SystemContext.Provider
      value={{
        AppConfiguration: state.configuration,
        AppManager: state.AppManager,
      }}
    >
      {props.children}
    </SystemContext.Provider>
  );
};
export default SystemState;
