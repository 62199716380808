import React, { useContext, useEffect ,useState} from "react";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import { Avatar, Button, CircularProgress } from "@mui/material";
import profileImg from "./icons/empresa.png";
import "./styles.css"
import InfoImg from "../Tables/img/info.png";
import EstadisticaCuentasPorCobrar from "../Modal/estadisticaCuentasPorCobrar.jsx";

function ProfileCompany({companyData}) {

  const [showModal, setshowModal]=useState(false)
  const { AppManager } = useContext(typeScriptContext);

  let TipoSangre = "loading"
  let FechaNacimiento = "loading"



  let {NIT= "loading",NombreEmpresa= "loading",Tel= "loading",Email= "loading",Departamento="loading",Municipio="loading"}=companyData;

 
  // Separar fecha
    FechaNacimiento=FechaNacimiento.split("T")[0]

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
    return () => {};
  }, [AppManager.translator]);

  return (
    <div className="card h-100" style={{ borderRadius: "25px" }}>
      <div
      id="profileHeader"
        style={{
          display: "flex",
          alignItems: "center",
          padding: 10,
          paddingRight: 25,
          marginTop:20
        }}
      >
       
       <img
        alt="profile-img"
        src={profileImg}
        className="me-2 slide-in-top" 
        width="160rem"
      />
       
        <h5
          style={{
            overflowWrap: "break-word",
            
            maxWidth:"60%",
            color: "rgb(19, 37, 60) !important",
          }}
        >
          <strong style={{fontSize:22}}>  {NombreEmpresa}</strong>
          <br></br>
       
        </h5>
        <img src={InfoImg} alt="" width={25} style={{position:"absolute",right:0,marginRight:40,cursor:"pointer"}} onClick={()=>{
          setshowModal(true)
        }}/>
      </div>
    
        <div
          id="perfilCreciente"
          style={{
            paddingTop: "1rem",
            paddingLeft: "4rem",
            overflow: "hidden",
            height: "55%",
          }}
        >
          <strong style={{ marginRight: 5 }} >NIT</strong>
          <p className="colorP" style={{ fontSize: 18}}>{NIT} </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Departament">Loading...</strong>
          <p className="colorP" style={{ fontSize: 18}}>{Departamento}</p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Municipio">Loading...</strong>
          <p className="colorP" style={{ fontSize: 18}}>{Municipio}   </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Email">Loading...</strong>
          <p className="colorP" style={{ fontSize: 18 }}>{Email} </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Phone">Loading...</strong>
          <p className="colorP" style={{ fontSize: 18}}>  {Tel}   </p>
        

        </div>
      
         
      <div
        style={{ display: "flex", justifyContent: "center", padding: "0.5rem" }}
      >
        <Button
          id="btnShowProfile"
          variant="contained"
          style={{
        
            borderRadius: "25px",
            width: "80%", 
          }}
          size="large"
          onClick={() => {
            AppManager.showAllProfile();
          }}
        >
          <span data-language_txt="txt_ShowProfile">VIEW FULL PROFILE</span>
        </Button>
        <Button
          id="btnHideProfile"
          variant="contained"
          style={{
            borderRadius: "25px",
            width: "80%", 
            display: "none",
          }}
          size="large"
          onClick={() => {
            AppManager.hideProfile();
          }}
        >
          <span data-language_txt="txt_HideProfile">HIDE FULL PROFILE</span>
        </Button>
      </div>

      {showModal ? (
        <EstadisticaCuentasPorCobrar closeModal={() => {
          setshowModal(false); 
        }}></EstadisticaCuentasPorCobrar>
      ) : (
        <></>
      )}
  
    </div>
  );
}

export default ProfileCompany;
