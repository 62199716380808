import React from "react";
import { useState, useEffect, useContext } from "react";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";

function EstadisticaCuentasPorCobrar({ closeModal }) {
  const { AppManager } = useContext(typeScriptContext);
  const [informacion, setInformacion] = useState(null);

  const setDOMURL = (data) => {
   // console.log(data["Empresa"]);
  //  console.log(data);
    setInformacion(data); //Local
  };
  prompt.setDOMURL = setDOMURL;

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
    AppManager.loadInformacionCarteraVencida(prompt);

    return () => {};
  }, [AppManager]);

  const parseDate = () => {
    let fecha=AppManager.translator.current_lang==="es"?"Sin fecha":"Undated" ;
    if(informacion.FechaUltimoPago&&informacion.FechaUltimoPago!=""){
      fecha=informacion.FechaUltimoPago.split("T")[0];
    }
    return fecha;
  };

  return (
    <div
      id="divShareData"
      className="modal fade-in"
      tabIndex="1"
      role="dialog"
      aria-hidden="true"
      style={{ backgroundColor: "#0000007a", display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "500px" }}>
        <div className="modal-content position-relative">
          <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 className="mb-1">Información</h4>
            </div>
            <div className="col-12 p-2">
              <div className="p-4  row" style={{ alignItems: "flex-end" }}>
                <label className="form-label">
                  Empresa: <span style={{ color: "#CB5C52" }}>{informacion ?  `${informacion.Empresa}` : "loading"}</span>
                </label>
                <div className="col-md-7" style={{ display: "flex", flexDirection: "column" }}>
                  <strong className="form-label" style={{ fontSize: 19 }}>
                    Cuenta Corriente
                  </strong>
                  <label className="form-label">
                    Total Pendiente:{" "}
                    <span style={{ color: "#CB5C52" }}>{informacion ?  `$${informacion.MontoPendienteCarteraCorriente}` : "loading"}</span>
                  </label>
                  <strong className="form-label" style={{ fontSize: 19 }}>
                    Cartera Vencida
                  </strong>
                  <label className="form-label">
                    Total Pendiente: <span style={{ color: "#CB5C52" }}>{informacion ? `$${informacion.MontoPendienteCarteraVencida}`  : "loading"}</span>
                  </label>
                </div>
                <div style={{ borderTop: "1px solid #dddd", display: "flex", flexDirection: "column",paddingTop:10 }}>
                  <label className="form-label">
                    Fecha de último pago: <span style={{ color: "#CB5C52" }}>{informacion ? parseDate() : "loading"}</span>
                  </label>
                  <label className="form-label">
                    Total pagado: <span style={{ color: "#E13020" }}>{informacion ? `$${informacion.MontoPendienteCarteraVencida}` : "loading"}</span>
                  </label>
                </div>
              </div>
            </div>

         
            <button
              className="btn btn-primary position-absolute mb-3 me-3 end-0 "
              type="button"
              style={{ backgroundColor: "#ff4848" }}
              onClick={() => {
                closeModal();
              }}
            >
              Salir
            </button>
            <div className="p-4 pb-0 row">
              <div className="mb-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstadisticaCuentasPorCobrar;
