import React, { useState, useContext, useEffect} from "react";  
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";  
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"; 
import parse from 'html-react-parser';

function EstudioCita({estudio,onCloseEstudio}) { 
 
  const { AppManager,AppConfiguration } = useContext(typeScriptContext); 
  const [isShowInstructions, setShowInstructions] = useState(0);   

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();  
    return () => {};
  }, [AppManager]);
    
  const showInstructions = () => {
    setShowInstructions(!isShowInstructions);
  };

  return (
    <div className="cardEstudio" style={{margin:5,width:"265px",height:"180px",maxHeight:"260px"}} id={estudio.idEstudio}> 
                                      <div className="cardSucursalSelected" style={{borderRadius:"5px 5px 0px 0px",justifyContent:"space-evenly",maxHeight:"80px",display:"flex",flexDirection:"row",padding:"5px"}}>
                                        <div style={{fontWeight:"bold",paddingLeft:5,width:"85%",overflow:"hidden",height:"25px",textOverflow:"ellipsis",textWrap:"nowrap",wordBreak:"break-word"}}>{estudio.Descripcion}</div>
                                        <button style={{width:"1.5rem", height:"1.5rem"}} className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" onClick={()=>onCloseEstudio(estudio.idEstudio)} aria-label="Close"/>
                                      </div> 
                                      <div style={{display:"flex",flex:"1 1 auto",alignItems:"center",background:"white"}}>
                                      {isShowInstructions ? ( 
                                          <div style={{display:"flex",flexDirection:"column"}}>
                                            <div onClick={showInstructions} className="colorP" style={{cursor:"pointer",padding:5,fontSize:12,display:"inline-flex",alignItems:"center"}}>
                                              <div>{AppManager.translator.current_lang === "es" ? "Ver preparación" : "Show instructions"}</div>
                                              <KeyboardArrowUpIcon />
                                            </div>
                                            <div className="scrollbarCustom" style={{height:"100px",overflowX:"hidden",overflowY:"auto",display:"inline-flex",flexWrap:"wrap",marginTop:10,padding:5}}>
                                            {estudio.Preparacion !== null && estudio.Preparacion !== undefined ? parse(estudio.Preparacion) : AppManager.translator.current_lang === "es" ? "Sin indicaciones" : "Without indications"}   </div>
                                          </div> 
                                      ) : ( 
                                          <div style={{display:"flex",flexDirection:"column"}}>
                                            <div onClick={showInstructions} className="colorP" style={{cursor:"pointer",padding:5,fontSize:12,display:"inline-flex",alignItems:"center"}}>
                                              <div>{AppManager.translator.current_lang === "es" ? "Ver preparación" : "Show instructions"}</div>
                                              <KeyboardArrowDownIcon />
                                            </div>
                                            <div style={{display:"flex",flexDirection:"column",marginTop:10,padding:5}}>
                                            <div style={{display:"inline-flex"}}><label data-language_lbl="lbl_Costo">Precio $:</label><label>{estudio.Precio?estudio.Precio:"0"}</label></div>
                                            <div style={{display:"inline-flex"}}><label data-language_lbl="lbl_Descuento2">Descuento $:</label><label>{estudio.Descuento?estudio.Descuento:"0"}</label></div>
                                            </div>
                                          </div> 
                                      )} 
                                      </div>
                                     
                                    </div> 
                                  );
}
export default EstudioCita;
