import React from "react";
import { useState, useEffect, useContext } from "react";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import Flatpickr from "react-flatpickr";
import Calendar from "../Tables/img/calendar.png";
let dayNow = new Date();


function EstadoDeCuentaModal({  closeModal,fechaInicio,fechaFin, }) {
  const { AppManager } = useContext(typeScriptContext);
  const [modo, setModo] = useState("todo");
  const [periodo, setPeriodo] = useState(9); //Año 
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusCopy, setStatus] = useState("Descargar");
  const [colorstate, setColor] = useState("btn-primary");


  //Fecha
  const [toDate, setToDate] = useState(fechaFin);
  const [fromDate, setFromDate] = useState(fechaInicio);



  useEffect(() => {
    if (isLoaded) return;
    
    AppManager.translator.changeLabelsTitle();
    setToDate(dayNow);
    setIsLoaded(true)

    return () => {};
  }, [AppManager]);


const onClick = () => {

    const handle = window.open("#/pdf", "_blank", "popUpWindow", "width=1350,height=950");
    
    handle.window.parameters = JSON.stringify({ idPlan: "58",fechaInicio:fromDate,fechaFin:toDate,tipo:modo });
  
}

  const closeShareData = () => {
    closeModal();
    document.getElementById("divShareData").style.display = "none";
    document.getElementById("divShareData").style.opacity = "0";
    setStatus("Copy URL");
    setColor("");
  };
  const onChangeSelectConcepto = (value) => {
    setModo(value.target.value);
   
  };
  const onChangePeriodo = (value) => {
    setPeriodo(value.target.value);

    switch (value.target.value) {
        case "1": //txt_oneDay
            
        setToDate(dayNow);
        setFromDate(dayNow);
  
        break;
        case "2": //txt_twoDays
        setToDate(dayNow);
           
        var prvDatetwDays = new Date(dayNow.getFullYear(),dayNow.getMonth(),dayNow.getDate()-2);
        setFromDate(prvDatetwDays);
        break;

        case "3": //txt_oneWeek
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth(),dayNow.getDate()-7);
        setFromDate(prvWeek);
  
        break;
        case "4": //txt_twoWeeks
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth(),dayNow.getDate()-14);
        setFromDate(prvWeek);
  
        break;
        case "5": //txt_threeWeeks
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth(),dayNow.getDate()-21);
        setFromDate(prvWeek);
        break;
        case "6": //Mes
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth()-1,dayNow.getDate());
        setFromDate(prvWeek);
        break;
        case "7": //txt_twoMonths
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth()-2,dayNow.getDate());
        setFromDate(prvWeek);
        break;
        case "8": //txt_threeMonths
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear(),dayNow.getMonth()-3,dayNow.getDate());
        setFromDate(prvWeek);
        break;
        case "9": //txt_oneYear
        setToDate(dayNow);
        var prvWeek= new Date(dayNow.getFullYear()-1,dayNow.getMonth(),dayNow.getDate());
        setFromDate(prvWeek);
        break;
      case "10": //txt_dateRange
      setToDate(dayNow);
      
      setFromDate("");
        break;

      default:
        break;
    }
  }
  return (
    <div
      id="divShareData"
      className="modal fade-in"
      tabIndex="1"
      role="dialog"
      aria-hidden="true"
      style={{ backgroundColor: "#0000007a", display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "600px" }}>
        <div className="modal-content position-relative">
          <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeShareData}
            ></button>
          </div>
          <div className="modal-body p-0">
            <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 className="mb-1" >
                Descargar el estado de cuenta
              </h4>
            </div>
            <div className="col-12 p-2">
              <div className="p-4  row" style={{ alignItems: "flex-end" }}>
                <div style={{ width: "50%" }}>
                  <label className="form-label" data-language_lbl="lbl_dateFilter" style={{ color: "#d12823" }}></label>

                  <select
                    id="selectPeriod"
                    className="form-select p-1"
                    onChange={onChangePeriodo}
                    style={{ borderRadius: 5, cursor: "pointer",marginBottom:4 ,backgroundImage: `url(${Calendar})`,backgroundRepeat: "no-repeat",backgroundSize: "20px",backgroundPosition: "left 10px center",paddingLeft: "40px"}}
                    value={periodo}
                  >

                    <option value="1" data-language_txt="txt_oneDay"></option>
                    <option value="2" data-language_txt="txt_twoDays"></option>
                    <option value="3" data-language_txt="txt_oneWeek"></option>
                    <option value="4" data-language_txt="txt_twoWeeks"></option>
                    <option value="5" data-language_txt="txt_threeWeeks"></option>
                    <option value="6" data-language_txt="txt_oneMonth"></option>
                    <option value="7" data-language_txt="txt_twoMonths"></option>
                    <option value="8" data-language_txt="txt_threeMonths"></option>
                    <option value="9" data-language_txt="txt_oneYear"></option>
                    <option value="10" data-language_txt="txt_dateRange"></option>
                  </select>
                </div>
                <div className="col-3 p-1">
                  <label className="form-label" data-language_lbl="lbl_date"></label>
                  <Flatpickr
                    className="form-control datetimepicker flatpickr-input"
                    value={fromDate}
                    options={{ dateFormat: "Y-m-d", disableMobile: false }}
                    onChange={([fromDate]) => {
                      setFromDate(fromDate)
                    }}
                  />
                </div>
                <div className="col-3 p-1">
                  <label className="form-label" data-language_lbl="lbl_toDate"></label>
                  <Flatpickr
                    className="form-control datetimepicker flatpickr-input"
                    value={toDate}
                    options={{ dateFormat: "Y-m-d", disableMobile: false }}
                    onChange={([toDate]) => {
                      setToDate(toDate)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="p-4 pb-0 mb-3 row">
              <div className="col-md-7">
                <label className="form-label" >
                  Seleccione el <strong>concepto estado de cuenta</strong> que desea consultar.
                </label>
              </div>
              <div className="col-md-4">
                <select className="form-select" required="" onChange={onChangeSelectConcepto} value={modo} style={{ width: "11rem" }}>
                  <option value="todo">{AppManager.translator.current_lang === "es" ? "Todos" : "All"}</option>
                  <option value="todo">{AppManager.translator.current_lang === "es" ? "Cuenta Corriente" : "Cuenta Corriente"}</option>
                  <option value="todo">{AppManager.translator.current_lang === "es" ? "Cartera Vencida" : "Cartera Vencida"}</option>
                </select>
              </div>
            </div>

            <div className="p-4 pb-0 row">
              <div className="mb-3">
               
                  <button
                  
                    className={`btn ${colorstate} d-block mb-3 me-3 position-absolute bottom-0 end-0`}
                    type="submit"
                    onClick={onClick}
                  >
                    {statusCopy}
                  </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstadoDeCuentaModal;
