import React, { useState, useContext, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CircularProgress from "@mui/material/CircularProgress";

import typeScriptContext from "../../../../../AppState/SystemConfig/appContext";


import img from "./img/ready.png";
import eye from "./img/eye.png";
import eye2 from "./img/eye2.png";
import sede from "../../../img/sede.png";
import "./styles.css";
import { Chip } from "@mui/material";

function ModalSedes({  }) {

  
  const { AppManager,AppConfiguration } = useContext(typeScriptContext);
  const [ready, setReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [validated, setValidate] = useState("");
  const [display, setDisplay] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
   
    return () => {};
  }, [AppManager]);

  const setStatusPassword = (response) => {
    setloading(false);
    setReady(response); //Local
  };
  prompt.setStatusPassword = setStatusPassword;

  const closeChangePassword = () => {
    document.getElementById("divSedes").style.display = "none";
    document.getElementById("divSedes").style.opacity = "0";
   
    setValidate("");
    setDisplay("");
    if (ready) {
      AppManager.clearToken();
    }
    setReady(false);
  };
  
  if(!AppManager.sedesURLS || AppManager.sedesURLS.lenght==0){
    // alert("No se han cargado las sedes")
    return <></>
  }

  return (
    <div className={AppConfiguration.theme}>
      <div
        id="divSedes"
        className="modal container-fluid fade-in top-50 position-absolute start-50 translate-middle"
        style={{
          display: "none",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.48)",
        }}
      >
        <div
          className="row justify-content-center pt-6"
          style={{ alignItems: "center", height: "100%" }}
        >
          <div className="col-sm-10 col-lg-7 col-xxl-5">
            <div className="card theme-wizard mb-5" id="wizard">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeChangePassword}
                ></button>
              </div>
              <div className="card-header bg-light pt-3 pb-2">
                <ul className="nav justify-content-between">
                  <li className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-cir" style={{backgroundColor:"transparent"}}>
                        <img
                        src={sede}
                        alt="ready"
                        className="fade-in"
                        style={{ width:"60px"}}
                      ></img>
                        </span>
                      </span>
                      <span
                        data-language_txt="txt_Sede_title"
                        className="d-md-block  colorP"
                        style={{ marginTop:"25px ",marginLeft:"5px",fontSize:"20px !important",fontWeight:"bold"}}
                      >
                        Lista de Sedes
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body py-4" id="wizard-controller">
                <div className="tab-content" style={{overflow:"auto",height:450}}>
                  <div
                    className="tab-pane active px-sm-3 px-md-5"
                    role="tabpanel"
                    aria-labelledby="bootstrap-wizard-tab1"
                    id="bootstrap-wizard-tab1"
                    style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}  
                  >

                     <div className="modalCardSedes">
                        <div style={{display:"flex",flexDirection:"column"}}>
                          <span style={{color:"#3B4DF1",fontWeight:"bold"}}><strong style={{color:"#717171"}}>Nombre: </strong> Principal </span>
                          <span style={{color:"#b9b9b9" ,fontSize:16}}> <strong style={{color:"#717171"}}>Url: </strong> { AppManager.urlServicio} </span>
                          <div style={{display:"flex",alignItems:"center"}}>
                          <strong style={{color:"#717171",marginRight:5}}>Status: </strong> <Chip label="Activo" color="success" style={{fontSize:14}}/>
                        </div>
                        </div>
                      
                       
                      </div>


                     {AppManager.sedesURLS.map((sede) => {
                      return (
                        <div className="modalCardSedes" key={`${sede.Nombre}-${sede.IdServidor}`}>
                        
                         
                        <div style={{display:"flex",flexDirection:"column"}} >
                          <span style={{color:sede.Activo? "#377C34":"#CB3934",fontWeight:"bold"}}><strong style={{color:"#717171"}}>Nombre: </strong> {sede.Nombre} </span>
                          <span style={{color:"#b9b9b9",fontSize:16}}> <strong style={{color:"#717171"}}>Url: </strong> {sede.UrlServicio} </span>
                          <div style={{display:"flex",alignItems:"center"}}>
                          <strong style={{color:"#717171",marginRight:5}}>Status: </strong><Chip label= {sede.Activo? "Activo":"Inactivo"} color={sede.Activo?"success":"error"} style={{fontSize:14}}/>
                        </div>
                        </div>
              
                       
                       
                      </div>
                      )
                     })}
                   
                  </div>
                </div>
              </div>
              <div className="card-footer bg-light" style={{ height: "4rem" }}>
                <div className="px-sm-3 px-md-5">
                  <button
                    
                    className="btn btn-primary position-absolute mb-3 me-3 end-0 "
                    type="button"
                    // onClick={onClick}
                  onClick={closeChangePassword}
                  >
                    Aceptar
                    {loading ? (
                      <CircularProgress
                        size={20}
                        style={{ marginLeft: 15 }}
                      ></CircularProgress>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSedes;
