import React from 'react'
import { useState } from 'react';

function DownloadData({folio,modalidad,fecha,userFolio,urlDescarga}) {
  const [selectValue ,setValue]=useState("DICOM")

  const Descargar = ()=>{
   
     
    window.open(`${urlDescarga}?rutaEstudio=${userFolio}\\${fecha}\\${modalidad}\\${folio}&nombrePaciente=Expediente&Formato=${selectValue}&incluirReporte=false&incluirImagenes=true&incluirImagenesClave=false`, '_blank').focus();
  }
  const closeShareData = () => {   
    document.getElementById("divDownloadData").style.display = "none";    
    document.getElementById("divDownloadData").style.opacity = "0";   
  };
    // openInNewTab(
                                //   `${urlDescarga}?rutaEstudio=${userData.Folio}\\${fecha}\\${row.PrimerModalidad}\\${row.Folio}&nombrePaciente=Expediente&Formato=ZIP&incluirReporte=true&incluirImagenes=true&incluirImagenesClave=false`
                                //   ,false);
 return ( 
  <div id="divDownloadData" className="modal fade-in" tabIndex="1" role="dialog" aria-hidden="true" style={{backgroundColor:"#0000007a"}}>
  <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:"500px"}}>
    <div className="modal-content position-relative">
      <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" onClick={closeShareData}></button>
      </div>
      <div className="modal-body p-0">
        <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h4 className="mb-1" data-language_txt="txt_downloadInfo">Descarga de Información</h4>
        </div>
        <div className="p-4 pb-0 row">  
          <div className="col-md-7">
            <label className="form-label" data-language_lbl="lbl_descDownload" style={{marginTop:25}}>Seleccione el formato en el que te gustaría descargar los datos.</label>     
          </div>      
          <div className="col-md-4">
            <label className="form-label" htmlFor="validationCustom04" data-language_lbl="lbl_format">Formato:</label>
            <select className="form-select" required="" onChange={(event)=>{setValue(event.target.value);}} value={selectValue} style={{width:"11rem"}}>
              <option >DICOM</option>
              <option >JPG</option>
              <option >BMP</option>
    
            </select> 
          </div>

      </div>   
      <div className="p-4 pb-0 row">  
         
      </div>
      <div className='p-4 pb-0 row'>
        <div className="mb-3">
            <button data-language_btn="btn_Download" className="btn btn-primary d-block mb-3 me-3 position-absolute bottom-0 end-0" type="submit" onClick={Descargar}>Descargar</button>
         </div>
      </div>    
      </div> 
    </div>
  </div>
</div>
  ) 
}

export default DownloadData