import React, { useContext, useEffect, useState } from 'react'

import Signin from './components/Signin/signin';
import Retrieve from './components/retrievePassword';
import ConfirmRetrieve from './components/confirmRetrieve';
import MsgError from '../../Global Components/Modal/msgErrorConnection' 
import MsgDataError from '../../Global Components/Modal/msgErrorData'
import MsgInfo from '../../Global Components/Modal/msgInfo' 
import SystemContext from '../../AppState/SystemConfig/appContext';
import {isMobile} from "react-device-detect"; 
import AgendaCita from './components/Citas/agendaCita';
import ChangePassword from './components/changePassword/changePassword';
import CircleIcon from '@mui/icons-material/Circle';
import ModalFacturacion from './components/Facturacion/modalFacturacion';
import ModalConsultaFacturacion from './components/Facturacion/modalConsultaFacturacion';


export default function Login() {
  const {AppManager,AppConfiguration} =useContext(SystemContext);
  const [retrieve, setretrieve] = useState(false); //Renderizado de recuperar contraseña
  const [confirmRet, setconfirmRet] = useState(false); //Renderizado de recuperar contraseña
  const [showCitas, setShowCitas] = useState(false); // Mostrar
  const [showPasswordModal, setshowPasswordModal] = useState(false); //Mostrar citas  
  const [showInstruccionesFacturacion, setShowInstruccionesFacturacion] = useState(false); 
  const [showModalFacturacion, setShowModalFacturacion] = useState(false); 
  const [showModalConsultaFacturacion, setShowModalConsultaFacturacion] = useState(false); 
  const [datosFacturacion, setDatosFacturacion] = useState();  

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
    if(  (!AppManager.sedesURLS||  AppManager.sedesURLS.length==0) && !AppManager.sedesloader &&AppConfiguration.nombre=="CEDISA"  ){
      AppManager.initSedes(false);
    }
    return () => {
    }
  }, [AppManager.translator])



  function showTermsPrivacity(){
    document.getElementById("divInfoMessage").style.display = "block";    
    document.getElementById("divInfoMessage").style.opacity = "1";  
  }

  function muestraDatosFacturacion(data){
    setShowModalFacturacion(true);
    setDatosFacturacion(data); 
  }

  function muestraVentanaConsultaFacturacion(){
    setShowModalConsultaFacturacion(true); 
  }
  
  return (
    <div style={{display:"grid"}} className={AppConfiguration.theme}>
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <div className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center id-card-gradient">
                    {/* INDICACIONES DE FACTURACION */}
                    {showInstruccionesFacturacion ?  
                      <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                        <div className="bg-holder bg-auth-card-shape backgroundImage">
                        </div>
                        {isMobile ?
                        <div className="z-index-1 position-relative mb-2 mt-4">
                          <img src={AppConfiguration.logo} alt="" style={{maxWidth:200,maxHeight:200}}></img>
                        </div>:

                        <div className="z-index-1 position-relative" style={{display:"flex",flexDirection:"column"}}>
                          <img src={AppConfiguration.logo} alt="" style={{maxWidth:200,maxHeight:200}}></img>
                          <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",textAlign:"left"}}> 
                            <p data-language_txt="txt_Requisitos" className="mb-1 mt-4 fw-semi-bold textGradient"></p>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1 mt-4">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito1Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito2Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito3Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito4Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito5Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito6Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito7Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            <div style={{display:"flex",alignItems:"center"}} className="mb-1">
                              <CircleIcon className='bulletList' style={{fontSize:"small"}}></CircleIcon>
                              <p data-language_txt="txt_Requisito8Factura" className="opacity-75 textGradient" style={{marginBottom:"2px",marginLeft:"5px",fontSize:"0.9rem"}}></p>
                            </div>
                            </div>
                          </div>
                        }
                      </div>:
                    /* MENSAJE DE BIENVENIDA */ 
                      <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                        <div className="bg-holder bg-auth-card-shape backgroundImage">
                        </div>
                        {isMobile ?
                        <div className="z-index-1 position-relative mb-2 mt-4">
                          <img src={AppConfiguration.logo} alt="" style={{maxWidth:200,maxHeight:200}}></img>
                        </div>:
                        <div className="z-index-1 position-relative"><img src={AppConfiguration.logo} alt="" style={{maxWidth:200,maxHeight:200}}></img>
                        <h4 data-language_txt="txt_MessageWelcome" className="mb-0 mt-4 fw-semi-bold textGradient"></h4>
                              <p data-language_txt={AppConfiguration.nombre==="CEDISA"?"txt_MessageCedisa1":"txt_Message1"} style={{marginTop:"1rem",fontSize: "0.9rem"}}  className="opacity-75 textGradient"></p>
                              <p data-language_txt={AppConfiguration.nombre==="CEDISA"?"txt_MessageCedisa2":"txt_Message2"} style={{marginTop:"1rem",fontSize: "0.9rem" }} className="opacity-75 textGradient"></p>
                        </div>
                        }
                      </div> 
                    }

                    {isMobile ?
                    <div className="mt-2 mb-2 mt-md-4 mb-md-5 light" style={{cursor:"pointer"}}>
                      <a data-language_txt="txt_MessageTerms" onClick={()=>showTermsPrivacity()} className="text-decoration-underline text-white mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold textGradient opacity-75">
                      </a>
                    </div> :
                    <div className="mt-3 mb-4 mt-md-4 mb-md-5 light" style={{cursor:"pointer"}}>
                      <a data-language_txt="txt_MessageTerms" onClick={()=>showTermsPrivacity()} className="text-decoration-underline text-white mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold textGradient opacity-75">
                      </a>
                    </div>}
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                
                    <div className="p-4 p-md-5 flex-grow-1">
                     {retrieve? 
                     <Retrieve 
                        backForgot={()=>{setretrieve(false);setconfirmRet(false)}} 
                        openConfirmRet={()=>{setretrieve(false);setconfirmRet(true)}}>
                     </Retrieve>:<></>}
                     {confirmRet? 
                     <ConfirmRetrieve backForgot={()=>{
                                        setretrieve(false);
                                        setconfirmRet(false)}}
                                      backRetrieve={()=>{
                                        setretrieve(false);
                                        setconfirmRet(false)}}>
                    </ConfirmRetrieve>:<></>}
                    {!retrieve && !confirmRet ?
                      <Signin
                         showIndicacionesFacturacion={(value)=>setShowInstruccionesFacturacion(value)}
                         showModalFacturacion={(value)=>muestraDatosFacturacion(value)}
                         showModalConsultaFacturacion={muestraVentanaConsultaFacturacion}
                         setShowCitas={()=>{setShowCitas(true)}}
                         openForgot={()=>{setretrieve(true)}}>
                      </Signin>:<></>} 
                    <div style={{width:"100%",display:"flex",justifyContent:"space-between",fontSize:12}}>
                    {/* <div >
                      <span style={{marginRight:5}} id='sedesLoaderTxt'>Cargando Sedes....</span>
                    <CircularProgress size={15} id="sedesLoader"></CircularProgress>
                    </div> */}
                      <span>Versión: <strong>{AppConfiguration.version}</strong></span>
                      
                      </div>
                    
                    </div>
                   
                  </div>
                 
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    {showCitas&& AppConfiguration.agendarCitas?<AgendaCita closeModalCita={()=>{setShowCitas(false)}} openModalPassword={()=>setshowPasswordModal(true)}></AgendaCita>:<></>}
    {showPasswordModal&& AppConfiguration.agendarCitas?<ChangePassword closeModalPassword={()=>setshowPasswordModal(false)}></ChangePassword>:<></>}
    {showModalFacturacion && AppConfiguration.facturacion?<ModalFacturacion datos={datosFacturacion} closeModalFacturacion={()=>setShowModalFacturacion(false)}></ModalFacturacion>:<></>}
    {showModalConsultaFacturacion && AppConfiguration.facturacion?<ModalConsultaFacturacion closeModalConsultaFacturacion={()=>setShowModalConsultaFacturacion(false)}></ModalConsultaFacturacion>:<></>}
    <MsgDataError></MsgDataError>
    <MsgError></MsgError>
    <MsgInfo></MsgInfo>
  
    </div>
  )  
}

  
