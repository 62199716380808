// @ts-ignore
import { MyUtilities } from "./MyUtilities.ts";

export class ManagerIdiomas {
  current_lang: string;

  dictionary_lbl = {
    lbl_sesion: {
      es: "Iniciar Sesión",
      en: "Login",
    },
     lbl_usuario: {
      es: "Usuario",
      en: "User",
    },
    lbl_password: {
      es: "Contraseña",
      en: "Password",
    },
    lbl_remember: {
      es: "Recordarme",
      en: "Remember me",
    },
    lbl_captureEmail:{
      es: "Ingresa tu correo:",
      en: "Enter your email:",
    },
    lbl_Duration:{
      es:"Duración",
      en:"Duration",
    },

    lbl_AllStudies:{
      es:"Todos los estudios",
      en:"All studies",
    },
    lbl_titleSendLink:{    
        es:"Capture su correo electrónico para enviar un enlace:",
        en:"Type your email to send a link:",
    },
    lbl_SendLink:{
      es:"Te hemos enviado un enlace de recuperación a:",
      en:"We have sent you a recovery link to:",
    },
    lbl_Email:{
      es: "Correo*",
      en: "Email*",
    },    
    lbl_PasswordC:{
      es:"Contraseña*",
      en:"Password*",
    },
    lbl_ConfirmPassword:{
      es:"Confirmar contraseña*",
      en:"Confirm Password*",
    },
    lbl_format:{
      es:"Formato:",
      en:"Format:",
    },
    lbl_descDownload:{
      es:"Seleccione el formato en el que te gustaría descargar los datos.",
      en:"Select the format in which you would like to download the data.",
    },
    lbl_userId:{
      es:"Identificación",
      en:"Identification",
    },
    lbl_date:{
      es:"De:",
      en:"From:",
    },
    lbl_dateFilter:{
      es:"Seleccione el periódo:",
      en:"Select range date:",
    },
    lbl_toDate:{
      es:"A:",
      en:"To:",
    },
    lbl_selection:{
      es:"Seleccionar",
      en:"Select",
    },
    lbl_Cedula:{
      es:"Cédula de Ciudadanía*",
      en:"Citizenship card*",
    },
    lbl_Nombre:{
      es:"Nombre*",
      en:"Name*",
    },
    lbl_PrimerNombre:{
      es:"Primer Nombre*",
      en:"First Name*",
    },
    lbl_Telefono:{
      es:"Teléfono*",
      en:"Phone*",
    },    
    lbl_Prev:{
      es:"Anterior",
      en:"Prev",
    },
    lbl_Next:{
      es:"Siguiente",
      en:"Next",
    },
    lbl_Poliza:{
      es:"Póliza",
      en:"Insurance policy",
    },
    lbl_SeleccionSucursal:{
      es:"Seleccione la sucursal de su preferencia",
      en:"Select the sucursal of your preference",
    },
    lbl_NoSucursal:{
      es:"No hay sucursales disponibles",
      en:"There are no sucursales available",
    },
    lbl_Modalidad:{
      es:"Modalidad",
      en:"Modality",
    },
    lbl_Estudio:{
      es:"Estudio",
      en:"Study",
    },
    lbl_Descuento:{
      es:"- Descuento: $",
      en:"- Discount: $",
    },
    lbl_Reembolso:{
      es:"- Reembolso: $",
      en:"- Refund: $",
    },
    lbl_Descuento2:{
      es:"Descuento: $",
      en:"Discount: $",
    },
    lbl_DescuentoAbr:{
      es:"Desct.: $",
      en:"Dto.: $",
    },
    lbl_Costo:{
      es:"Costo: $",
      en:"Charge: $",
    },
    lbl_Fecha:{
      es:"Fecha:",
      en:"Date:",
    },
    lbl_HoraInicioFin:{
      es:"Hora inicio-fin:",
      en:"Start-end time:",
    },
    lbl_Eliminar:{
      es:"Eliminar",
      en:"Remove",
    },
    lbl_folio:{
      es:"Folio",
      en:"Transaction ID",
    },
    lbl_monto:{
      es:"Monto",
      en:"Amount",
    },
    lbl_Importe:{
      es:"Importe: $",
      en:"Amount: $",
    },
    lbl_FechaInicio:{
      es:"Fecha Inicio:",
      en:"Start Date:",
    },
    lbl_FechaFin:{
      es:"Fecha Fin:",
      en:"End Date:",
    },
  };

  dictionary_txt = {
    txt_sesion: {
        es: "Inicio de sesión",
        en: "Login",
    },  
    txt_forgotPassword: {
      es: "¿Olvidaste tu contraseña?",
      en: "Forgot Password?",
  },
  txt_ErrorConnection: {
    es: "¡Error de conexión!",
    en: "Connection error!",
  },
  txt_DescriptionError: {
    es: "Lo sentimos, por el momento no podemos conectarnos a nuestros servicios, intenta descargarlo más tarde.",
    en: "Sorry, at the moment we can not connect to our services, try to download it later.",
  },
  txt_Message1:{
    es:"El acceso para pacientes lo conecta con sus registros médicos cuando más los necesita a través de su computadora o dispositivo móvil, desde cualquier lugar.",
    en:"Patient access connects you to your medical records when you need them most through your computer or mobile device, from anywhere.",
  },
  txt_Message2:{
    es:"Su información personal y registros médicos están protegidos con los más altos estándares de seguridad en línea.",
    en:"Your personal information and medical records are protected with the highest online security standards.",
  },
  txt_MessageCedisa1:{
    es:"Este portal le permite acceder a los estudios de imágenes médicas que se ha realizado en nuestro centro, puede ingresar con facilidad a través de su computadora o dispositivo móvil, desde cualquier lugar.",
    en:"This portal allows you to access the medical imaging studies that have been performed at our center, you can easily access them through your computer or mobile device, from anywhere.",
  },
  txt_MessageCedisa2:{
    es:"Su información personal y registros médicos están protegidos con los más altos estándares de seguridad y confidencialidad en línea.",
    en:"Your personal information and medical records are protected with the highest online security standards.",
  },
  txt_MessageTerms:{
    es:"Aviso de privacidad",
    en:"Notice of Privacy",
  },
  txt_err_PDF:{
    es:"Algo Salió Mal, Intenta de Nuevo",
    en:"Something went wrong, try again",
  },
  txt_MessageWelcome:{
    es:"BIENVENIDO",
    en:"WELCOME",
  },
  txt_show:{
    es:"MOSTRAR",
    en:"SHOW",
  },
  txt_BackForgot:{
    es:"Regresar",
    en:"Back",
  }, 
  txt_login: {
    es: "Iniciar sesión",
    en: "Log in",
  },
  txt_ErrorData:{
    es: "¡Error de datos!",
    en: "Data error!",
  },
  txt_DescriptionDataError:{
    es: "Los datos introducidos son incorrectos.",
    en: "The data entered is incorrect.",
  },
  txt_ShareTitle:{
    es: "Compartir enlace con URL",
    en: "Share link with URL",
  },
  txt_ShareDescription:{
    es: "Para compartir un enlace a este estudio, copie la siguiente URL:",
    en:"To share a link to this study, please copy the following URL:",
  },
  txt_ShareLeyend:{
    es:"Pulse Ctrl + c o el botón 'Copiar' para copiar al portapapeles.",
    en:"Press Ctrl + c or 'Copy' button to copy to clipboard.",
  },
  txt_Help:{
    es:"Ayuda",
    en:"Help",
  },
  txt_Sedes:{
    es:"Sedes",
    en:"Headquarters",
  },
  txt_CerrarSesion:{
    es:"Cerrar sesión",
    en:"Log out",
  },
  txt_Company:{
    es:"Empresa:",
    en:"Company:",
  },
  txt_Document:{
    es:"Documento:",
    en:"Document:",
  },
  txt_Birth:{
    es:"Fecha de nacimiento:",
    en:"Date of birth:",
  },
  txt_Email:{
    es:"Correo:",
    en:"Email:",
  },
  txt_Phone:{
    es:"Teléfono:",
    en:"Phone:",
  },
  txt_Gender:{
    es:"Sexo:",
    en:"Gender:",
  },
  txt_ShowProfile:{
    es:"VER PERFIL COMPLETO",
    en:"VIEW FULL PROFILE:",
  },
  txt_HideProfile:{
    es:"OCULTAR PERFIL COMPLETO",
    en:"HIDE FULL PROFILE",
  },
  txt_Loading:{
    es:"Cargando",
    en:"Loading",
  },
  txt_Status:{
    es:"Estado",
    en:"Status",
  },
  txt_Studies:{
    es:"Estudios",
    en:"Studies",
  },
  txt_Datetime:{
    es:"Fecha",
    en:"Datetime",
  },
  txt_Modality:{
    es:"Modalidad",
    en:"Modality",
  },
  txt_Links:{
    es:"Enlaces",
    en:"Links",
  },
  txt_TitleRetry:{
    es:"¿No puedes iniciar sesión?",
    en:"Can't log in?",
  },
  txt_BackRetry:{
    es:"Regresar a inicio de sesión",
    en:"Back to login",
  }, 
  txt_ReSendLink:{
    es:"Volver a enviar enlace de recuperación",
    en:"Resend recovery link",
  },
  txt_Cuenta:{
    es:"Mi Cuenta",
    en:"My count",
  },
  txt_AddEmail:{
    es:"Debes agregar correo electrónico",
    en:"You must add email",
  },
  txt_EnterPassword:{
    es:"Por favor, ingrese contraseña",
    en:"Please enter password",
  },
  txt_NeedPassword:{
    es:"Las contraseñas deben coincidir",
    en:"Passwords need to match",
  },
  txt_AvisoConfTitle:{
    es:"Aviso de Privacidad",
    en:"Privacy Notice",
  },
  txt_AvisoConfSubTitle:{
    es:"AGRADECEMOS SU PREFERENCIA",
    en:"WE APPRECIATE YOUR PREFERENCE",
  },
  txt_AvisoConfDescription:{
    es:"Esta página contiene información CONFIDENCIAL sometida a secreto profesional o cuya divulgación está prohibida por la ley. Si ha recibido este enlace por error, debe saber que su lectura, copia y uso están prohibidos. Le rogamos que nos lo comunique inmediatamente por teléfono y proceda a su destrucción. Cualquier documentación e información en esta pagina, son propiedad exclusiva de Cliente Final.",
    en:"This page contains CONFIDENTIAL information subject to professional secrecy or the disclosure of which is prohibited by law. If you have received this link by mistake, you should know that its reading, copying and use are prohibited. Please inform us immediately by telephone and proceed to its destruction. Any documentation and information on this page are the exclusive property of the End Customer.",
  }, 
  txt_downloadInfo:{
    es:"Descarga de Información",
    en:"Information Download",
  },
  txt_ChangePassword:{
    es:"Cambiar Contraseña",
    en:"Change Password",
  },
  txt_Sede_title:{
    es:"Lista de Sedes",
    en:"Headquarters",
  },
  txt_thanksForUse:{
    es:"Gracias por usar INFOSALUD.",
    en:"Thank you for using INFOSALUD.",
  },

  txt_Departament:{
    es:"Departamento",
    en:"Departament",
  },
  txt_Municipio:{
    es:"Municipio",
    en:"Town",
  },
  txt_all: {
    es:"Todos",
    en:"All",
  },
  txt_oneDay: {
    es:"Hoy",
    en:"Today",
  },
  txt_twoDays: {
    es:"Dos días",
    en:"Two days",
  },
  txt_oneWeek: {
    es:"Una semana",
    en:"One week",
  },
  txt_twoWeeks: {
    es:"Dos semanas",
    en:"Two weeks",
  },
  txt_threeWeeks: {
    es:"Tres semanas",
    en:"Three weeks",
  },
  txt_oneMonth: {
    es:"Un mes",
    en:"One month",
  },
  txt_twoMonths: {
    es:"Dos meses",
    en:"Two months",
  },
  txt_threeMonths: {
    es:"Tres meses",
    en:"Three months",
  },
  txt_oneYear: {
    es:"Un año",
    en:"One year",
  },    
  txt_dateRange: {
    es:"Rango de Fechas",
    en:"Date Range",
  },
  txt_AddTel:{
    es:"Debes agregar teléfono",
    en:"You must add phone",
  }, 
  txt_AgendarCita:{
    es:"Agendar Cita",
    en:"Schedule Studies",
  },
  txt_agendaCitaSinCuenta:{
    es:"¿Deseas agendar una cita y no tienes una cuenta? ¡Da clic aquí!",
    en:"Do you want to schedule an appointment and don't have an account? Click here!",
  },
  txt_DatosPropIncorrectos:{
    es: "Los datos proporcionados son incorrectos!",
    en: "The data entered is incorrect!",
  },
  txt_InfoCaptura:{
    es: "Informacion de Captura",
    en: "Capture Information",
  },  
  txt_buscar:{
    es:"Buscar",
    en:"Search",
  },
  txt_Facturacion:{
    es:"Facturación electrónica",
    en:"Electronic Billing",
  },
  txt_FacturacionTitle:{
    es:"FACTURACION",
    en:"BILLING",
  },
  txt_Requisitos:{
    es:"Requisitos",
    en:"Requirements",
  },
  txt_Requisito1Factura:{
    es:"Número de folio del estudio(s).",
    en:"Folio number of the study(ies).",
  },
  txt_Requisito2Factura:{
    es:"Monto total del estudio(s).",
    en:"Total amount of the study(ies).",
  },
  txt_Requisito3Factura:{
    es:"RFC (Registro Federal de Contribuyentes).",
    en:"Tax ID.",
  },
  txt_Requisito4Factura:{
    es:"Uso del CFDI (Comprobante digital por Internet).",
    en:"Digital Tax Invoice.",
  },
  txt_Requisito5Factura:{
    es:"Denominación/Razón Social.",
    en:"Name/Company name.",
  },
  txt_Requisito6Factura:{
    es:"Código Postal del domicilio fiscal.",
    en:"Postal Code of the tax address.",
  },
  txt_Requisito7Factura:{
    es:"Correo electrónico.",
    en:"Email",
  }, 
  txt_Requisito8Factura:{
    es:"Si su orden fue abierta el 31 de diciembre solo podrá facturar el mismo día que abrimos su orden en sucursal.",
    en:"If your order was opened on December 31, you will only be able to invoice the same day we opened your order at the branch.",
  },
  txt_ConsultaFactura:{
    es:"Consultar factura",
    en:"Check invoice",
  },
  txt_GenerarCFDI:{
    es:"Generar CFDI",
    en:"Generate Digital Tax Invoice",
  },
  txt_EnviarCorreo:{
    es:"Enviar vía correo",
    en:"Send by email",
  },
  txt_DescargaArchivos:{
    es:"Descargar PDF y XML",
    en:"Download files",
  },
  txt_Cancelar:{
    es:"Cancelar",
    en:"Cancel",
  },
  };

  dictionary_btn = {     
    btn_login: {
      es: "Iniciar sesión",
      en: "Log in",
    },
    btn_Retry: {
      es: "Reintentar",
      en: "Retry",
    },
    btn_Send: {
      es: "Enviar",
      en: "Send",
    },
    btn_Ok: {
      es: "Aceptar",
      en: "Ok",
    },
    btn_CopyURL:{
      es:"Copiar URL",
      en:"Copy URL",
    },
    btn_SendLink:{
      es:"Enviar enlace de recuperación",
      en:"Send recovery link",
    },
    btn_ChangePassword:{
      es:"Cambiar contraseña",
      en:"Change Password",
    },
    btn_Download:{
      es:"Descargar",
      en:"Download",
    },
  }; 
  

  constructor(current_lang: string) {
    try {
      this.current_lang = current_lang;
      // this.changeLabelsTitle();
    } catch (ex) {}
  }

  changeLabelsTitle() {
    try {
      //console.log("Entro a translate");
      //console.log(this.current_lang);

      /*let myCallback = MyUtilities.Bind(this.lblModifyLanguage, this, null);
      $("label[data-language_lbl]").each((element) =>
        myCallback(this, element)
      );*/

      let arrayLBL = $("label[data-language_lbl]");
   
      for (var i = 0; i < arrayLBL.length; i++)
      {
          let elementoLBL = arrayLBL[i];
          this.lblModifyLanguage(elementoLBL);
      }

      let arrayTXT= $("[data-language_txt]");
     
      for (var a = 0; a < arrayTXT.length; a++)
      {
          let elementoTXT = arrayTXT[a];
          this.txtModifyLanguage(elementoTXT);
      }

      let arrayBTN= $("button[data-language_btn]");
      for (var e = 0; e < arrayBTN.length; e++)
      {
          let elementoBTN = arrayBTN[e];
          this.btnModifyLanguage(elementoBTN);
      } 

    } catch (err) {
      // console.log(err.message);
    }
  }

  lblModifyLanguage(elemento: any) {
    let key:string;
        try {
        let objectJquery = $(elemento);
        key = objectJquery.data("language_lbl"); //Solo toma el language_tip del idLabel
        if(key != ""){
          let texto = this.dictionary_lbl[key][this.current_lang] || ""; //Cambia el texto de ingles/español
          objectJquery.text(texto);
        }
      } catch (ex) {
        console.error("inner", key + " - " + ex.message);
      }
  }

  txtModifyLanguage(elemento: any) {
    let key:string;
        try {
        let objectJquery = $(elemento);
        key = objectJquery.data("language_txt"); //Solo toma el language_tip del idText
        let texto =
          this.dictionary_txt[key][this.current_lang] || ""; //Cambia el texto de ingles/español
        objectJquery.text(texto);
      } catch (ex) {       
        console.error("inner", key + " - " + ex.message);
      }
  }

  btnModifyLanguage(elemento: any) {
    let key:string;
        try {
        let objectJquery = $(elemento);
        key = objectJquery.data("language_btn"); //Solo toma el language_tip del idButton
        let texto =
          this.dictionary_btn[key][this.current_lang] || ""; //Cambia el texto de ingles/español
        objectJquery.text(texto);
      } catch (ex) {
        console.error("inner", key + " - " + ex.message);
      }
  }

}
