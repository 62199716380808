import React, { useState, useContext, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CircularProgress from "@mui/material/CircularProgress";

import typeScriptContext from "../../../../AppState/SystemConfig/appContext";


import img from "./img/ready.png";
import eye from "./img/eye.png";
import eye2 from "./img/eye2.png";

import "./styles.css";

function ChangePassword({ userData }) {
  if(!userData|| Object.keys(userData).length==0){
    return <div></div>
  }
  
  const { AppManager,AppConfiguration } = useContext(typeScriptContext);
  const [ready, setReady] = useState(false);
  const [loading, setloading] = useState(false);
  const [validated, setValidate] = useState("");
  const [display, setDisplay] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const [data, setData] = useState({
    email: userData.Email !== "" ? userData.Email : "",
    password: "",
    passwordConfirm: "",
  });
  

  AppManager.translator.changeLabelsTitle();


  const setStatusPassword = (response) => {
    setloading(false);
    setReady(response); //Local
  };
  prompt.setStatusPassword = setStatusPassword;

  const closeChangePassword = () => {
    document.getElementById("divChangePassword").style.display = "none";
    document.getElementById("divChangePassword").style.opacity = "0";
    setData({
      email: userData.Email !== "" ? userData.Email : "",
      password: "",
      passwordConfirm: "",
    });
    setValidate("");
    setDisplay("");
    if (ready) {
      AppManager.clearToken();
    }
    setReady(false);
  };
  const handleChange = (event) => {
    setData((prevState) => {
      // Object.assign would also work
      return { ...prevState, password: event.target.value };
    });
  };
  const handleChangeEmail = (event) => {
    setData((prevState) => {
      // Object.assign would also work
      return { ...prevState, email: event.target.value };
    });
  };
  const handleChangeConfirmPass = (event) => {
    setDisplay("");
    if (data.password !== event.target.value) {
      setDisplay("block");
    }
    setData((prevState) => {
      // Object.assign would also work
      return { ...prevState, passwordConfirm: event.target.value };
    });
  };

  const onClick = (e) => {
    if (!ready) {
      //Prevenir el funcionamiento del boton
      setloading(true);
      if (
        display === "" &&
        data.passwordConfirm &&
        data.password &&
        data.email
      ) {
        AppManager.changePassword(prompt, data.email, data.password);
      } else {
        setValidate("");
      }
      setValidate("was-validated");
    }
  };


  
  const handleClickShowPassword = () => {setShowPassword(!showPassword);}
  const handleClickShowConfirmPassword = () => {setShowConfirmPassword(!showConfirmPassword)};
  if(!userData || Object.keys(userData)==0){
    return <></>
}
  return (
    <div className={AppConfiguration.theme}>
      <div
        id="divChangePassword"
        className="modal container-fluid fade-in top-50 position-absolute start-50 translate-middle"
        style={{
          display: "none",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.48)",
        }}
      >
        <div
          className="row justify-content-center pt-6"
          style={{ alignItems: "center", height: "100%" }}
        >
          <div className="col-sm-10 col-lg-7 col-xxl-5">
            <div className="card theme-wizard mb-5" id="wizard">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeChangePassword}
                ></button>
              </div>
              <div className="card-header bg-light pt-3 pb-2">
                <ul className="nav justify-content-between">
                  <li className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-cir">
                          <svg
                            className="svg-inline--fa fa-lock fa-w-14"
                            style={{ marginLeft: "0.5rem" }}
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="lock"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 648 712"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                      <span
                        data-language_txt="txt_ChangePassword"
                        className="d-md-block mt-1 fs--1 colorP"
                      >
                        Cambiar Contraseña
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body py-4" id="wizard-controller">
                <div className="tab-content">
                  <div
                    className="tab-pane active px-sm-3 px-md-5"
                    role="tabpanel"
                    aria-labelledby="bootstrap-wizard-tab1"
                    id="bootstrap-wizard-tab1"
                  >
                    {!ready ? (
                      <form className={`needs-validation ${validated}`}>
                        <div className="mb-3">
                          <label
                            data-language_lbl="lbl_Email"
                            className="form-label"
                            htmlFor="bootstrap-wizard-wizard-email"
                          >
                            Email*
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            required
                            placeholder="Email address"
                            pattern="^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"
                            onChange={handleChangeEmail}
                            value={data.email}
                            id="bootstrap-wizard-wizard-email"
                            data-wizard-validate-email="true"
                          />
                          <div
                            data-language_txt="txt_AddEmail"
                            className="invalid-feedback"
                          >
                            You must add email
                          </div>
                        </div>
                        <BrowserView className="row g-2">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                data-language_lbl="lbl_PasswordC"
                                className="form-label"
                                htmlFor="bootstrap-wizard-wizard-password"
                              >
                                Password*
                              </label>

                             
                            <div style={{display:"flex",alignItems:"center"}}>
                            <input
                                className="form-control"
                                type={!showPassword?"password":"text"}
                                name="password"
                                autoComplete="off"
                                placeholder="Password"
                                id="bootstrap-wizard-wizard-password"
                                data-wizard-validate-password="true"
                                onChange={handleChange}
                                value={data.password}
                                required
                              />
                            <img src={!showPassword?eye:eye2} className="icon" onClick={handleClickShowPassword}></img>

                            </div>

                              <div
                                data-language_txt="txt_EnterPassword"
                                className="invalid-feedback"
                              >
                                Please enter password
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                data-language_lbl="lbl_ConfirmPassword"
                                className="form-label"
                                htmlFor="bootstrap-wizard-wizard-confirm-password"
                              >
                                Confirm Password*
                              </label>
                              <div style={{display:"flex",alignItems:"center"}}>
                              <input
                                className="form-control form-control:invalid form-control.is-invalid"
                                type={!showConfirmPassword?"password":"text"}
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                required
                                id="bootstrap-wizard-wizard-confirm-password"
                                onChange={handleChangeConfirmPass}
                                data-wizard-validate-confirm-password="true"
                              />
                               <img src={!showConfirmPassword?eye:eye2} className="icon" onClick={handleClickShowConfirmPassword}></img>
                               </div>
                              <div
                                data-language_txt="txt_NeedPassword"
                                className="invalid-feedback"
                                style={{ display: display }}
                              >
                                Passwords need to match
                              </div>
                            </div>
                          </div>
                        </BrowserView>
                        <MobileView
                          className="row g-2"
                          style={{ flexDirection: "column" }}
                        >
                          <div className="row-6">
                            <div className="mb-3">
                              <label
                                data-language_lbl="lbl_PasswordC"
                                className="form-label"
                                htmlFor="bootstrap-wizard-wizard-password"
                              >
                                Password*
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                autoComplete="off"
                                placeholder="Password"
                                id="bootstrap-wizard-wizard-password"
                                data-wizard-validate-password="true"
                                onChange={handleChange}
                                value={data.password}
                                required
                              />
                              <div
                                data-language_txt="txt_EnterPassword"
                                className="invalid-feedback"
                              >
                                Please enter password
                              </div>
                            </div>
                          </div>
                          <div className="row-6">
                            <div className="mb-3">
                              <label
                                data-language_lbl="lbl_ConfirmPassword"
                                className="form-label"
                                htmlFor="bootstrap-wizard-wizard-confirm-password"
                              >
                                Confirm Password*
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="confirmPassword"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                required
                                id="bootstrap-wizard-wizard-confirm-password"
                                data-wizard-validate-confirm-password="true"
                                onChange={handleChangeConfirmPass}
                              />
                              <div
                                data-language_txt="txt_NeedPassword"
                                className="invalid-feedback"
                                style={{ display: display }}
                              >
                                Passwords need to match
                              </div>
                            </div>
                          </div>
                        </MobileView>
                      </form>
                    ) : (
                      <img
                        src={img}
                        alt="ready"
                        className="fade-in"
                        style={{ marginLeft: "32%" }}
                      ></img>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer bg-light" style={{ height: "4rem" }}>
                <div className="px-sm-3 px-md-5">
                  <button
                    data-language_btn="btn_ChangePassword"
                    className="btn btn-primary position-absolute mb-3 me-3 end-0 "
                    type="button"
                    onClick={onClick}
                  >
                    Cambiar contraseña{" "}
                    {loading ? (
                      <CircularProgress
                        size={20}
                        style={{ marginLeft: 15 }}
                      ></CircularProgress>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
