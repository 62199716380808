import React, { useState, useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import RadioInput from "./radioInput";
import { isUndefined, set, uniqueId } from "lodash";
import EstudioCita from "./estudioCita";
import EstudioFechaHora from "./estudioFechaHora";
import dayjs from "dayjs";
import {isMobile} from "react-device-detect";
import MsgErrorData from "./Modal/msgErrorData";
import FormControl from '@mui/material/FormControl';
import "./styles.css";

function AgendaCita({ Paciente = "", closeModalCita, openModalPassword }) {
  const { AppManager, AppConfiguration } = useContext(typeScriptContext);
  const [itemIndex, setItemIndex] = useState(1);
  const [mensaje, setMensaje] = useState("");
  const [idSucursal, setIdSucursal] = useState(-1);
  const [sucursales, setSucursales] = useState([]); // !(!AppManager.sedesURLS) ? AppManager.sedesURLS.filter(s=>s.Activo === true) : AppManager.gSedeActual;
  const [modalidadesDisponibles, setModalidadesDisponibles] = useState([]);
  const [estudiosPorModalidad, setEstudiosPorModalidad] = useState([]);
  const [horariosPorModalidad, setHorariosPorModalidad] = useState([]);
  const [estudiosSeleccionados, setEstudiosSeleccionados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLadas, setIsLoadingLadas] = useState(false);
  const [idEstudioSeleccionado, setIdEstudioSeleccionado] = useState(-1);
  const [estudiosProgramados, setEstudiosProgramados] = useState([]);
  const [idPaciente, setIdPaciente] = useState(0);
  const [terminaCita, setTerminaCita] = useState(false);
  const [ladasDisponibles, setLadasDisponibles] = useState([]);
  const [arsDisponibles, setArsDisponibles] = useState([]);
  const [idLadaSeleccionada, setIdLadaSeleccionada] = useState(-1);
  const [idPaisHospital, setIdPaisHospital] = useState("-1");

  const [firstLoad, setFirstLoad] = useState(true);

  const [idPlanSeleccionado, setIdPlanSeleccionado] = useState(-1);
  const [valueLada, setValueLada] = useState("");
  const [valueTelefono, setValueTelefono] = useState("");
  const [valueCedula, setValueCedula] = useState("");
  const [valuePoliza, setValuePoliza] = useState("");
  const [valueEmail, setValueEmail] = useState("");

  //============================Nombres============================
  const [valueNombre, setValueNombre] = useState("");
  const [valueSegundoNombre, setValueSegundoNombre] = useState("");
  const [valueApellidoMaterno, setvalueApellidoMaterno] = useState("");
  const [valueApellidoPaterno, setvalueApellidoPaterno] = useState("");

  const [isErrorInData, setisErrorInData] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [isSearching, setisSearching] = useState(false);

  const [userData, setUserData] = useState(null);

  const ComboSelect = () => {
    return (
      <Stack spacing={2}>
        <Autocomplete
          id="cmbSelectStudy"
          style={{minWidth:"200px"}}
          onInputChange={(event, value) => {
            //console.log('Value: ' + value);
            if (value !== null) {
              var datosEstudio = estudiosPorModalidad.find((e) => e.Descripcion === value);
              var existeItem =
                estudiosSeleccionados !== null && estudiosSeleccionados.length > 0
                  ? estudiosSeleccionados.find((s) => s.Descripcion === value)
                  : null;
              if (!isUndefined(datosEstudio)) {
                if (existeItem === null || isUndefined(existeItem)) {
                  setEstudiosSeleccionados([
                    ...estudiosSeleccionados,
                    {
                      idEstudio: datosEstudio.idEstudio,
                      Descripcion: datosEstudio.Descripcion,
                      Preparacion: datosEstudio.Preparacion,
                      Precio: datosEstudio.Precio,
                      Descuento: datosEstudio.Descuento,
                      modalidad: datosEstudio.Modalidad,
                    },
                  ]);
                  if (AppManager.isDebug) console.log(estudiosSeleccionados);
                }
              }
            }
          }}
          options={estudiosPorModalidad.map((option) => option.Descripcion)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    );
  };

  const ComboSelectLada = ({actual}) => {
    if(isLoadingLadas){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete
        freeSolo
        value={actual}
        style={{ minWidth: 185}}
        id="cmbSelectLadaAgenda"
        onChange={(event, value) => {
         if(value !== null){
          setValueLada(value);
         }
          if (AppManager.isDebug)
            // console.log('Lada seleccionada: ' + value); 
          if (            
            valueTelefono&&
            valueTelefono.length > 0 &&
            valueCedula !== null &&
            valueCedula.length > 0 &&
            value !== null &&
            value.length > 0
          ) {
            setIsValidating(true);
            setisSearching(true);     
            AppManager.getDatosPacienteByFolioandPhone(valueCedula, valueTelefono, prompt);
          }
          if (value === "" || value === null) setIdLadaSeleccionada(-1);
        }}
        onInputChange={(event, valueInput) => {
          if (valueInput !== null) { 
            var existeItem = ladasDisponibles && ladasDisponibles!== null &&ladasDisponibles.length > 0 ? ladasDisponibles.find((s) => s.Nombre === valueInput) : null;
            if (!isUndefined(existeItem)) {
              setIdLadaSeleccionada(existeItem.Id);
              if (AppManager.isDebug) console.log("Lada id seleccionada: " + existeItem.Id);
            }
          }
        }}
        options={ladasDisponibles.map((option) => option.Nombre)}
        renderInput={(params) => <TextField {...params} placeholder={actual!=""?actual:AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };

  /////////////////////////
  ////CARGA INICIAL////////

  const getURLSucursal = () => {
    var url = null;
    try {
      if (sucursales !== undefined && sucursales.length > 0) {
        let suc = sucursales.filter((x) => x.IdServidor == idSucursal);
        if (suc !== null && suc !== undefined) url = suc.UrlServicio === undefined ? null : suc.UrlServicio;
      }
    } catch (e) {
      //  console.log('getURLSucursal' + e);
    }
    return url;
  };

  prompt.setModalidades = (arry) => {
    {
      if (arry.length > 0) {
        setModalidadesDisponibles([]);
        setModalidadesDisponibles((modalidadesDisponibles) => [...modalidadesDisponibles, ...arry.filter((x) => x.Activo === true)]);
        //Obtiene los estudios de la primer modalidad
        onModalidadSeleccionada(arry[0]);        
        pintaNextItem(3);
      }
      setIsLoading(false);
    }
  };

  prompt.setEstudiosModalidad = (arry) => {
    {
      if (arry.length > 0) {
        setEstudiosPorModalidad([]);

        const unique = arry
          .map((e) => e["Descripcion"])
          // store the keys of the unique objects
          .map((e, i, final) => final.indexOf(e) === i && i)

          // eliminate the dead keys & store unique objects
          .filter((e) => arry[e])
          .map((e) => arry[e]);
        //console.log('Estudios filtrados:' + unique);

        setEstudiosPorModalidad((estudiosPorModalidad) => [...estudiosPorModalidad, ...unique]);
      }
      setIsLoading(false);
    }
  };

  prompt.setHorariosModalidad = (arry, idEstudio) => {
    try {
      if (horariosPorModalidad.length > 0) {
        var existeEstudio = horariosPorModalidad.filter((x) => x.IdEstudio === idEstudio);
        if (existeEstudio !== null && existeEstudio.length > 0) {
          //Si ya se había seleccionado otra fecha, se actualizan los datos
          var datos = horariosPorModalidad.map((data) => {
            if (data.IdEstudio === idEstudio) {
              // Create a *new* object with changes
              return { ...data, IdEstudio: idEstudio, Horarios: arry };
            } else {
              return data;
            }
          });
          setHorariosPorModalidad(datos);
        } else {
          setHorariosPorModalidad((horariosPorModalidad) => [...horariosPorModalidad, ...[{ IdEstudio: idEstudio, Horarios: arry }]]);
        }
      } else setHorariosPorModalidad((horariosPorModalidad) => [...horariosPorModalidad, ...[{ IdEstudio: idEstudio, Horarios: arry }]]);

      //////////////////////////////////////////////
      //Fija la primer hora al estudio programado
      if (arry.length > 0) {
        onHoraEstudioSeleccionado(idEstudio,arry[0].IdDivision + '_' + arry[0].FechaInicio);
      }
    } catch (e) {
      setIsLoading(false);
      // console.log('setHorariosModalidad' + e);
    } finally {
      setIsLoading(false);
    }
  };

  prompt.setSucursalesDisponibles = (arry) => {
    try {
      setIsLoading(false);
      if (arry.length > 0) {
      
        for (var i = 0; i < arry.length; i++) setSucursales(arry);
        
        setIdSucursal(arry[0].IdServidor);
        if (AppManager.isDebug)
          console.log('Sucursal default:' + arry[0].IdServidor);
      }
    } catch (e) {
      setIsLoading(false);
      // console.log('Error Sucursales:' + e);
    }
  };

  prompt.setDatosPaciente = (arry) => {
    try {
      switch (arry.Result) {
        case 0:
          setIsLoading(false);
          if (AppManager.translator.current_lang === "es") setMensaje("Ocurrio un error al consultar sus datos...");
          else setMensaje("An error occurred reviewing your data...");
          break;
        case -1:
          setIsLoading(false);
          if (AppManager.translator.current_lang === "es") setMensaje("El folio no se encontró...");
          else setMensaje("The folio was not found...");
          break;
        default:
          pintaNextItem(itemIndex + 1);
          setIdPaciente(arry.Result);
          setSucursales([]);
          resetSucursalEstudios(-1);
          AppManager.getSucursales(prompt);
          break;
      }
    } catch (e) {
      // console.log('Error Sucursales:' + e);
    } finally {
      setIsLoading(false);
    }
  };

  prompt.setEstudiosAgenda = (data) => {
    try {
      setIsLoading(false);
      setTerminaCita(true);
      if (data != null) {
        if (AppManager.translator.current_lang === "es")
          setMensaje("Su solicitud ha sido recibida, en cualquier momento lo contactarán para que acuda a su cita...");
        else setMensaje("Your request has been received, you will be contacted at any time to attend your appointment...");
      } else {
        if (AppManager.translator.current_lang === "es") setMensaje("Ocurrió un error al agendar la cita de su(s) estudio(s)...");
        else setMensaje("An error occurred when scheduling your appointment...");
      }
    } catch (e) {
      setIsLoading(false);
      // console.log('Error enviar estudios de agenda:' + e);
    }
  };
  
  prompt.setCodigoPais=(data) =>{
    try{
        if(data.Data !== null && data.Data !==undefined)
        {
          var idPais = Number(data.Data);
          var valLada = ladasDisponibles.filter((x) => x.Id === idPais);
          if (valLada[0] !== null && valLada[0] !== undefined) 
          {
            setIdLadaSeleccionada(idPais); 
            setValueLada(valLada[0].Nombre);
          }
          console.log('Id Pais:' + idPais); 
          console.log('Nombre lada:' + valLada[0].Nombre);
        }
    }
    catch(e){
      console.log(e);
    }
  }

  prompt.setLadasAgenda = (data) => {
    try {
      setIsLoadingLadas(false);
      setLadasDisponibles(data);

      if (data !== undefined && data.length > 0) {

        if(Paciente.IdPaisLada !== undefined)
        {
          setIdLadaSeleccionada(Paciente.IdPaisLada);
          var valLada = data.filter((x) => x.Id === Paciente.IdPaisLada);
          if (valLada[0] !== null && valLada[0] !== undefined) setValueLada(valLada[0].Nombre);
        }
      }
      //////////////////////////////
      //Get identificador del pais      
      AppManager.getIdentificadorPais(prompt);
    } catch (e) {
      setIsLoadingLadas(false);
      // console.log('Error enviar ladas en agenda:' + e);
    }
  };

  prompt.setPlanesAgenda = (data) => {
    try {
      setIsLoading(false);
      setArsDisponibles(data);
      var tel = Paciente.Tel !== undefined ? Paciente.Tel.split(" ") : "";
      if (tel.length > 1) setValueTelefono(tel[1]);
      else setValueTelefono(Paciente.Tel);
    } catch (e) {
      setIsLoading(false);
      //  console.log('Error enviar planes de aseguradora en agenda:' + e);
    }
  };

  prompt.onUserSearched = (user) => {
    try {
      if (user != null && Object.keys(user).length > 0) {
       setUserData(user);
       setValueNombre(user.PrimerNombre);
       setValueSegundoNombre(user.SegundoNombre);
       setValueEmail(user.Email);
       setvalueApellidoMaterno(user.ApellidoMaterno);
       setvalueApellidoPaterno(user.ApellidoPaterno);
       setValuePoliza(user.Poliza);
      }
      else{
        setUserData(null);
        setValueNombre("");
        setValueSegundoNombre("");
        setValueEmail("");
        setvalueApellidoMaterno("");
        setvalueApellidoPaterno("");
        setValuePoliza("");
      }
      setIsValidating(false);
      setisSearching(false);      
    } catch (e) {
      setIsValidating(false);
      setisSearching(false);
    }
  };

  prompt.setCitaNoPaciente = (data) => {
    try {
      setIsLoading(false);
      setTerminaCita(true);
      if (data !== null && data !==undefined)
      {  
        if(data.Id !== null && data.Id !==undefined) 
        { 
          if (AppManager.translator.current_lang === "es")
            setMensaje("Su solicitud ha sido recibida, en cualquier momento lo contactarán para que acuda a su cita...");
          else 
            setMensaje("Your request has been received, you will be contacted at any time to attend your appointment...");
        }else{
          if (AppManager.translator.current_lang === "es") 
            setMensaje("Ocurrió un error al agendar la cita de su(s) estudio(s)...");
          else 
            setMensaje("An error occurred when scheduling your appointment...");
        } 
        if(data.Mensaje !== null && data.Mensaje !== undefined){
          console.log('setCitaNoPaciente:' + data.Mensaje);
        }
      } else {
        if (AppManager.translator.current_lang === "es") 
          setMensaje("Ocurrió un error al agendar la cita de su(s) estudio(s)...");
        else 
          setMensaje("An error occurred when scheduling your appointment...");
      }
    } catch (e) {
      setIsLoading(false);
      // console.log('Error enviar estudios de agenda:' + e);
    }
  }

  const closeAgendaCita = () => {
    closeModalCita();
    document.getElementById("divAgendaCita").style.display = "block";
    document.getElementById("divAgendaCita").style.opacity = "0";
  };

  const changeTabNext = () => {
    try {
      setMensaje("");
      var numItem = itemIndex + 1;
      switch (itemIndex) {
        //DATOS DE ENTRADA
        case 1:       
            if (valueApellidoPaterno && valueApellidoPaterno.length>1  &&  valueNombre&&valueNombre.length>1 
              &&valueTelefono && valueTelefono.length === 10 && idPlanSeleccionado !== -1) {
              pintaNextItem(numItem);
            if (userData !== null) {
              AppManager.setDatosPacienteAgenda(
                null,
                Paciente.Folio,
                idLadaSeleccionada,
                tel,
                correo,
                polizaVal,
                idPlanSeleccionado,
                prompt
              );
            }
          } else {
            setisErrorInData(true);
            return;
          }
          break;        
        //SUCURSAL
        case 2:       
          if (idSucursal !== -1) {        
            var url = getURLSucursal();
            AppManager.getModalidadesSucursal(url, prompt);  
          }else {
            if (AppManager.translator.current_lang === "es") {
              setMensaje("Seleccione una sucursal...");
            } else {
              setMensaje("Select a sucursal...");           
            }
            setModalidadesDisponibles([]);
          }
          break;
        //LISTA DE ESTUDIOS
        case 3: {     
          if (estudiosSeleccionados.length > 0) {
          } else {
            return;
          }
          break;
        }
        //PROGRAMACION LISTA DE ESTUDIOS
        case 4:
          if (estudiosProgramados.length == 0 || estudiosProgramados.length !== estudiosSeleccionados.length) {
            if (AppManager.translator.current_lang === "es") setMensaje("Seleccione fecha y hora para su(s) estudio(s)...");
            else setMensaje("Select date and time for your studies...");
            return;
          } else {     
            if (
              valueApellidoPaterno &&
              valueApellidoPaterno.length > 0 &&
              valueNombre &&
              valueNombre.length > 0 &&
              valueCedula &&
              valueCedula.length > 0
            ) {
              var url = getURLSucursal();
           
              if (AppManager.isDebug) {
                console.log('Agenda-IdPaciente: ' + idPaciente);
                console.log('Agenda-estudiosProgramados: ' + estudiosProgramados);
              }
       
              if (userData !== null) {
                AppManager.setHorariosEstudiosAgenda(url, userData.IdPaciente, estudiosProgramados, prompt);               
              } else {
                  AppManager.setCitaSinPaciente(
                  valueCedula,
                  valueNombre,
                  valueSegundoNombre,
                  valueApellidoPaterno,
                  valueApellidoMaterno,
                  idLadaSeleccionada,
                  valueTelefono,
                  valueEmail,
                  valuePoliza,
                  idPlanSeleccionado,
                  estudiosProgramados,
                  prompt
                );

              }
            } else {
              setisErrorInData(true);
              return;
            }
          }
          break;
      }

      if (numItem < 5) {
        pintaNextItem(numItem);
      } else {
        setItemIndex(4);
      }

    } catch (e) {
      // console.log('changeTabNext' + e);
    }
  };

  const pintaNextItem = (numItem) => {
    try {
      //Item previo
      var item = document.getElementById("tabItem" + (numItem - 1) + "_Agenda");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0";
      }

      //Siguiente item
      item = document.getElementById("tabItem" + numItem + "_Agenda");
      if (item !== null) {
        item.style.display = "flex";
        item.style.opacity = "1";
      }
    
      var itemMenuActivo = document.getElementById("Paso" + numItem + "_Cita_1");
      var itemMenuInactivo = document.getElementById("Paso" + numItem + "_Cita_0");
      if (itemMenuActivo !== null) itemMenuActivo.style.display = "block";
      if (itemMenuInactivo !== null) itemMenuInactivo.style.display = "none";

      setItemIndex(numItem);
  
    } catch (e) {
      // console.log('pintaNextItem' + e);
    }
  };

  const resetSucursalEstudios = () => {
    setIdSucursal(-1);
    setEstudiosSeleccionados([]);
  };

  const changeTabPrev = () => {
    var numItem = itemIndex - 1;
    setItemIndex(numItem);
    setMensaje("");
    if (numItem > 0) {
      //Item next
      var item = document.getElementById("tabItem" + itemIndex + "_Agenda");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0"; 
      }
      //Item actual
      item = document.getElementById("tabItem" + numItem + "_Agenda");
      if (item !== null) {
        item.style.display = "flex";
        item.style.opacity = "1";
      }
      var itemMenuActivo = document.getElementById("Paso" + itemIndex + "_Cita_1");
      var itemMenuInactivo = document.getElementById("Paso" + itemIndex + "_Cita_0");

      if (itemMenuActivo != null) itemMenuActivo.style.display = "none";
      if (itemMenuInactivo !== null) itemMenuInactivo.style.display = "block";
    } else {
      setItemIndex(1);
    }
    // console.log('Indice actual:' + itemIndex);
  };

  const handleFiltraEstudios = (event) => {
    try {
      var datoModalidad = event.target.value;
      var modalidad = modalidadesDisponibles.find((e) => e.IdModalidad === datoModalidad);
      if (modalidad !== null) {
        //console.log("Modalidad seleccionada: " + modalidad);
        var url = getURLSucursal();       
        AppManager.getEstudiosModalidad(url, modalidad.IdModalidad, prompt);
      }
    } catch (e) {
      //  console.log('handleFiltraEstudios' + e);
    }
  };

  const handleSeleccionaPlan = (event) => {
    try {
      var datoPlan = event.target.value;
      setIdPlanSeleccionado(datoPlan);
      if (AppManager.isDebug) console.log("handleSeleccionaPlan: " + datoPlan);
    } catch (e) {
      // console.log('handleSeleccionaPlan' + e);
    }
  };

  const quitarEstudio = (idEstudio) => {
    try {
      setMensaje("");
      var estudios = estudiosSeleccionados.filter((a) => a.idEstudio !== idEstudio);
      setEstudiosSeleccionados(estudios);
      var estudiosP = estudiosProgramados.filter((a) => a.IdEstudio !== idEstudio);
      setEstudiosProgramados(estudiosP);

      if (estudios.length === 0) {
        if (itemIndex === 4) changeTabPrev(true);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const filterHorarioEstudio = (idEstudio) => {
    var data = [];
    if (horariosPorModalidad.length > 0) {
      var data = horariosPorModalidad.filter((x) => x.IdEstudio === idEstudio);
      if (data.length > 0) data = data[0].Horarios;
    }
    return data;
  };

  const onFechaEstudioSeleccionado = (idEstudio, fecha) => {
    try {
      fecha = dayjs(fecha).format("YYYY-MM-DD");
      var existeItem =
        estudiosSeleccionados !== null && estudiosSeleccionados.length > 0
          ? estudiosSeleccionados.find((s) => s.idEstudio === idEstudio)
          : null;

      if (AppManager.isDebug) {
        console.log("Fecha seleccionada:" + fecha);
        console.log("Id Estudio seleccionado:" + idEstudio);
        console.log("Modalidad estudio seleccionado:" + existeItem.modalidad);
      }
      setIdEstudioSeleccionado(idEstudio);
      var url = getURLSucursal();
      AppManager.getHorariosDisponiblesModalidad(url, fecha, idEstudio, prompt);
    } catch (e) {
      //  console.log(e);
    }
  };

  const onHoraEstudioSeleccionado = (idEstudio, IdfechaHora) => {
    try {

      setMensaje("");
      if (IdfechaHora === "") {
        //Borra el item de la lista de programados
      }

      var datosFechaSel = IdfechaHora.split("_");
      var id = datosFechaSel[0];
      var fechaHora = datosFechaSel[1];

      if (estudiosProgramados.length > 0) {
       
        var item = estudiosProgramados.filter((x) => x.IdEstudio === idEstudio);
        if (item !== null && item.length > 0) {
          //Actualiza la nueva fecha
          var datos = estudiosProgramados.map((data) => {
            if (data.IdEstudio === idEstudio) {
           
              // Create a new object with changes
              return { FechaInicio: fechaHora+"z", IdEstudio: data.IdEstudio, IdDivision: id };
            } else {
              return data;
            }
          });
          setEstudiosProgramados(datos);
        } else {
          setEstudiosProgramados([...estudiosProgramados,  { FechaInicio: fechaHora+"z", IdEstudio: idEstudio, IdDivision: id }]);
        }
      } else {

        setEstudiosProgramados([...estudiosProgramados,  { FechaInicio: fechaHora+"z", IdEstudio: idEstudio, IdDivision: id }]);
      }

      if (AppManager.isDebug) {
        console.log("Estudio, division y hora: [" + idEstudio + ", " + id + ", " + fechaHora+"z" + "]");
        console.log("Estudios programados:" + estudiosProgramados);
      }
    } catch (e) {
      console.log("onHoraEstudioSeleccionado" + e);
    }
  };

  const onModalidadSeleccionada = (modalidad) => {
    try {
      //console.log("Modalidad seleccionada: " + modalidad);
      var url = getURLSucursal();
      AppManager.getEstudiosModalidad(url, modalidad.IdModalidad, prompt);
    } catch (e) {}
  };

  const onHiddeMessage = () => {
    try {
      setMensaje("");
    } catch (e) {}
  };

  const handleTelefonoChange = (e) => {
    try {
      if(e.target.value !== null&& e.target.value.length > 0){
        var tel = e.target.value;
    
        setValueTelefono(tel);
          
      }
        if(valueCedula && valueCedula.length > 0 && tel && tel.length > 9&& valueLada && valueLada.length > 0){
      
          setIsValidating(true);
          setisSearching(true);

        AppManager.getDatosPacienteByFolioandPhone(valueCedula, tel, prompt);
        }
       
      
    } catch (e) {}
  };

  const handleCedulaChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length > 0) 
     {
  
        setValueCedula(e.target.value);
        if (
         
          e.target.value !== null &&
          e.target.value.length > 0 &&
          valueTelefono && valueTelefono.length > 9
        ) {
          setIsValidating(true);
          setisSearching(true);
          AppManager.getDatosPacienteByFolioandPhone(e.target.value, valueTelefono, prompt);
        }
      }
    } catch (e) {}
  };

  const handleNombreChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length > 0) {
        setValueNombre(e.target.value);
      }
    } catch (e) {}
  };
  const handleApellidoMaternoChange = (e) => {
    try {
      if (e.target.value !== null && e.target.value.length > 0 ) {
       
        setvalueApellidoMaterno(e.target.value);
      }
    } catch (e) {}
  };
  const handleApellidoPaternoChange = (e) => {
    try {
      if (e.target.value !== null && e.target.value.length > 0) {
        setvalueApellidoPaterno(e.target.value);
      }
    } catch (e) {}
  };
  const handleSegundoNombreChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length > 0) {
        setValueSegundoNombre(e.target.value);
      }
    } catch (e) {}
  };
  const handlePolizaChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length > 0) {
        setValuePoliza(e.target.value);
      }
    } catch (e) {}
  };
  const handleEmailChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length > 0) {
        setValueEmail(e.target.value);
      }
    } catch (e) {}
  }; 

  const handleSucursalChange = (idSucursal) => {
    try { 
        setIdSucursal(idSucursal);
        if (AppManager.isDebug) 
          console.log('Id Sucursal seleccionada:' + idSucursal);      
    } catch (e) {
      console.log(e);
    }
  }; 

  useEffect(() => {
     AppManager.translator.changeLabelsTitle();

    if (AppManager.isDebug) {
      console.log("Agenda-Estudios Seleccionados:" + estudiosSeleccionados);
      console.log("Agenda-Estudios Programados:" + estudiosProgramados);
      console.log("Agenda-Sucursales:" + sucursales);
    }
    if (firstLoad) {
      try {
        setFirstLoad(false);
        var url = getURLSucursal();
        setIsLoadingLadas(true);
        AppManager.getCatalogoPaisesLada(url, prompt);
        setIsLoading(true);
        AppManager.getCatalogoPlanesAseguramiento(url, prompt);
        if (sucursales.length === 0) {
          AppManager.getSucursales(prompt);
        }
      } catch (e) {
        // console.log(e);
      }
    }
    return () => {};
  });

  return (
    <div className={AppConfiguration.theme}>
      <MsgErrorData isOpen={isErrorInData} close={() => setisErrorInData(false)}></MsgErrorData>
      <div
        id="divAgendaCita"
        className="modal container-fluid fade-in top-50 position-absolute start-50 translate-middle"
        style={{
          zIndex: 1,
          display: "block",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div className="row justify-content-center pt-6" style={{ alignItems: "center", height: "100%" }}>
          <div className="col-sm-10 col-lg-7 col-xxl-6">
            <div className="card theme-wizard mb-5" id="wizard">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                id="btnCloseAgendaCita"
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  onClick={closeAgendaCita}
                  aria-label="Close"
                ></button>
              </div>
              <div className="card-header bg-light pt-3 pb-2">
                <ul className="nav justify-content-between">
                  <li className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-cir">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-calendar3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                          </svg>
                        </span>
                      </span>
                      <span data-language_txt="txt_AgendarCita" className="d-md-block mt-1 fs--1 colorP">
                        Agendar Cita
                      </span>
                    </a>
                  </li>
                </ul>
                <ul className="nav justify-content-between">
                  {/* Inactivos*/}
                  <li id="Paso1_Cita_1" className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span id="Paso1_Cita" className="nav-item-Check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-badge-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {" "}
                        {AppManager.translator.current_lang === "es" ? "Datos" : "Data"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso2_Cita_0" className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-UnCheck">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-building"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"></path>
                            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"></path>
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">Sucursal</span>
                    </a>
                  </li>
                  <li id="Paso2_Cita_1" style={{ display: "none" }} className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-Check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-building"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"></path>
                            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"></path>
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">Sucursal</span>
                    </a>
                  </li>
                  <li id="Paso3_Cita_0" className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-UnCheck">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clipboard2-pulse-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                            <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM9.98 5.356 11.372 10h.128a.5.5 0 0 1 0 1H11a.5.5 0 0 1-.479-.356l-.94-3.135-1.092 5.096a.5.5 0 0 1-.968.039L6.383 8.85l-.936 1.873A.5.5 0 0 1 5 11h-.5a.5.5 0 0 1 0-1h.191l1.362-2.724a.5.5 0 0 1 .926.08l.94 3.135 1.092-5.096a.5.5 0 0 1 .968-.039Z" />
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {AppManager.translator.current_lang === "es" ? "Estudios" : "Studies"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso3_Cita_1" style={{ display: "none" }} className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-Check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-clipboard2-pulse-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z" />
                            <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM9.98 5.356 11.372 10h.128a.5.5 0 0 1 0 1H11a.5.5 0 0 1-.479-.356l-.94-3.135-1.092 5.096a.5.5 0 0 1-.968.039L6.383 8.85l-.936 1.873A.5.5 0 0 1 5 11h-.5a.5.5 0 0 1 0-1h.191l1.362-2.724a.5.5 0 0 1 .926.08l.94 3.135 1.092-5.096a.5.5 0 0 1 .968-.039Z" />
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {AppManager.translator.current_lang === "es" ? "Estudios" : "Studies"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso4_Cita_0" className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-UnCheck">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-calendar3-week-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 0a2 2 0 0 0-2 2h16a2 2 0 0 0-2-2H2zM0 14V3h16v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm12-8a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM5 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm5-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM2 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
                            />
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {AppManager.translator.current_lang === "es" ? "Fecha y Horario" : "Date and Time"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso4_Cita_1" style={{ display: "none" }} className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-Check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-calendar3-week-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 0a2 2 0 0 0-2 2h16a2 2 0 0 0-2-2H2zM0 14V3h16v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm12-8a1 1 0 1 0 2 0 1 1 0 0 0-2 0zM5 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm5-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM2 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
                            />
                          </svg>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {AppManager.translator.current_lang === "es" ? "Fecha y Horario" : "Date and Time"}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              {!isLoading ? (
                <div className="card-body" style={{padding:0}}>
                  <div className="tab-content" style={{height:"420px"}}>
                    {/* ****************** */}
                    {/* DATOS DEL PACIENTE */}
                    <div id="tabItem1_Agenda" style={{flexDirection:"column",height:"420px",overflowX:"hidden",overflow:"scroll"}} className="tab-pane container active scrollbarCustom" role="tabpanel">
                      <form className="needs-validation " noValidate>
                        {/*cedula ciudadania*/}
                        <div className="mb-1 mt-2" style={{marginLeft:"5px"}}>
                          <label className="form-label" htmlFor="bootstrap-wizard-wizard-nombre">
                          {AppManager.translator.current_lang === "es" ? "Cédula de Ciudadanía*" : "Citizenship card*"} 
                          </label>
                          <input
                            style={{ fontSize: "11px !important" }}
                            className="form-control"
                            name="cedula"
                            required
                            value={valueCedula}
                            placeholder={AppManager.translator.current_lang === "es" ? "Cédula" : "Card"}
                            id="inputCedula"
                            onChange={handleCedulaChange}
                            data-wizard-validate-email="true"
                          />
                        </div>

                        {/* LADA */}
                        <div style={{display:"flex",flexWrap:"wrap",flexDirection:"row"}}>
                            <div className="col mb-1 mt-1" style={{marginLeft:"5px",minWidth: "185px", display:"flex",flexDirection:"column"}}>
                              <label className="form-label">LADA</label>
                              <FormControl size="small">
                               <ComboSelectLada actual={valueLada} required></ComboSelectLada>
                               </FormControl>
                            </div>

                            <div className="col mb-1 mt-1" style={{marginLeft:"5px", display:"flex",flexDirection:"column"}}>
                              <label className="form-label">
                              {AppManager.translator.current_lang === "es" ? "Telefono*" : "Phone*"}</label>
                              <input
                              style={{minWidth: "185px"}}
                              className="form-control"
                              type="tel"
                              name="telefono"
                              placeholder={AppManager.translator.current_lang === "es" ? "Teléfono":"Phone"}
                              required
                              pattern="[0-9]{10}"
                              defaultValue={valueTelefono}
                              onChange={handleTelefonoChange}
                              id="inputTel"/>
                            </div>

                            {isValidating && isSearching ? (
                              <CircularProgress size={35} style={{ marginLeft: 50 }}></CircularProgress>
                            ) : null}

                            <div className="col mb-1 mt-1" style={{minWidth:"185px",flexDirection:"column",display: !isValidating && !isSearching ? "flex" : "none", marginLeft:"5px"}}>
                              <label className="form-label">
                              {AppManager.translator.current_lang === "es" ? "Email":"Email"}</label>
                              <input
                                style={{minWidth:"185px"}}
                                className="form-control"
                                type="email"
                                name="email"
                                disabled={userData !== null ? true : false}                            
                                placeholder={userData !== null ? userData.Email : AppManager.translator.current_lang === "es" ? "correo@dominio.com" : "email@domain.com"}
                                pattern="^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"
                                defaultValue={Paciente.Email}
                                onChange={handleEmailChange}
                                id="inputEmail"
                              />
                            </div>
                        </div> 

                        {/*nombre*/}
                        <div style={{ display: !isValidating && !isSearching ? "flex" : "none", flexDirection: "row",flexWrap:"wrap", justifyContent: "" }}>
                            <div className="col mb-1 mt-1" style={{marginLeft:"5px",flexDirection:"column", minWidth: "185px", display: !isValidating && !isSearching ? "flex" : "none" }}>
                              <label data-language_lbl="lbl_PrimerNombre" style={{textWrap:"nowrap"}} className="form-label" htmlFor="bootstrap-wizard-wizard-nombre">
                              {AppManager.translator.current_lang === "es" ?  "Primer Nombre*:" : "First Name*:"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important",minWidth:"185px"}}
                                className="form-control"
                                name="nombre"
                                disabled={userData !== null ? true : false}
                              
                                placeholder={userData !== null ? userData.PrimerNombre : AppManager.translator.current_lang === "es" ?  "Primer Nombre*:" : "First Name*:"}
                                id="bootstrap-wizard-wizard-nombre"
                                onChange={handleNombreChange}
                                defaultValue={valueNombre}
                              />
                            </div>
                            <div className="col mb-1 mt-1" style={{flexDirection:"column",marginLeft:"5px",minWidth: "185px", display: !isValidating && !isSearching ? "flex" : "none" }}>
                              <label data-language_lbl="" style={{textWrap:"nowrap"}} className="form-label" htmlFor="bootstrap-wizard-wizard-nombre">
                              {AppManager.translator.current_lang === "es" ? "Segundo Nombre" : "Middle Name" }
                              </label>
                              <input
                                style={{ fontSize: "11px !important",minWidth:"185px"}}
                                className="form-control"
                                name="nombre"
                                required
                                disabled={userData !== null ? true : false}
                        
                                placeholder={userData !== null  ?  userData.SegundoNombre :  ""}
                                id="bootstrap-wizard-wizard-nombre"
                                onChange={handleSegundoNombreChange}
                                defaultValue={valueSegundoNombre}
                              />
                            </div>
                            <div className="col mb-1 mt-1" style={{marginLeft:"5px",flexDirection:"column",marginLeft: "5px", minWidth:"185px",display:!isValidating && !isSearching ? "flex" : "none" }}>
                              <label data-language_lbl="" style={{textWrap:"nowrap"}} className="form-label" htmlFor="bootstrap-wizard-wizard-nombre">
                                {AppManager.translator.current_lang === "es" ?  "Primer Apellido*" :"Last Name*"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important",minWidth:"185px"}}
                                className="form-control"
                                name="nombre"
                                required
                                disabled={userData !== null ? true : false}
                                placeholder={userData !== null ? userData.ApellidoPaterno : AppManager.translator.current_lang === "es" ?  "Primer Apellido" :"Last Name"}  
                                id="bootstrap-wizard-wizard-nombre"
                                onChange={handleApellidoPaternoChange}
                                defaultValue={valueApellidoPaterno}
                              />
                            </div>
                            <div className="col mb-1 mt-1" style={{display:"flex",flexDirection:"column",marginLeft:"5px",minWidth:"185px"}}>
                              <label data-language_lbl="" style={{textWrap:"nowrap"}} className="form-label" htmlFor="bootstrap-wizard-wizard-nombre">
                              {AppManager.translator.current_lang === "es" ?  "Segundo Apellido" :"Second Last Name"}   
                              </label>
                              <input
                                style={{ fontSize: "11px !important", minWidth:"185px"}}
                                className="form-control"
                                name="nombre"
                                required
                                disabled={userData !== null ? true : false}
                                placeholder={userData !== null ? userData.ApellidoMaterno : AppManager.translator.current_lang === "es" ?  "Segundo Apellido" :"Second Last Name"} 
                                id="bootstrap-wizard-wizard-nombre"
                                onChange={handleApellidoMaternoChange}
                                defaultValue={valueApellidoMaterno}
                              />
                            </div>
                        </div> 

                        <div style={{display:"flex",flexWrap:"wrap",display: !isValidating && !isSearching ? "flex" : "none" }}>
                          {/*ARS*/}
                          <div className="col" style={{marginLeft:"5px",minWidth:"185px"}}>
                            <div className="mb-3">
                              <label className="form-label">ARS*</label>
                              <select
                                required
                                value={idPlanSeleccionado}
                                onChange={handleSeleccionaPlan}
                                disabled={isValidating}
                                defaultValue={idPlanSeleccionado}
                                className="form-select"
                                style={{ lineHeight: 1.5 }}
                              >
                                <option key={0} value={0}>
                                {AppManager.translator.current_lang === "es" ? "Seleccionar...":"Select..."}
                                  </option>
                                {arsDisponibles !== null && arsDisponibles.length > 0 ? (
                                  arsDisponibles.map((item, index) => {
                                    return (
                                      <option key={index+2} value={item.Id}>
                                        {item.Nombre}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option key={1} value={0}>
                                    NA
                                  </option>
                                )}
                              </select>
                            </div>
                          </div>
                          {/*poliza*/}
                          <div className="col" style={{marginLeft:"5px",minWidth:"185px"}}>
                            <div className="mb-3">
                              <label data-language_lbl="lbl_Poliza" className="form-label">
                              {AppManager.translator.current_lang === "es" ? "Póliza*":"Insurance policy*"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important" }}
                           
                                id="inputPoliza"
                                className="form-control"
                                type="text"
                                onChange={handlePolizaChange}
                                defaultValue={Paciente.Poliza}
                                required
                                name="poliza"
                                placeholder={userData !== null ? userData.Poliza : AppManager.translator.current_lang === "es" ?"Póliza" : "Insurance policy"}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* *************** */}
                    {/*   SUCURSALES    */}
                    <div
                      id="tabItem2_Agenda"
                      className="tab-pane container fade scrollbarCustom"
                      style={{flexDirection:"column",height: "380px",display:"none", overflow: "scroll", overflowX: "hidden", overflowY: "auto" }}
                      role="tabpanel"
                    >
                      <div className="mb-3 mt-2">
                        <label data-language_lbl="lbl_SeleccionSucursal" className="form-label">
                        {AppManager.translator.current_lang === "es" ? "Seleccione la sucursal de su preferencia":
                          "Select the sucursal of your preference"}
                        </label>
                      </div>
                      <div>
                        {sucursales !== undefined && sucursales.length > 0 ? (
                          sucursales.map((sucursal, indexSucursal) => (
                            <RadioInput
                              key={indexSucursal}
                              nombre={sucursal.Nombre}
                              checked={idSucursal === sucursal.IdServidor ? true : false}
                              direccion={sucursal.Direccion}
                              principal={sucursal.EsPincipal}
                              telefono={sucursal.Telefono}
                              horarios={sucursal.Horarios}
                              value={sucursal.IdServidor}  
                              setter={()=>handleSucursalChange(sucursal.IdServidor)}
                            ></RadioInput>
                          ))
                        ) : (
                          <label data-language_lbl="lbl_NoSucursal">
                              {AppManager.translator.current_lang === "es" ? "No hay sucursales disponibles":
                                "There are no sucursales available"}</label>
                        )}
                      </div>
                    </div>
                    {/* *************** */}
                    {/*    ESTUDIOS     */}
                    <div id="tabItem3_Agenda" style={{flexDirection:"column",height:"430px",display:"none",overflow:"scroll"}} className="scrollbarCustom tab-pane container fade" role="tabpanel">
                      <div style={{flexWrap:"wrap",display:"flex",alignItems:"center"}}>
                        <div className="col">
                          <div className="mb-1 mt-1" style={{marginLeft:"5px"}}>
                            <label className="form-label" data-language_lbl="lbl_Modalidad">
                              Modalidad
                            </label>
                            <select onChange={handleFiltraEstudios} className="form-select" style={{minWidth:"200px"}}>
                              {modalidadesDisponibles !== null && modalidadesDisponibles.length > 0 ? (
                                modalidadesDisponibles.map((item, index) => {
                                  return (
                                    <option key={index} value={item.IdModalidad}>
                                      {item.Modalidad}
                                    </option>
                                  );
                                })
                              ) : (
                                <option>{AppManager.translator.current_lang === "es" ? "Ninguno" : "None"}</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-1 mt-1" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" data-language_lbl="lbl_Estudio">
                              Estudio
                            </label>
                            <FormControl size="small" style={{marginTop:"3px"}}>                             
                            <ComboSelect className="form-select"></ComboSelect>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      {(estudiosSeleccionados !== null || !isUndefined(estudiosSeleccionados)) && estudiosSeleccionados.length > 0 ? (
                        <div
                          className="fondoEstudiosCita scrollbarCustom"
                          style={{
                            flex:1,
                            marginBottom: "20px", 
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {estudiosSeleccionados.map((item) => {
                            return (
                              <EstudioCita
                                key={item.idEstudio}
                                estudio={item}
                                onCloseEstudio={() => quitarEstudio(item.idEstudio)}
                              ></EstudioCita>
                            );
                          })}
                        </div>
                      ) : (
                        <div
                          style={{
                            marginBottom: "20px",
                            flex:1,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="colorP" style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "16px" }}>
                            {AppManager.translator.current_lang === "es"
                              ? "Seleccione los estudios que desea realizarse..."
                              : "Select the studies you wish to undergo..."}
                          </div>
                        </div>
                      )}
                      <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%" }}>
                        <div className="col">
                         </div>
                        <div className="col" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                          <div style={{ display: "inline-flex" }}>
                            <label style={{ marginBottom: "0px" }}>SubTotal $:</label>
                            <label style={{ color: "gray", fontWeight: "bold", marginBottom: "0px" }}>
                              {estudiosSeleccionados.reduce((accum, item) => accum + item.Precio, 0)}
                            </label>
                          </div>
                          <div style={{ display: "inline-flex" }}>
                            <label style={{ marginBottom: "0px" }} data-language_lbl="lbl_Descuento">
                              - Descuento $:
                            </label>
                            <label style={{ color: "red", fontWeight: "bold", marginBottom: "0px" }}>
                              {estudiosSeleccionados.reduce((accum, item) => accum + item.Descuento, 0)}
                            </label>
                          </div>
                          <div style={{ display: "inline-flex" }}>
                            <label style={{ fontWeight: "bold", marginBottom: "0px" }}>Total $:</label>
                            <label style={{ color: "green", fontWeight: "bold", marginBottom: "0px" }}>
                              {estudiosSeleccionados.reduce((accum, item) => accum + item.Precio, 0) -
                                estudiosSeleccionados.reduce((accum, item) => accum + item.Descuento, 0)}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ********************* */}
                    {/*   FECHA Y HORA CITA   */}
                    <div
                      id="tabItem4_Agenda"
                      className="tab-pane scrollbarCustom fade text-center"
                      role="tabpanel"
                      style={{
                        flexDirection:"column",
                        height: "430px",
                        overflow: "scroll",
                        overflowY: "auto",
                        overflowX: "hidden",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        display:"none",
                      }}
                    >
                      {(estudiosSeleccionados !== null || !isUndefined(estudiosSeleccionados)) && estudiosSeleccionados.length > 0 ? (
                        <div
                          className="fondoEstudiosCita"
                          style={{ marginBottom: "20px", 
                          flex: "1 1 auto", display: "flex", flexDirection: "column", flexWrap: "wrap" }}
                        >
                          {estudiosSeleccionados.map((item) => {
                            return (
                              <EstudioFechaHora
                                horasDisponibles={filterHorarioEstudio(item.idEstudio)}
                                onchangeTime={onHoraEstudioSeleccionado}
                                key={item.idEstudio}
                                estudio={item}
                                onChangeDate={onFechaEstudioSeleccionado}
                                onCloseEstudio={() => quitarEstudio(item.idEstudio)}
                              ></EstudioFechaHora>
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ height: "95%", display: "flex", justifyContent: "center", flexDirection: "row" }}>
                          <div className="colorP" style={{ display: "flex", alignItems: "center" }}>
                            {AppManager.translator.current_lang === "es"
                              ? "No hay estudios seleccionados..."
                              : "There are no selected studies..."}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/*MENSAJE TERMINACION DE CITA */}
                  {terminaCita ? (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        top: "100px",
                        width: "100%",
                        height: "580px",
                        left: "0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="colorP"
                        style={{
                          background: "#f8f7f7cf",
                          borderRadius: "22px",
                          padding: "21px",
                          border: "2px solid #d8d8d8cf",
                          textAlign: "center",
                          fontWeight: "bold",
                          margin: "40px",
                          fontSize: "16px",
                        }}
                      >
                        {mensaje}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 500 }}>
                  <CircularProgress size={60}></CircularProgress>
                </div>
              )}
              {/*FOOTER*/}
              {!terminaCita && !isLoading ? (
                <div className="card-footer bg-light" style={{ height: "4rem" }}>
                  <div style={{ display: "flex",flexDirection:"row",justifyContent:"space-between"}}>
                  {itemIndex <= 1 ? 
                    <p id="btn_backhomepage"
                      onClick={() => {
                        closeAgendaCita();
                      }}> 
                      {AppManager.translator.current_lang === "es" ?
                          (isMobile ? "Regresar" :"Regresar a página principal") :
                          (isMobile ? "Back" : "Return to main page")}
                    </p> :
                    <></>} 
                      <div className="previous">
                        {itemIndex != 1 ? (
                          <button id="btnAgendaPrev" className="btn btn btn-primary" type="button" onClick={changeTabPrev}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-chevron-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              />
                            </svg>
                            <label data-language_lbl="lbl_Prev">Regresar</label>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="next" style={{ cursor: "pointer" }} onClick={changeTabNext}>
                        <button id="btnAgendaNext" style={{ marginLeft: "10px" }} className="btn btn-primary" >
                          <label data-language_lbl="lbl_Next">Next</label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </button>
                      </div> 
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/*MENSAJES GENERALES*/}
              {!terminaCita && mensaje != "" ? (
                <div className="colorP container mesajeHiddeAuto" style={{ position: "absolute", width: "100%", bottom: "90px" }}>
                  <div
                    style={{
                      background: "#f8f7f7cf",
                      borderRadius: "22px",
                      padding: "11px",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #d8d8d8cf",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <div className="z-index-1" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        style={{ width: "1.8rem", height: "1.8rem" }}
                        className="btn-close btn btn-sm btn-circle transition-base"
                        onClick={onHiddeMessage}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div style={{ marginBottom: "10px" }}>{mensaje} </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgendaCita;
