import React,{ useContext, useState} from 'react'
import SystemContext from '../../../../AppState/SystemConfig/appContext';
import CircularProgress from '@mui/material/CircularProgress'
import {DropdownButton,Dropdown} from 'react-bootstrap/';
import {isMobile} from "react-device-detect";
import PanelFactura from '../Facturacion/consultaFolio';

import "./styles.css"
import { useEffect } from 'react';

function Signin(props) {
   //Los props son parametros que se dan en la instancia del componente <Signin/>
   {/* Signin props*/}
   {/* openForgot: funcion que cambia el estado del variable a true */}
  const opciones=[{id:4,name:"Cédula de Ciudadanía"},{id:5,name:"Cédula de Extranjería"},{id:6,name:"Carné Diplomatico"},{id:7,name:"Pasaporte"},{id:8,name:"Salvo Conducto"},{id:9,name:"Permiso especial de Permanencia"},{id:10,name:"Registro Civil de Nacimiento"},{id:11,name:"Tarjeta de Identidad"},{id:12,name:"Certificado de Nacido Vivo"},{id:13,name:"Adulto sin Identificar"},{id:14,name:"Menor sin identificar"},{id:15,name:"Documento Extranjero"},{id:16,name:"Sin Identificación"},{id:17,name:"NIT"}]
  const {AppManager,AppConfiguration} =useContext(SystemContext);
  const [dropDownValue,setDropDownValue]=useState('');
  const [type, setType] = useState("password"); //Cambio de vista del input () se pasa parametro inicial de la variable
  const [loading, setLoading] = useState(false); 
  const [idCarnete, setidCarnete] = useState(0); 
  const [showCitas, setShowCitas] = useState(false); 
  const [showPanelFacturacion, setShowPanelFacturacion] = useState(false); 
  let dropItems = []

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
  })

  opciones.forEach(({id,name}) => {
    dropItems.push(<Dropdown.Item key={name+"-"+id} eventKey={id}>{name}</Dropdown.Item>)
  })
  
  //Click Login
  const clickLogin = (event)=>{  
    event.preventDefault();
    if (!AppManager.reqLoadingPending )
    {
      setLoading(true);
      let usuario = $('#card-username').val();
      let paswword = $('#card-password').val();

      AppManager.clickLogin(idCarnete,usuario,paswword)  
         
    }    
  }
 const agendarCitaOnclick=()=>{
  props.setShowCitas(true)
 }
  const handleSelect=(e,text)=>{
    setDropDownValue(text.target.text)
    setidCarnete(e);
  }

  const facturaCitaOnclick=()=>{
    setShowPanelFacturacion(true);
    props.showIndicacionesFacturacion(true);
   }

   const regresaLoginOnclick=()=>{
    props.showIndicacionesFacturacion(false); 
    setShowPanelFacturacion(false);
   }

   const muestraFacturacionResultado=(datos)=>{ 
    props.showModalFacturacion(datos); 
   } 

   const muestraConsultaFactura=()=>{ 
    props.showModalConsultaFacturacion(); 
   } 


  return (
    <div>
                      
    {/*IDIOMA*/}
    {isMobile ? 
    <div 
        className="p-2 flex-grow-1"
        style={{
          justifyContent: "flex-end",
          display: "flex",
          paddingTop: "0.5rem",
          position: "absolute",
          top: "0px",
          right: "0px",
          zIndex: "1",
        }}>
        { AppConfiguration.facturacion  && !showPanelFacturacion? 
          <div 
          className="h5 opacity-75"
          style={{ paddingRight: "2rem",cursor: "pointer" }}
          onClick={facturaCitaOnclick}
        ><span data-language_txt="txt_FacturacionTitle">FACTURACION</span>
        </div>:<></>}
        <div
          id="itemLangEN"
          className="h5 opacity-75"
          style={{ paddingRight: "1.5rem", cursor: "pointer" }}
          onClick={() => {
            AppManager.changeLanguaje("en");
          }}
        >EN
        </div>
        <div
          id="itemLangES"
          className="h5 opacity-75"
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            paddingRight: "1.2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            AppManager.changeLanguaje("es");
          }}
        >ES
        </div> 
      </div>:
      <div 
        className="p-4 flex-grow-1"
        style={{
          justifyContent: "flex-end",
          display: "flex",
          paddingTop: "0.5rem",
          position: "absolute",
          top: "0px",
          right: "0px",
          zIndex: "1",
        }}>
        { AppConfiguration.facturacion  && !showPanelFacturacion? 
          <div 
          className="h5 opacity-75"
          style={{ paddingRight: "2rem",cursor: "pointer" }}
          onClick={facturaCitaOnclick}
        ><span data-language_txt="txt_FacturacionTitle">FACTURACION</span>
        </div>:<></>}
        <div
          id="itemLangEN"
          className="h5 opacity-75"
          style={{ paddingRight: "1.5rem", cursor: "pointer" }}
          onClick={() => {
            AppManager.changeLanguaje("en");
          }}
        >EN
        </div>
        <div
          id="itemLangES"
          className="h5 opacity-75"
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            paddingRight: "1.2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            AppManager.changeLanguaje("es");
          }}
        >ES
        </div> 
      </div>}
    {/*FIN IDIOMA*/}  

    {/*FACTURACION*/}  
    {showPanelFacturacion ?  
    <PanelFactura backSingnin={regresaLoginOnclick} muestraFacturacion={muestraFacturacionResultado} muestraConsultaFactura={muestraConsultaFactura}></PanelFactura>:
    
    /*SIGNIN*/ 
    <>
     <div className="row flex-between-center">
        <div className="col-auto">
          <h3 data-language_txt="txt_sesion"></h3>
        </div>
      </div> 
      <form onSubmit={clickLogin} >
      {AppConfiguration.showIdentificaciónBtn?<div className="mb-3" style={{marginTop:"15px"}}>
          <div className="d-flex justify-content-between">
              <label className="form-label" htmlFor="card-password" data-language_lbl="lbl_userId">Identificación</label>
            </div>
           
           <DropdownButton
              onSelect={handleSelect}
              // as={"ButtonGroup"}
              align={{ lg: 'end' }}
              title={dropDownValue!=""?dropDownValue: <label data-language_lbl="lbl_selection"  style={{margin:0}}>Seleccionar</label>}
              id="dropdown-menu-align-responsive-1"
            >
            {dropItems}

            </DropdownButton>
          
      </div>
      :<></>}
      <div className="mb-3">
        <label className="form-label" htmlFor="card-username" data-language_lbl="lbl_usuario"></label>
        <input className="form-control" id="card-username" required />
      </div>
      <div className="mb-3">
        <div className="d-flex justify-content-between">
          <label className="form-label" htmlFor="card-password" data-language_lbl="lbl_password"></label>
        </div>
        <div className="form-control">
              <input style={{border:"none"}} id="card-password"  autoComplete="on" required type={type}/>
              <span data-language_txt="txt_show" id="mostrar" onClick={()=>{ //Llamamos la funcion de mostrar password ()=>{} para prevenir carga default
                showPassword()
              }} >MOSTRAR</span>
        </div>
        
      </div>

      

      <div style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",alignItems:"center"}} className="flex-between-center">
        <div className="col-auto">
          <div className="form-check mb-0">
            <input className="form-check-input" type="checkbox" id="card-checkbox" defaultChecked="checked" />
            <label  data-language_lbl="lbl_remember" className="form-check-label mb-0" htmlFor="card-checkbox"></label>
          </div>
        </div>
        <a data-language_txt="txt_forgotPassword" style={{cursor:"pointer"}} className="col-auto id-link-basic" onClick={ ()=>{
          props.openForgot()
        }}><span className="fs--1">Forgot Password?</span></a>
      </div>
      <div className="mb-3">
      <button className="id-btn d-block w-100 mt-3" type="submit" onClick={clickLogin}><div style={{display:"flex",alignItems:"center",justifyContent:"center",padding:5}}>
        <span data-language_txt="txt_login">Iniciar Sesión</span>
         {loading?<CircularProgress id="circularLogin"size={20} color='inherit' style={{marginLeft:"10px"}}/>:<></>}</div></button>
      </div>
    </form>
{    AppConfiguration.agendarCitas?<p id='agendarCita' onClick={agendarCitaOnclick}>
  <span data-language_txt="txt_agendaCitaSinCuenta">¿Deseas agendar una cita y no tienes una cuenta? ¡Da clic aquí!</span></p>:<></>}
  </>}
  </div> 
  
  )
  function showPassword() {
    var text = document.getElementById("mostrar");
    if (type === "password") {
      if (AppManager.translator.current_lang === 'es')
        text.innerText="OCULTAR";
      else
        text.innerText="HIDE";
      setType("text")
    } else {
      if (AppManager.translator.current_lang === 'es')
         text.innerText="MOSTRAR"
      else
         text.innerText="SHOW"
      setType("password");
    }
  }
}

export default Signin