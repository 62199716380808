import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ParticlesJS = () => {
  const particlesInit = async (main) => {
    // console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    // console.log(container);
  };
  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        "background": {
          "color": {
            "value": "transparent"
          },
          "position": "50% 50%",
          "repeat": "no-repeat",
          "size": "cover"
        },
        "fullScreen": {
          "zIndex": 1
        },
        "interactivity": {
          "events": {
            "onClick": {
              "mode": "push"
            },
            "onHover": {
              "enable": true,
              "mode": "bubble"
            }
          },
          "modes": {
            "bubble": {
              "distance": 400,
              "duration": 4,
              "opacity": 0.8,
              "color": {
                "value": "#AFAFAF"
              },
              "size": 40,
              "divs": {
                "distance": 200,
                "duration": 0.4,
                "mix": false,
                "selectors": []
              }
            },
            "grab": {
              "distance": 400
            },
            "repulse": {
              "divs": {
                "distance": 200,
                "duration": 0.4,
                "factor": 100,
                "speed": 1,
                "maxSpeed": 50,
                "easing": "ease-out-quad",
                "selectors": []
              }
            }
          }
        },
        "particles": {
          "color": {
            "value": "#1b1e34"
          },
          "links": {
            "color": {
              "value": "#ffffff"
            },
            "distance": 200,
            "width": 2
          },
          "move": {
            "attract": {
              "rotate": {
                "x": 600,
                "y": 1200
              }
            },
            "enable": true,
            "path": {},
            "outModes": {
              "bottom": "out",
              "left": "out",
              "right": "out",
              "top": "out"
            },
            "speed": 8,
            "spin": {}
          },
          "number": {
            "density": {
              "enable": true,
  
            },
            "value": 3
          },
          "opacity": {
            "random": {
              "enable": true,
              "minimumValue": 0.3
            },
            "value": {
              "min": 0.3,
              "max": 0.5
            },
            "animation": {
              "speed": 1,
              "minimumValue": 0.1
            }
          },
          "shape": {
            "options": {
              "polygon": {
                "sides": 6
              },
              "star": {
                "sides": 6
              }
            },
            "type": "polygon"
          },
          "size": {
            "random": {
              "enable": true,
              "minimumValue": 100
            },
            "value": {
              "min": 100,
              "max": 160
            },
            "animation": {
              "minimumValue": 40
            }
          }
        }
      }}
    />
  );
};
export default ParticlesJS