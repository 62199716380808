import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import React , { useContext, useState } from 'react';
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";


function Reporte({closeModal,title,subtitle,folio,urlServicio}) {
  
  const { AppManager } = useContext(typeScriptContext);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState(false);
    const [total, setTotal] = useState(1);
    // console.log(`${urlServicio}ImagingReportFull?folio=${folio}`)
    function onDocumentLoadSuccess({ numPages }) {
        setTotal(numPages);
        setloading(false);
      }
    const closeShareData = () => {   
        closeModal() 
    };
    const onError=(error)=>{
      if(error){
        setloading(false)
        seterror(true)
      }
    }
    function printPages(){
        let elements=[]
        for (let index = 1; index <= total; index++) {
            elements.push(<Page key={`pageno-${index}`} pageNumber={index} />) 
        }
        return elements;
    }

   return ( 
    <div id="modalPdf" className="modal fade-in " tabIndex="1" role="dialog" aria-hidden="true" style={{backgroundColor:"#0000007a",display:"block"}}>
    <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:"900px"}}>
      <div className="modal-content position-relative">
        <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" onClick={closeShareData}></button>
        </div>
        <div className="modal-body p-0">
          <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
            <h4 className="mb-1">{title}</h4>
            <h6 className="mb-1" ><strong>Modalidad: </strong>{subtitle}</h6>
          </div>
        
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%"}}>
            
            <div id="pdfContainer">
                    <Document  object-fit="fill" size="A4"  file={{url:`${urlServicio}ImagingReportFull?folio=${folio}&Token=${AppManager.userToken}`}} onLoadSuccess={onDocumentLoadSuccess} error={onError}>
                      {printPages()}
                    </Document>
                {/* {error?<h1></h1>:<></>} */}
               
            </div>
           
        </div>
       
        </div> 
      </div>
    </div>
  </div>
    ) 

  }
  
  export default Reporte