import React from 'react'

function MsgInfo() {


  const closeMsgInfo = () => {   
    document.getElementById("divInfoMessage").style.display = "none";    
    document.getElementById("divInfoMessage").style.opacity = "0";   
  };
 
 return ( 
  <div id="divInfoMessage" className='modal fade-in' tabIndex="-1" role="dialog" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:"500px"}}>
    <div className="modal-content position-relative">
      <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" onClick={closeMsgInfo}></button>
      </div>
      <div className="modal-body p-0">
        <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h4 className="mb-1" data-language_txt="txt_AvisoConfTitle" id="modalExampleDemoLabel"></h4>
        </div>
        <div className="p-4 pb-0 row">          
          <label  data-language_txt="txt_AvisoConfDescription" className="col-form-label"></label>   
          <h5 className="mb-3 mt-3" data-language_txt="txt_AvisoConfSubTitle" id="modalExampleDemoLabel"></h5>
        </div>
      </div> 
    </div>
  </div>
</div>
  ) 
}

export default MsgInfo