import React, { useState, useContext, useEffect } from "react";  
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";   
import Checkbox from '@mui/material/Checkbox';
import dayjs from "dayjs";

function FacturaItem({factura,checked, setter,value}) { 
 
  const { AppManager,AppConfiguration } = useContext(typeScriptContext); 
  const [isShowInstructions, setShowInstructions] = useState(0);   

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();  
    return () => {};
  }, [AppManager]);

  return (
    <label key={value} className="cardFactura" style={{marginTop:"10px",marginBottom:"10px",width:"100%"}}>
        <div style={{alignItems:"center",margin:5,width:"100%",display:"flex",flexDirection:"row"}}>  
        <Checkbox onChange={setter} style={{marginLeft:"10px",minWidth:"14px"}}/> 
        <div style={{margin:10,width:"100%",display:"flex",flexDirection:"column"}}>
          {/*Folio */}
          <div style={{display:"flex",flexWrap:"wrap"}}>
            <label className="form-label" style={{marginLeft:"5px",fontWeight:"bold",marginBottom:"0px"}}>
              {AppManager.translator.current_lang === "es" ? "Folio:" : "Transaction ID:"} 
            </label>
            <span style={{marginLeft:"5px",fontWeight:"normal",marginBottom:"0px",overflow:"hidden",height:"25px",textOverflow:"ellipsis",textWrap:"nowrap",wordBreak:"break-word"}}>{factura.Folio}</span>
          </div>
          {/*Fecha factura */}
          <div style={{display:"flex",flexWrap:"wrap"}}>
            <label className="form-label" style={{marginLeft:"5px",marginBottom:"0px",fontWeight:"bold"}}>
              {AppManager.translator.current_lang === "es" ? "Fecha Factura:" : "Invoice Date:"} 
            </label>
            <span style={{marginLeft:"5px",fontWeight:"normal",marginBottom:"0px",overflow:"hidden",height:"25px",textOverflow:"ellipsis",textWrap:"nowrap",wordBreak:"break-word"}}>{dayjs(factura.FechaFactura).format("YYYY-MM-DD")}</span>
          </div>
          {/*Nombre */}
          <div style={{display:"flex",flexWrap:"wrap"}}>
            <label className="form-label" style={{marginLeft:"5px",fontWeight:"bold",marginBottom:"0px"}}>
              {AppManager.translator.current_lang === "es" ? "Nombre:" : "Name:"} 
            </label>
            <span style={{marginLeft:"5px",ontWeight:"normal",marginBottom:"0px",overflow:"hidden",height:"25px",textOverflow:"ellipsis",textWrap:"nowrap",wordBreak:"break-word"}}>{factura.NombrePaciente}</span>
          </div>
          {/*Lista estudios */}
          <div style={{display:"flex",flexDirection:"column"}}>
            <label className="form-label" style={{marginLeft:"5px",fontWeight:"bold",marginBottom:"0px"}}>
              {AppManager.translator.current_lang === "es" ? "Estudio(s):" : "Study(ies):"} 
            </label>
            {factura.ListaEstudios.map((item,index) => {
              return (
              <div style={{display:"flex",alignItems:"flex-end",flexDirection:"row",width:"90%",borderBottom:"1px dashed",marginLeft:"5px",fontSize:"11px",flexWrap:"wrap"}}>
                <span className="col-6" style={{fontWeight:"normal",marginBottom:"0px"}}>
                       {item.Nombre}</span>
                <div className="col-2" style={{display:"flex",flexDirection:"row"}}>
                  <span className="col" style={{fontWeight:"normal",marginBottom:"0px",marginLeft:10,textWrap:"nowrap"}}>
                  {AppManager.translator.current_lang === "es" ? "Importe: $ " : "Ammount: $"} </span>
                  <span className="col" style={{fontWeight:"normal",marginBottom:"0px",margingLeft:10,textWrap:"nowrap"}}>
                       {item.Importe}</span>
                </div>
              </div>);
            })} 
          </div>
        </div>
      </div> 
    </label>
  );
}
export default FacturaItem;
