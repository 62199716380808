import React, { useContext,useState,useEffect } from 'react';
import typeScriptContext from '../AppState/SystemConfig/appContext';
import CircularProgress from '@mui/material/CircularProgress'

const ComponentToPrint = () => {
  
  if(!window.parameters){
    window.close();
  }
  const {folio,idPlan,fechaInicio,fechaFin,tipo,URLServicio} = JSON.parse(window.parameters)
  const [pdf, setPDF] = useState([]);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { AppManager } = useContext(typeScriptContext);


  const setData = (base64) => {
    setPDF(base64); //Local  
    if(base64&&(base64.length<5||!base64)){
      setTimeout(() => {setError(true)}, 10000);
    }
  };

  prompt.setPDF = setData;

  useEffect(() => {
   
    if(!loaded){
    
        AppManager.loadPDF(prompt,folio,URLServicio);
        setLoaded(true);
    }
   
    
    return () => {
    
    };
  }, [AppManager]);


  
  return (
   <>
   { pdf&&pdf.length>5?<object  onError={()=>{setError(true)}} style={{width:"100%",height:"99vh"}} data={pdf} type='application/pdf'></object>
      : error?<div style={{width:"100%",height:"99vh",display:"flex",justifyContent:"center",alignItems:"center"}}><h5>Something is wrong, try again</h5></div>:<div style={{width:"100%",height:"99vh",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress size={70}></CircularProgress></div> 
    }
    
   </>
  );
};

export default ComponentToPrint

