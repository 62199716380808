import React, { useState, useContext, useEffect } from "react";  
import typeScriptContext from "../../../../AppState/SystemConfig/appContext"; 
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined'; 
function EstudioItem({estudio}) { 
 
  const { AppManager,AppConfiguration } = useContext(typeScriptContext); 
  const [isShowInstructions, setShowInstructions] = useState(0);   

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();  
    return () => {};
  }, [AppManager]);
    
  return (
    <div className="cardEstudio" style={{minHeight:"65px",alignItems:"center",margin:5,width:"100%",display:"flex",flexDirection:"row",flexWrap:"wrap"}}> 
      <DoneOutlineOutlinedIcon style={{marginLeft:"5px"}}></DoneOutlineOutlinedIcon>
      <div className="titleStudyCita" style={{fontWeight:"bold",paddingLeft:10,width:"70%",overflow:"hidden",height:"25px",textOverflow:"ellipsis",textWrap:"nowrap",wordBreak:"break-word"}}>{estudio.Nombre}</div>
      <div className="titleStudyCita" style={{marginLeft:"30px",display:"inline-flex"}}><label data-language_lbl="lbl_Importe">Importe $:</label><label>{estudio.Importe?estudio.Importe:"0"}</label></div> 
   </div> 
  );
}
export default EstudioItem;
