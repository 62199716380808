import { useContext, useEffect, useState } from "react";
import typeScriptContext from "../../../AppState/SystemConfig/appContext";
import _, { set } from "lodash";

import Table from "./Tables/table.jsx";
import TableCompany from "./Tables/tableCompany.jsx";
import ModalSedes from "./Modal/sedes/sedesModal";

function SetTableByTokenType({ profile }) {
  const { AppManager, AppConfiguration } = useContext(typeScriptContext);
  const [estudios, setEstudios] = useState([]);
  const [estudiosSede, setEstudiosbySede] = useState([]);
  //Cargamos la fecha actual
  const date1 = new Date();
  const dayNow = date1.toISOString().split("T")[0];

  const date2 = new Date();
  date2.setMonth(date2.getMonth() - 12);
  const lastYear = date2.toISOString().split("T")[0];
  const [actualDate, setActualDate] = useState({
    inicio: lastYear,
    fin: dayNow,
  }); //Date for company table content

  //Conexion con appManager
  prompt.setStudies = (dataSede) => {
    // Comprobamos si ya existe un objeto con el mismo "sede" en el array
    const existingSede = estudiosSede.find((estudio) => estudio.sede === dataSede.sede);

    if (existingSede) {
      // Si existe, actualizamos la propiedad "data"
      const updatedEstudiosSede = estudiosSede.map((estudio) =>
        estudio.sede === dataSede.sede ? { ...estudio, data: dataSede.data } : estudio
      );

      setEstudiosbySede(updatedEstudiosSede);
    } else {
      // Si no existe, creamos un nuevo objeto y lo agregamos al array
      const newStudy = { sede: dataSede.sede, data: dataSede.data };
     
      setEstudiosbySede([...estudiosSede, newStudy]);
    }

    // if(arry.length>0){
    //  setEstudios(estudios=>[...estudios,...arry])
    // }
  };
  prompt.actualDate = actualDate;



  useEffect(() => {
    if (!AppManager.isSettingStudies && estudiosSede.length == 0) {
      AppManager.loadStudies(prompt); //Cargamos los estudios
    }

    //========Si no hay sedes las solicitamos solo cedisa=======
    if (!AppManager.sedesloader && (!AppManager.sedesURLS || AppManager.sedesURLS.length == 0) && AppConfiguration.nombre == "CEDISA") {
      AppManager.initSedes(true); //Cargamos las sedes
    }
    
    
    if (estudiosSede.length != 0) {
      for (let index = 0; index < estudiosSede.length; index++) {
        const element = estudiosSede[index];
       setEstudios((estudios) => [...estudios, ...element.data]);
      }
    }

    return () => {

      setEstudios([]); //Limpiamos el array de estudios
    };
  }, [AppManager, actualDate,estudiosSede]);

  const setDate = (date) => {
    setActualDate(date);
  };

  if (AppManager.loginByNIT.toString() == "true") {
    return (
      <TableCompany
        data={estudios}
        setDate={setDate}
        userData={profile}
        actualDate={actualDate}
        urlServicio={AppManager.urlServicio}
        urlVisor={AppManager.urlVisor}
        urlDescarga={`${AppManager.urlUltimaApi}/GetStudyZip`}
      ></TableCompany>
    );
  }

  return (
    <div>
      <Table
        userData={profile}
        data={estudios}
        urlServicio={AppManager.urlServicio}
        urlsVisor={AppManager.sedesURLS}
        urlVisor={`${AppManager.urlRoot}/`}
        urlDescarga={`${AppManager.urlUltimaApi}/GetStudyZip`}
        isLoading={AppManager.isSettingStudies}
      ></Table>
      <ModalSedes></ModalSedes>
    </div>
  );
}

export default SetTableByTokenType;
