import React from 'react'
import imgErrorConn from './conexionError.png'


function MsgErrorConnection({openMessage}) {

  const closeMessage = () => {  

    document.getElementById("divErrorConnection").style.display = "none";  
    if(  document.getElementById("circularLogin")){
      document.getElementById("circularLogin").style.display = "none";     
    }
  };
 
 return ( 
    <div id="divErrorConnection" className="container-fluid" style={{position:"absolute",zIndex:"1",height:"100%",display:"none"}}>
    {/*ERROR DE CONEXION*/}
    <div className="row flex-center g-0">
        <div className="col-lg-8 col-xxl-5 py-3" style={{height:"100%",justifyContent:"flex-end",display:"flex",flexDirection:"column"}}>
        <div style={{minHeight:"25%", background:"white",boxShadow:"1px 1px 11px gray",display:"flex",flexDirection:"column",alignContent:"center", alignItems:"center"}}>
          <div className="z-index-0 position-relative" style={{textAlign:"center"}}><img src={imgErrorConn} style={{marginTop:"1rem"}} alt="" height="50" width="50"/>
            <p data-language_txt="txt_ErrorConnection" style={{marginTop:"10px",fontWeight:"bold",color:"black"}} className="opacity-95">Connection error!</p>
            <p data-language_txt="txt_DescriptionError" style={{marginTop:"10px",color:"black",fontSize:"0.8rem",padding:"1rem"}} className="opacity-75">Sorry, at the moment we can not connect to our services, try to download it later.</p>
          </div>
          <div className="mb-3">
            <button data-language_btn="btn_Retry" className="btn btn-primary d-block mt-3" type="submit" onClick={closeMessage} name="Reload">Retry</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  ) 
}

export default MsgErrorConnection