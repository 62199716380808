


export class ManagerModales {

    constructor() {
        
        try {

            //let message2: string = 'Hello Web asdda222wqwewqe';
            //document.body.innerHTML = message2;

        } catch (ex) {
            
        }
    }
}
