
import { useLocation  } from 'react-router';
import { useNavigate } from "react-router-dom";
import { useContext} from 'react'
import systemContext from '../../AppState/SystemConfig/appContext';
import { useEffect } from 'react';

function  UserAccess() {
    const {AppManager} =useContext(systemContext);
    const {search}= useLocation()
    const navigate = useNavigate();
 
    useEffect(() => {
        if(search){
            const queryParams = new URLSearchParams(search);
    
            // Obtener el valor del parámetro "token" de los query params
            const token = queryParams.get('token');
            // let splited=search.split("?")
            // // let token=splited[0]
            // // let credencial=splited[1]
            
            // // const paramSplited=token.split("=") //Obtenemos el texto
            // // const paramSplited2=credencial.split("=")
            
            // token=paramSplited[1]
            // // credencial=paramSplited2[1]
            // // alert(`${paramSplited[1]},${paramSplited2[1]}`)
        
            AppManager.setToken(token)    
            navigate("/loading");
        }
    }, [])

    return (<></>)
}

export default  UserAccess;