import React, { useContext } from 'react'
import SystemContext from '../../../AppState/SystemConfig/appContext';
import imgEmail from '../img/email.png'

function RetrievePass(props) {
  /* Forgot props*/
  /* closeForgot: funcion que cambia el estado del variable del login a false */

  const {AppManager} =useContext(SystemContext);

  
  return (
<form>
    <h3 data-language_txt="txt_TitleRetry">¿No puedes iniciar sesión?</h3>
    <img src={imgEmail} className="start-50 translate-middle" style={{marginTop:"3rem",position:"relative"}} alt="" height="70" width="80"/>
    <div className="mb-3">
      <label className="form-label" htmlFor="card-username" data-language_lbl="lbl_SendLink">Te hemos enviado un enlace de recuperación a tu cuenta de correo electronico.</label>
  
    </div> 
    <div className="mb-3 row" style={{borderBottom:"1px solid", opacity:"0.2"}}></div> 
    <div className="mb-3 row" style={{cursor:"pointer"}}> 
      <a className="col-auto id-link-basic" onClick={()=>{
        props.backForgot();
        AppManager.changeLanguaje(AppManager.translator.current_lang);
        }}><span data-language_txt="txt_BackRetry" className="fs--1">Regresar a inicio de sesión</span></a>
        <a className="col-auto id-link-basic" onClick={()=>{
        props.backRetrieve();
        AppManager.changeLanguaje(AppManager.translator.current_lang);
        }}><span data-language_txt="txt_ReSendLink" className="fs--1">Volver a enviar enlace de recuperación</span></a>
    </div>  
  </form>

  )
}

export default RetrievePass