import imgErroData from './dataPatientError.png'
import React, {useContext, useEffect } from "react";  
import typeScriptContext from "../../../../../AppState/SystemConfig/appContext";  

function MsgErrorData({isOpen,close}) {

  const { AppManager} = useContext(typeScriptContext);   

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();  
    return () => {};
  }, [AppManager]);

  const closeMessageDataError = () => {   
    document.getElementById("divErrorData").style.display = "none";    
    $("#circularLogin").css('display', 'none'); 
    close();
    //document.getElementById("divErrorData").classList.remove("top-50");       
  };
 
 return ( 
    <div id="divErrorData" className="container-fluid fade-in top-50 position-absolute start-50 translate-middle" style={{zIndex:"10",display:isOpen?"":"none"}}>
    {/*ERROR DE DATOS*/}
    <div className="row flex-center g-0">
        <div className="col-lg-8 col-xxl-5 py-3" style={{height:"100%",justifyContent:"flex-end",display:"flex",flexDirection:"column"}}>
        <div style={{minHeight:"25%", background:"white",boxShadow:"1px 1px 11px gray",display:"flex",flexDirection:"column",alignContent:"center", alignItems:"center"}}>
          <div className="z-index-1 position-relative" style={{textAlign:"center"}}><img src={imgErroData} style={{marginTop:"1rem"}} alt="" height="50" width="50"/>
            <p data-language_txt="txt_InfoCaptura" style={{marginTop:"10px",fontWeight:"bold",color:"black"}} className="opacity-95">Informacion de Captura</p>
            <p data-language_txt="txt_DatosPropIncorrectos" style={{marginTop:"10px",color:"black",fontSize:"0.8rem",padding:"1rem"}} className="opacity-75">Los datos proporcionados son incorrectos!</p>
          </div>
          <div className="mb-3">
            <button data-language_btn="btn_Ok" className="btn btn-primary d-block mt-3" type="submit" onClick={closeMessageDataError} name="Reload">Ok</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  ) 
}

export default MsgErrorData