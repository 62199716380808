import React, { useState, useEffect, useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import ModalPdf from "../Modal/reporte";
import DownloadData from "../Modal/download.js";
import Tooltip from "@mui/material/Tooltip";
import ModalCita from "../Dates/agendaCita";

import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";
import { TableSortLabel } from "@mui/material";
import { Paper, Tab, Tabs } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";

//Icons
import Icon from "./img/download.png";
import Icon1 from "./img/file.png";
import IconWithoutPdf from "./img/file2.png";
import Icon2 from "./img/share.png";
import IconSedeP from "./img/sedePrincipal.png";
import IconSedeS from "./img/sedeSecundaria.png";
import Icon3 from "./img/check.png";
import Icon4 from "./img/loading.png";
import IconWeb from "./img/web.png";
import ShareData from "../Modal/shareData";
import Chip from "@mui/material/Chip";

import "./animation.css";

export default function BasicTable({ data, userData, urlVisor, urlsVisor, urlDescarga, isLoading }) {
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("Buscar"); //Valor de la busqueda
  const [value, setValue] = React.useState(0); //Valor del filtro de estudio
  const { AppManager,AppConfiguration } = useContext(typeScriptContext);
  const [tab1, setValueTab1] = useState("");
  const [tab2, setValueTab2] = useState("");
  const [tab3, setValueTab3] = useState("");
  const [settingRows, setSettingRows] = useState(true);
  const [description, setValueDesc] = useState("");
  const [showPdf, setShowPDF] = useState(false); //Modal del pdf
  const [showShareModal, setShareModal] = useState(false); //Modal de compartir url
  const [showModalCita, setShowModalCita] = useState(false); //Modal de compartir url
  const [selectedRow, setSelectedRow] = useState({}); // Data para el modal

  const [selectedUrl, setSelectedUrl] = useState(""); // Data para el modal, ruta inicial la sede principal
  const [isSedesLoaded, setisSedesLoaded] = useState("");
  //Al iniciar el componente
  useEffect(() => {
   // console.log(userData)
    if (data && data.length > 0) {
      data.sort(function (a, b) {
        return new Date(b.FechaEstudio) - new Date(a.FechaEstudio);
      });
      setRows(data);
      setSettingRows(false);
    }
    setisSedesLoaded(AppManager.sedesloader);

    setSelectedUrl(urlVisor);
    AppManager.translator.changeLabelsTitle();
    translateAll(AppManager.translator.current_lang);

    return () => {
      //cleanup
      setRows([]);
    };
  }, [data, urlVisor, AppManager.sedesloader]);

  //Si no hay datos circular progress
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress size={100} color={"warning"}></CircularProgress>
      </div>
    );
  }

  const getUrlporSede = (sede) => {
    if (sede) {
      if (urlsVisor && urlsVisor.length > 0) {
        for (let index = 0; index < urlsVisor.length; index++) {
          const element = urlsVisor[index];

          if (element.Nombre === sede) {
            return element.UrlServicio;
          }
        }
      }
    }
    return urlVisor; //Default
  };
  function translateAll(current_lang) {
    if (current_lang === "es") {
      setValueTab1("Todos los estudios");
      setValueTab2("Estudios listos");
      setValueTab3("Estudios en proceso");
      setValueDesc("Sin Descripción");
      setSearched("Buscar");
    } else {
      setValueTab1("All studies");
      setValueTab2("Ready studies");
      setValueTab3("Studies in process");
      setValueDesc("Not available");
      setSearched("Search");
    }
  }
  //=========Filtros de busqueda===========
  const requestSearch = (searchedVal) => {
    const cpy = _.cloneDeep(data);

    //Por cada paciente
    const filtered = cpy.filter((paciente) => {
      // filtramos sus estudios
      return paciente.Descripcion.toLowerCase().includes(searchedVal.toLowerCase());
    });

    // console.log(filtered);

    setRows(filtered);
  };
  const filterTerminados = () => {
    const cpy = _.cloneDeep(data);
    //Busqueda

    //Por cada paciente
    const filtered = cpy.filter((paciente) => {
      // filtramos sus estudios
      return paciente.EstadoReporte === "Autorizado" || paciente.EstadoReporte === "Validado";
    });

    // console.log(filtered);

    setRows(filtered);
  };
  const filterEnProceso = () => {
    const cpy = _.cloneDeep(data);

    //Por cada paciente
    const filtered = cpy.filter((paciente) => {
      // filtramos sus estudios
      return paciente.EstadoReporte !== "Autorizado" && paciente.EstadoReporte !== "Validado";
    });

    // console.log(filtered);

    setRows(filtered);
  };
  const cancelSearch = () => {
    setSearched(searched);
    requestSearch(searched);
    setRows(data);
  };
  //==========Fin filtros de busqueda=========

  // Status de reporte por fila (Check y Progress)
  function iconStatusReporte(statusReporte) {
    switch (statusReporte) {
      case "Autorizado":
        return (
          <img
            alt="status"
            className="slide-in-left"
            src={Icon3}
            title="Autorizado"
            style={{ width: 25, marginLeft: 15, cursor: "pointer" }}
          ></img>
        );
      case "Validado":
        return (
          <img
            alt="status"
            className="slide-in-left"
            src={Icon3}
            title="Autorizado"
            style={{ width: 25, marginLeft: 15, cursor: "pointer" }}
          ></img>
        );

      default:
        return (
          <img
            className="slide-in-left"
            alt="status2"
            src={Icon4}
            title="En proceso"
            style={{ width: 25, marginLeft: 15, cursor: "pointer" }}
          ></img>
        );
    }
  }

  //Abrir url en pestaña nueva
  const openInNewTab = (url, newWindow) => {
    if (newWindow) {
      window.open(url, "_blank", "location=yes,scrollbars=yes,status=yes,resize=yes").focus();
    } else {
      window.open(url, "_blank").focus();
    }
  };

  const openShareData = () => {
    setShareModal(true);
  };

  const toPrintView = (folio, selectedUrl) => {
    const handle = window.open("#/pdf", "_blank", "popUpWindow", "width=1350,height=950");
    // console.log(selectedUrl);
    handle.window.parameters = JSON.stringify({ folio: folio, URLServicio: selectedUrl });
  };

  function ListaItemsMovil() {
    let counter = 0;

    const lista = rows.map((row, indx) => {
      //Por cada estudio
      const [fecha, hora] = row.FechaEstudio.split("T");
      //if (row.EstadoReporte == "Autorizado" || row.EstadoReporte == "Validado")
      //{
      counter++;
      return (
        <MobileView className="card mb-3" key={row.Folio + indx}>
          <div
            className="mt-3"
            component="th"
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}
          >
            <div scope="row">{iconStatusReporte(row.EstadoReporte)}</div>
            <div component="th" scope="row" style={{ textAlign: "center", fontWeight: "bold", justifyContent: "center", display: "flex" }}>
              {row.Descripcion ? row.Descripcion : description}{" "}
            </div>
          </div>
          <div align="center">{row.PrimerModalidad}</div>
          <div align="center">
            {fecha} <br></br>
            {hora}
          </div>
          {/* SECCION ENLACES*/}
          <div
            className="mb-2"
            align="center"
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="hoverButton2 puff-in-center">
              <img
                alt="weboptima"
                src={IconWeb}
                onClick={() => {
                  const url = getUrlporSede(row.sede);

                  openInNewTab(`${url}WebUltimateGL/App/Vistas/index.html?Tipo=Estudio&ID=${row.Folio}`, true);
                }}
                className="icons"
                style={{ width: 53 }}
              ></img>
            </div>
            {/* PDF */}
            {row.EstadoReporte == "Autorizado" || row.EstadoReporte == "Validado" ? (
              <div
                className="hoverButton"
                onClick={() => {
                  const url = getUrlporSede(row.sede);
                 // console.log(row.sede);
                  setSelectedUrl(url);
                  setSelectedRow(row);
                  setShowPDF(true);
                }}
              >
                <img src={Icon1} alt="document" className="icons"></img>
              </div>
            ) : (
              <></>
            )}
            {/* Download */}
            <div
              className="hoverButton"
              onClick={() => {
                const url = getUrlporSede(row.sede);
                setSelectedUrl(url);
                row.fecha = fecha;
                setSelectedRow(row);
                document.getElementById("divDownloadData").style.display = "block";
              }}
            >
              <img src={Icon} alt="download" className="icons"></img>
            </div>
            <div
              className="hoverButton"
              onClick={() => {
                const url = getUrlporSede(row.sede);
                setSelectedUrl(url);
                setSelectedRow(row);
                openShareData();
              }}
            >
              {/* Share */}
              <img src={Icon2} alt="share" className="icons"></img>
            </div>
            {AppManager.sedesURLS && AppManager.sedesURLS.length > 0 ? (
              <div className="hoverButton" style={{ cursor: "text" }} onClick={() => {}}>
                {/* Sede */}
                {row.sede == "Principal" ? (
                  <img src={IconSedeP} alt="share" className="icons"></img>
                ) : (
                  <img src={IconSedeS} alt="share" className="icons"></img>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </MobileView>
      );
      //}
    });

    if (counter == 0) {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
          <h2 style={{ color: "rgb(231 231 231)" }}>Sin estudios</h2>
        </div>
      );
    }
    return lista;
  }

  function MensajeSinDatos({date}){
    if(date!="0001-01-01 00:00"){
      return (<p style={{fontWeight:"bold",color:"#656565"}}>Sin estudios desde: <strong style={{color:"#999999"}}>{date}</strong></p>)
    }
    return   (<p style={{fontWeight:"bold",color:"#656565"}}>Sin estudios</p>)
  }
  return (
    <>
      <div className="card h-lg-86 fade-in" style={{ height: "86vh", borderRadius: 28 }}>
        <div className="card-header bg-light" style={{ borderRadius: 28 }}>
          <div className="row " style={{ display: "flex", justifyContent: "flex-end" }}>
            <BrowserView style={{ width: "65%" }}>
              <Tabs
                value={value}
                style={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: "1.2rem" }}
                TabIndicatorProps={{ className: "tabs-Indicator-Color" }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  switch (newValue) {
                    case 0:
                      cancelSearch();
                      break;
                    case 1:
                      filterTerminados();
                      break;
                    case 2:
                      filterEnProceso();
                      break;
                    default:
                      break;
                  }
                }}
              >
                <Tab label={tab1} />
                <Tab label={tab2} />
                <Tab label={tab3} />
              </Tabs>
            </BrowserView>

            {isSedesLoaded ? <CircularProgress size={10}></CircularProgress> : <> </>}
            <BrowserView className="search-box" style={{ width: "35%", paddingTop: 8 }} data-list='{"valueNames":["title"]}'>
              <input
                className="form-control search-input fuzzy-search"
                type="search"
                aria-label="Search"
                placeholder={searched}
                onChange={(e) => {
                  cancelSearch();
                  requestSearch(e.target.value);
                }}
              />
              <span className="fas fa-search search-box-icon"></span>
            </BrowserView>

            <MobileView>
              <div className="search-box" style={{ width: "100%" }} data-list='{"valueNames":["title"]}'>
                <input
                  className="form-control search-input fuzzy-search"
                  type="search"
                  aria-label="Search"
                  placeholder={searched}
                  onChange={(e) => {
                    cancelSearch();
                    requestSearch(e.target.value);
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
              </div>
            </MobileView>
          </div>
        </div>
        <div className="card-body h-lg-50 navbar-vertical-content" id="scrollable">
          <BrowserView style={{ height: "100%", width: "100%" }}>
            <Paper style={{ overflow: "scroll", height: "100%", width: "100%", borderRadius: 20 }} className="scrollbarNoneCustom">
              <TableContainer sx={{ width: "100%" }} className="scrollbarCustomHorizontalFlat">
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ color: "rgb(19, 37, 60)", fontWeight: "bold" }}>
                        <span data-language_txt="txt_Status">Status</span>
                      </TableCell>
                      <TableCell style={{ color: "rgb(19, 37, 60)", fontWeight: "bold" }}>
                        <span data-language_txt="txt_Studies">Studies</span>
                      </TableCell>
                      <TableCell align="center" style={{ color: "rgb(19, 37, 60)", fontWeight: "bold" }}>
                        <span data-language_txt="txt_Modality">Modality</span>
                      </TableCell>
                      <TableCell align="center" style={{ color: "rgb(19, 37, 60)", fontWeight: "bold" }}>
                        <span data-language_txt="txt_Datetime">Datetime</span>
                      </TableCell>

                      <TableCell align="center" style={{ color: "rgb(19, 37, 60)", fontWeight: "bold" }}>
                        <span data-language_txt="txt_Links">Links</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {data.length > 0 ? (
                    <TableBody>
                      {rows.map((row, indx) => {
                        //Por cada estudio
                        const [fecha, hora] = row.FechaEstudio.split("T");

                        return (
                          <TableRow key={row.Folio + indx}>
                            <TableCell component="th" scope="row" style={{ padding: 7 }}>
                              {iconStatusReporte(row.EstadoReporte)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span>{row.Descripcion ? row.Descripcion : description}</span>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 87 }}
                            >
                              <Chip
                                label={row.PrimerModalidad}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#eaeaea9e",
                                  width: 50,
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <span style={{ width: "85px" }}>{fecha}</span>
                                <span style={{ width: "85px" }}> {hora} </span>
                              </div>
                            </TableCell>

                            <TableCell scope="row" align="center">
                              {row.EstudioVisible == true || row.EstudioVisible === undefined ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  {/* Visor */}
                                  <Tooltip title="Visor">
                                    <div className="hoverButton2 puff-in-center">
                                      <img
                                        alt="weboptima"
                                        src={IconWeb}
                                        onClick={() => {
                                          const url = getUrlporSede(row.sede);

                                          openInNewTab(`${url}WebUltimateGL/App/Vistas/index.html?Tipo=Estudio&ID=${row.Folio}`, true);
                                        }}
                                        className="icons"
                                        style={{ width: 53 }}
                                      ></img>
                                    </div>
                                  </Tooltip>
                                  {/* PDF */}
                                  {row.EstadoReporte === "Autorizado" || row.EstadoReporte === "Validado" ? (
                                    <Tooltip title="PDF">
                                      <div
                                        className="hoverButton"
                                        onClick={() => {
                                          const url = getUrlporSede(row.sede);
                                          setSelectedUrl(url);

                                          if (isMobile) {
                                            setSelectedRow(row);
                                            setShowPDF(true);
                                          } else {
                                            toPrintView(row.Folio, url);
                                          }
                                        }}
                                      >
                                        <img src={Icon1} alt="document" className="icons"></img>
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="No Disponible">
                                      <div className="hoverButton">
                                        <img src={IconWithoutPdf} alt="document" className="icons"></img>
                                      </div>
                                    </Tooltip>
                                  )}
                                  {/* Download */}
                                  <Tooltip title="Descargar">
                                    <div
                                      className="hoverButton"
                                      onClick={() => {
                                        const url = getUrlporSede(row.sede);
                                        setSelectedUrl(url);
                                      
                                        row.fecha = fecha;
                                        setSelectedRow(row);
                                        document.getElementById("divDownloadData").style.display = "block";
                                      }}
                                    >
                                      <img src={Icon} alt="download" className="icons"></img>
                                    </div>
                                  </Tooltip>
                                  <Tooltip title="Compartir">
                                    <div
                                      className="hoverButton"
                                      onClick={() => {
                                        const url = getUrlporSede(row.sede);
                                        setSelectedUrl(url);
                                        setSelectedRow(row);
                                        openShareData();
                                      }}
                                    >
                                      {/* Share */}
                                      <img src={Icon2} alt="share" className="icons"></img>
                                    </div>
                                  </Tooltip>
                                  {AppManager.sedesURLS && AppManager.sedesURLS.length > 0 ? (
                                    <Tooltip title={row.sede}>
                                      <div className="hoverButton" style={{ cursor: "text" }} onClick={() => {}}>
                                        {/* Sede */}
                                        {row.sede == "Principal" ? (
                                          <img src={IconSedeP} alt="share" className="icons"></img>
                                        ) : (
                                          <img src={IconSedeS} alt="share" className="icons"></img>
                                        )}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <p style={{ margin: 0 }}>{`CONTACTA A ${
                                  row.Aseguradora ? row.Aseguradora : "TU CLINICA"
                                } PARA LA ENTREGA DE RESULTADOS. `}</p>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <div
                    className="text-focus-in"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        height: "80%",
                        width: "100%",
                      }}
                    >
                      <MensajeSinDatos date={userData.FechaInicial}></MensajeSinDatos>
                    
                    </div>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </BrowserView>

          <MobileView>{ListaItemsMovil()}</MobileView>
          {/* BOTON CITA */}
          { AppConfiguration.agendarCitas && isMobile ?
            <div className="hoverCitaBtn" style={{bottom:"10px",display:"inline-flex",width:"50px",height:"50px",borderRadius:"50%"}} onClick={() => { setShowModalCita(true); }} >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>
           </div> :  AppConfiguration.agendarCitas? <div className="hoverCitaBtn" style={{display:"inline-flex",height:"40px",borderRadius:"10px",width:"145px",bottom:"40px"}} onClick={() => { setShowModalCita(true); }} >
              <span style={{marginRight:"5px"}}> {AppManager.translator.current_lang==="es" ? "Agendar Cita" : "Schedule"}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>
          </div> : <></>}
        </div>
      </div>
      {showPdf ? (
        <ModalPdf
          title={selectedRow.Descripcion}
          subtitle={selectedRow.PrimerModalidad}
          folio={selectedRow.Folio}
          urlServicio={selectedUrl+"/HisWebServicios/Portal/ServicioPortal.svc/"}
          closeModal={() => {
            setShowPDF(false);
            setSelectedRow({});
          }}
        ></ModalPdf>
      ) : (
        <></>
      )}
      <DownloadData
        folio={selectedRow.Folio}
        modalidad={selectedRow.PrimerModalidad}
        fecha={selectedRow.fecha}
        userFolio={userData || userData.Folio ? userData.Folio : ""}
        urlDescarga={`${selectedUrl}WebUltimateGL/Servicio/Stream/Imagenes.svc/GetStudyZip`}
      ></DownloadData>

      {showModalCita ? (
        <ModalCita
          Paciente={userData}
          closeModalCita={() => {
            setShowModalCita(false);
          }}
        ></ModalCita>
      ) : (
        <></>
      )}

      {showShareModal ? (
        <ShareData
          urlBase={selectedUrl}
          userId={userData.Folio}
          idEstudio={selectedRow.Folio}
          closeModal={() => {
            setShareModal(false);
            setSelectedRow({});
          }}
        ></ShareData>
      ) : (
        <></>
      )}
    </>
  );
}
