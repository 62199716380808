import React, { useContext, useEffect } from "react";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import { Avatar, Button, CircularProgress } from "@mui/material";
import profileImg from "./icons/unknow.png";
import profileImgMale from "./icons/male.png";
import profileImgFemale from "./icons/female.png";
import { isMobile } from "react-device-detect";

function Profile({ userData }) {

  const { AppManager } = useContext(typeScriptContext);
  const loading = AppManager.translator.current_lang === "es" ? "Cargando" : "loading";
  if(!userData||Object.keys(userData).length==0){
    return<div
          style={{
            width: "100%",
      
            display: "flex",
            justifyContent: "center",
            alignItems: "center",      
            height: AppManager.publicidad ? (isMobile ? "0vh" : "20vh") : (isMobile ? "0vh" : "50vh"),
          
          }}
        >
          <CircularProgress size={70} color="warning"></CircularProgress>
        </div>
  }
 
  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
    return () => {};
  }, [AppManager.translator]);
  
  let {
    NombreCompleto = "",
    TipoSangre = "",
    FechaNacimiento = "",
    Email = "",
    Tel = "",
    VIP = "",
    Sexo = "",
    Documento = "",
    Empresa = "",
  } = userData;

  //Obtener primer nombre

  const name = NombreCompleto.split(" ");
  const primerNombre = name[0];
  name.shift();
  NombreCompleto = name.join(" ");

  //Fin
  // Separar fecha
  FechaNacimiento = FechaNacimiento.split("T")[0];
  function returnAvatar() {
    if (Sexo == "F" || Sexo == "FEMENINO" || Sexo == "FEMALE")
      return <Avatar alt="Remy Sharp" src={profileImgFemale} sx={{ width: 100, height: 100 }} className="slide-in-top" />;
    else if (Sexo == "M" || Sexo == "MASCULINO" || Sexo == "MALE")
      return <Avatar alt="Remy Sharp" src={profileImgMale} className="slide-in-top" sx={{ width: 100, height: 100 }} />;
    else return <Avatar alt="Remy Sharp" className="slide-in-top" src={profileImg} sx={{ width: 100, height: 100 }} />;
  }

  return (
    <div className="card h-100" style={{ borderRadius: "25px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: 10,
          paddingRight: 25,
          marginTop: 20,
        }}
      >
        <div style={{ border: "2px solid #EBA623", borderRadius: "50%", marginLeft: 35 }} id="fotoPerfil" className="slide-in-blurred-top">
          {returnAvatar()}
        </div>
        <h5
          style={{
            overflowWrap: "break-word",
            paddingLeft: 20,
            color: "rgb(19, 37, 60) !important",
          }}
        >
          <strong style={{ fontSize: 22 }}> {primerNombre}</strong>
          <br></br>
          {NombreCompleto}
        </h5>
      </div>
      {Object.keys(userData).length > 0 ? (
        <div
          id="perfilCreciente"
          style={{
            paddingTop: "1rem",
            paddingLeft: "4rem",
            overflow: "hidden",
            height: AppManager.publicidad ? (isMobile ? "0vh" : "20vh") : (isMobile ? "0vh" : "50vh"),
          }}
        >
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Birth">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {FechaNacimiento}{" "}
          </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Gender">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {Sexo}
          </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Document">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {Documento}{" "}
          </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Email">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {Email}{" "}
          </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Phone">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {" "}
            {Tel}{" "}
          </p>
          <strong style={{ marginRight: 5 }} data-language_txt="txt_Company">
            Loading...
          </strong>
          <p className="colorP" style={{ fontSize: 18 }}>
            {Empresa}
          </p>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={70} color="warning"></CircularProgress>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", padding: "0.5rem" }}>
        {AppManager.publicidad ? (
          <>
            <Button
              id="btnShowProfile"
              variant="contained"
              style={{
                borderRadius: "25px",
                width: "80%",
              }}
              size="large"
              onClick={() => {
                AppManager.showAllProfile();
              }}
            >
              <span data-language_txt="txt_ShowProfile">VIEW FULL PROFILE</span>
            </Button>
            <Button
              id="btnHideProfile"
              variant="contained"
              style={{
                borderRadius: "25px",
                width: "80%",
                display: "none",
              }}
              size="large"
              onClick={() => {
                AppManager.hideProfile(isMobile);
              }}
            >
              <span data-language_txt="txt_HideProfile">HIDE FULL PROFILE</span>
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Profile;
