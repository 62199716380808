import React, { useState, useEffect, useContext } from "react";

import Box from "@mui/material/Box";
import _, { set } from "lodash";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";

import ModalPdf from "../Modal/reporte";
import DownloadData from "../Modal/download.js";
import ShareData from "../Modal/shareData";

import "../../../../../node_modules/flatpickr/dist/themes/light.css";

import Flatpickr from "react-flatpickr";

import CircularProgress from "@mui/material/CircularProgress";
import { isMobile } from "react-device-detect";

import { Paper, Tab, Tabs } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";

//Icons
import Icon from "./img/download.png";
import Icon1 from "./img/file.png";
import IconWithoutPdf from "./img/file2.png";
import adobeIcon from "./img/adobeIcon.png";
import adobeIcon2 from "./img/adobeIcon2.png";
import Icon2 from "./img/share.png";
import Icon3 from "./img/check.png";
import Icon4 from "./img/loading.png";
import IconWeb from "./img/web.png";

import EstadoDeCuentaModal from "../Modal/estadodeCuenta.jsx";
import Lupe from "./img/lupe.png";
import Info from "./img/info.png";
import Calendar from "./img/calendar.png";
import { Alert } from "react-bootstrap";

export default function BasicTable({ setDate, data, userData, urlVisor, urlDescarga,actualDate }) {
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState(""); //Valor de la busqueda
  const [value, setValue] = React.useState(0); //Valor del filtro de estudio
  const { AppManager } = useContext(typeScriptContext);
  const [tab1, setValueTab1] = useState("");
  const [tab2, setValueTab2] = useState("");
  const [tab3, setValueTab3] = useState("");
  const [description, setValueDesc] = useState("");
  const [isLoading, setIsLoading] = useState(true); //Modal del pdf
  const [showPdf, setShowPDF] = useState(false); //Modal del pdf
  const [showShareModal, setShareModal] = useState(false); //Modal de compartir url
  const [showCuentaModal, setshowCuentaModal] = useState(false); //Modal de estado de cuenta 
  
  const [selectedRow, setSelectedRow] = useState({}); // Data para el modal

  const [period, setPeriodo] = useState(9);
  const [isRangoFechas, setIsRangoFechas] = useState(false); //Si esta activa la opcion por rangos de fechas


  // Fija el periodo en el calendario
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  //Dezplegables
  const [openClient, setOpenClient] = React.useState({}); //Estatus si esta abierto cliente
  const [openPatient, setOpenPatient] = useState({}); //Estatus si estan abiertos o cerrados la lista de estudios por paciente

  //Fechas del dia de hoy y el mes pasado
  const dayNow = new Date();
  const parsed = dayNow.toISOString().split("T")[0];

  const month = new Date();
  month.setMonth(month.getMonth() - 12);

  //Al iniciar el componente
  useEffect(() => {
    AppManager.translator.changeLabelsTitle();
    if (AppManager.translator.current_lang === "es") {
      setValueTab1("Todos los estudios");
      setValueTab2("Estudios listos");
      setValueTab3("Estudios en proceso");
      setValueDesc("Sin Descripción");
    } else {
      setValueTab1("All studies");
      setValueTab2("Ready studies");
      setValueTab3("Studies in process");
      setValueDesc("Not available");
    }
    setRows([...data]);
    setFromDate(actualDate.inicio)
    setToDate(actualDate.fin)
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [AppManager, data]);

function isLoadingComponent (){
    // // Si esta en loading circular progress
    AppManager.translator.changeLabelsTitle()
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={100} color={"warning"}></CircularProgress>
        </div>
      );

}

  //=========Filtros de busqueda===========
  const requestSearch = (searchedVal) => {
    
    setSearched(searchedVal)
    if (searchedVal !== "" || searchedVal.length !== 0) {
      const cpy = _.cloneDeep(rows);
      // console.log(cpy)
      //Busqueda
      for (let plan = 0; plan < cpy.length; plan++) {

          //Por cada paciente
          const filtered = cpy[plan].Pacientes.filter((paciente) => {
            // filtramos sus estudios
            return paciente.Nombre.toLowerCase().includes(searchedVal.toLowerCase());
          });

          // console.log(filtered);
       
          cpy[plan].Pacientes=filtered;

       
      }

      setRows(cpy);
    }
    if (searchedVal == "" || searchedVal.length == 0) {
      setRows(data);
    }
   
  };
  const filterTerminados = () => {
    cancelSearch()
    const cpy = _.cloneDeep(data);
    //Busqueda
    for (let plan = 0; plan < cpy.length; plan++) {
      for (let paciente = 0; paciente < cpy[plan].Pacientes.length; paciente++) {
        const filtered = cpy[plan].Pacientes[paciente].Estudios.filter((row) => {
          return row.EstadoReporte === "Autorizado" || row.EstadoReporte === "Validado";
        });
       
          //Si el filtro tuvo resultados
          cpy[plan].Pacientes[paciente].Estudios = filtered;
        
      }
      // console.log(cpy)
   
    }


    setRows(cpy);
  };
  const filterEnProceso = () => {
    cancelSearch()
    const cpy = _.cloneDeep(data);
    //Busqueda
    for (let plan = 0; plan < cpy.length; plan++) {
      for (let paciente = 0; paciente < cpy[plan].Pacientes.length; paciente++) {
        const filtered = cpy[plan].Pacientes[paciente].Estudios.filter((row) => {
          return row.EstadoReporte !== "Autorizado" || row.EstadoReporte !== "Validado";
        });

        if (filtered.length > 0) {
          //Si el filtro tuvo resultados
          cpy[plan].Pacientes[paciente].Estudios = filtered;
        } else {
          //Si no lo eliminamos
          cpy[plan].Pacientes.splice(paciente, 1);
        }
      }

      if (cpy[plan].Pacientes.length === 0) {
        //Si el filtro tuvo resultados
        cpy.splice(plan, 1);
      }
    }

    setRows(cpy);
  };
  const cancelSearch = () => {
    
    setSearched("");
    setRows(data);
  };
  //==========Fin filtros de busqueda=========

  // Status de reporte por fila (Check y Progress)
  function iconStatusReporte(statusReporte) {
    switch (statusReporte) {
      case "Autorizado":
        return (
          <img
            alt="status"
            className="slide-in-left"
            src={Icon3}
            title="Autorizado"
            style={{ width: 25, marginRight: 15, cursor: "pointer" }}
          ></img>
        );
        case "Validado":
          return (
            <img
              alt="status"
              className="slide-in-left"
              src={Icon3}
              title="Autorizado"
              style={{ width: 25, marginRight: 15, cursor: "pointer" }}
            ></img>
          );
  
      default:
        return (
          <img
            className="slide-in-left"
            alt="status2"
            src={Icon4}
            title="En proceso"
            style={{ width: 25, marginRight: 15, cursor: "pointer" }}
          ></img>
        );
    }
  }

  //Abrir url en pestaña nueva
  const openInNewTab = (url, newWindow) => {
    if (newWindow) {
      window.open(url, "_blank", "location=yes,scrollbars=yes,status=yes").focus();
    } else {
      window.open(url, "_blank").focus();
    }
  };

  const openShareData = () => {
    setShareModal(true);
  };

  const toPrintView = (folio) => {
    const handle = window.open("#/pdf", "_blank", "popUpWindow", "width=1350,height=950");
    handle.window.parameters = JSON.stringify({ folio: folio });
  };

  const onChangePeriod = (value) => {
    let strFromDate = "";

    switch (value.target.value) {
      case "0":
        setFromDate("");
        setIsRangoFechas(false);
        const month = new Date("2022-01-01");
        const lastYear=month.toISOString().split("T")[0]
        setDate({
          inicio: lastYear,
          fin: parsed,
        });
        //Filtra todos los estudios
        break;
      //Hoy
      case "1":
        let tomorrow = new Date();
        tomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        const tomorrowParsed = tomorrow.toISOString().split("T")[0];

        setFromDate(dayNow);
        setToDate(tomorrow);

        setDate({
          inicio: parsed,
          fin: tomorrowParsed,
        });
        setIsRangoFechas(false);
        break;
      //Dos días
      case "2":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 2));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Una semana
      case "3":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 7));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Dos semanas
      case "4":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 14));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Tres semanas
      case "5":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 21));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Un mes
      case "6":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 30));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Dos meses
      case "7":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 60));
        setFromDate(strFromDate);

        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      //Tres meses
      case "8":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 90));
        setFromDate(strFromDate);

        setDate({
          inicio: dayNow,
          fin: strFromDate,
        });
        setIsRangoFechas(false);
        break;
      //Un año
      case "9":
        strFromDate = new Date(dayNow.setDate(dayNow.getDate() - 365));
        setFromDate(strFromDate);
        setDate({
          inicio: strFromDate.toISOString().split("T")[0],
          fin: parsed,
        });
        setIsRangoFechas(false);
        break;
      case "10":
        
        setIsRangoFechas(true);
        setToDate(dayNow);
        setFromDate("");
    
        break;
      //Rango fechas
      default: 
        break;
    }
    setPeriodo(value.target.value);
   

  };


  //Tabla
  function ClientRow(rowData) {
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell scope="row" style={{ display: "inline-flex", alignItems: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                //Setteamos el estado de opertura de la row al objeto de estados
                setOpenClient((prevState) => {
                  const key = rowData.Plan;
                  return {
                    //copiamos anterior estado y le setteamos a la key el estado
                    ...prevState,
                    [key]: prevState[key] ? !prevState[key] : true, //Si no existia lo ponemos en true
                  };
                });
              }}
            >
              {openClient[rowData.Plan] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <div style={{display:"flex",alignItems:"center"}}>
           
            <h5 style={{ color: "#2a3d45", marginLeft: 4,marginRight:6 }}>{rowData.dataPac.Plan}</h5>
            <img src={Info} alt="" width="22px" height="22px" style={{cursor:"pointer"}} onClick={()=>{
              setshowCuentaModal(true)
            }} />
           
            </div>
            
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={openClient[rowData.Plan]} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableBody>
                    {rowData.dataPac.Pacientes.map((row) => (
                      <RowPatient key={row.Folio} rowPatient={row} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        </>
    );
  }

  function RowPatient(props) {
    return (
      <React.Fragment>
        <TableRow key={props.rowPatient.Folio}>
          <TableCell
            scope="row"
            style={{
              display: "inline-flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpenPatient((prevState) => {
                  const key = props.rowPatient.Folio;
                  return {
                    //copiamos anterior estado y le setteamos a la key el estado
                    ...prevState,
                    [key]: prevState[key] ? !prevState[key] : true, //Si no existia lo ponemos en true
                  };
                });
              }}
            >
              {openPatient[props.rowPatient.Folio] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <div style={{ display: "flex" }}>
              <h5 style={{ color: "#B66262", fontWeight: "600", fontSize: 16 }}>({props.rowPatient.Folio})</h5>
              <h5 style={{ color: "#7f7f7f", marginLeft: 14, fontSize: 16 }}>{props.rowPatient.Nombre} </h5>
              <h8 style={{ color: "#B66262", fontWeight: "600", fontSize: 14,marginLeft:14 }}>Total: {props.rowPatient.Estudios.length}</h8>

            
            </div>
          </TableCell>
          <Collapse in={openPatient[props.rowPatient.Folio]} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="estudios">
                <TableHead>
                  <TableCell>Estado</TableCell>
                  <TableCell>Estudios</TableCell>
                  <TableCell>Modalidad</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Enlaces</TableCell>
                </TableHead>
                <TableBody>
                  {props.rowPatient.Estudios.map((row) => (
                    <RowStudy key={row.Folio} rowStudy={row} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableRow>
      </React.Fragment>
    );
  }

  function RowStudy(props) {
    const [fecha, hora] = props.rowStudy.FechaEstudio.split("T");
    return (
      <React.Fragment>
        <TableRow key={props.rowStudy.Folio}>
          <TableCell component="th" scope="row">
            {iconStatusReporte(props.rowStudy.EstadoReporte)}
          </TableCell>
          <TableCell component="th" scope="row">
            {props.rowStudy.Descripcion ? props.rowStudy.Descripcion : description}
          </TableCell>
          <TableCell align="center">{props.rowStudy.PrimerModalidad}</TableCell>
          <TableCell align="center">
            {fecha} <br></br>
            {hora}
          </TableCell>

          <TableCell scope="row" align="center" className="noselect">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              
              <div className="hoverButton2 puff-in-center" title="Visor">
                <img
                  alt="weboptima"
                  src={IconWeb}
                  onClick={() => {
                    openInNewTab(`${urlVisor}?Tipo=Estudio&ID=${props.rowStudy.Folio}`, true);
                  }}
                  className="icons"
                  style={{ width: 53 }}
                ></img>
              </div>
               {/* Facturas */}
               <Tooltip title={props.rowStudy.URLFactura?"Factura":"Factura No Disponible"}>
                <div className="hoverButton">
                  <img src={props.rowStudy.URLFactura?adobeIcon:adobeIcon2} alt="document" className="icons noselect" onClick={() => {
                    if(props.rowStudy.URLFactura){
                      openInNewTab(`${props.rowStudy.URLFactura}`, true);
                    }
                  }}></img>
                </div>
              </Tooltip>
              {/* PDF */}
              {props.rowStudy.EstadoReporte === "Autorizado" ? (
                <div
                  className="hoverButton"
                  title="PDF"
                  onClick={() => {
                    if (isMobile) {
                      setSelectedRow(props.rowStudy);
                      setShowPDF(true);
                    } else {
                      toPrintView(props.rowStudy.Folio);
                    }
                  }}
                >
                  <img src={Icon1} alt="document" className="icons"></img>
                </div>
              ) : (
                <div className="hoverButton" title="No Disponible">
                  <img src={IconWithoutPdf} alt="document" className="icons"></img>
                </div>
              )}
              {/* Download */}
              <div
                className="hoverButton"
                title="Descargar"
                onClick={() => {
                  props.rowStudy.fecha = fecha;
                  setSelectedRow(props.rowStudy);
                  document.getElementById("divDownloadData").style.display = "block";
                }}
              >
                <img src={Icon} alt="download" className="icons"></img>
              </div>
              <div
                className="hoverButton"
                title="Compartir  "
                onClick={() => {
                  setSelectedRow(props.rowStudy);
                  openShareData();
                }}
              >
                {/* Share */}
                <img src={Icon2} alt="share" className="icons"></img>
              </div>
             
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  // End Tabla
  return (
    <>
      <div className="card h-lg-86 fade-in" style={{ height: "86vh", borderRadius: 38 }}>
        <div className="card-header bg-light" style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}>
          {/* FILTROS */}
          <BrowserView>
            <div className="card-header bg-light" style={{ paddingLeft:32,borderBottom: "1px solid rgb(231 231 231 / 87%)", padding: 10 }}>
              <div className="row gx-2" style={{paddingBottom:15,display:"flex",alignItems:"center"}}>
                <div className="col-3 p-1">
                  <label className="form-label" data-language_lbl="lbl_dateFilter" style={{ color: "#d12823" }}></label>
                  <select
                    id="selectPeriod"
                    className="form-select p-1"
                    
                    onChange={onChangePeriod}
                    style={{ borderRadius: 10,cursor:"pointer" ,backgroundImage: `url(${Calendar})`,backgroundRepeat: "no-repeat",backgroundSize: "20px",backgroundPosition: "left 10px center",paddingLeft: "40px"}}
                    value={period}
                    onMouseEnter={() => {
                      AppManager.translator.changeLabelsTitle();
                    }}
                  >
                    <option value="0" data-language_txt="txt_all"  ></option>
                    <option value="1" data-language_txt="txt_oneDay"></option>
                    <option value="2" data-language_txt="txt_twoDays"></option>
                    <option value="3" data-language_txt="txt_oneWeek"></option>
                    <option value="4" data-language_txt="txt_twoWeeks"></option>
                    <option value="5" data-language_txt="txt_threeWeeks"></option>
                    <option value="6" data-language_txt="txt_oneMonth"></option>
                    <option value="7" data-language_txt="txt_twoMonths"></option>
                    <option value="8" data-language_txt="txt_threeMonths"></option>
                    <option value="9" data-language_txt="txt_oneYear" ></option>
                    <option value="10" data-language_txt="txt_dateRange"></option>
                  </select>
                </div>
                {isRangoFechas ? (
                  <>
                    <div className="col-3 p-1">
                      <label className="form-label" data-language_lbl="lbl_date">De:</label>
                      <Flatpickr
                        className="form-control datetimepicker flatpickr-input"
                        value={fromDate}
                        options={{ dateFormat: "d-m-y", disableMobile: false }}
                        onChange={([fromDate]) => {
                          this.setFromDate({ fromDate });
                        }}
                      />
                    </div>
                    <div className="col-3 p-1">
                      <label className="form-label" data-language_lbl="lbl_toDate">A:</label>
                      <Flatpickr
                        className="form-control datetimepicker flatpickr-input"
                        value={toDate}
                        options={{ dateFormat: "d-m-y", disableMobile: false }}
                        onChange={([toDate]) => {
                          this.setToDate({ toDate });
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {  AppManager.translator.changeLabelsTitle()}
          </BrowserView>
          <MobileView className="card-header bg-light">
            <div className="row gx-2">
              <div className="p-1">
                <label className="form-label" data-language_lbl="lbl_dateFilter" style={{ color: "#e77b7bf0" }}></label>
                <select className="form-select p-1" onChange={onChangePeriod} value={period}>
                  <option value="0">*</option>
                  <option value="1">
                    <span data-language_txt="txt_oneDay"></span>
                  </option>
                  <option value="2">
                    <span data-language_txt="txt_twoDays"></span>
                  </option>
                  <option value="3">
                    <span data-language_txt="txt_oneWeek"></span>
                  </option>
                  <option value="4">
                    <span data-language_txt="txt_twoWeeks"></span>
                  </option>
                  <option value="5">
                    <span data-language_txt="txt_threeWeeks"></span>
                  </option>
                  <option value="6">
                    <span data-language_txt="txt_oneMonth"></span>
                  </option>
                  <option value="7">
                    <span data-language_txt="txt_twoMonths"></span>
                  </option>
                  <option value="8">
                    <span data-language_txt="txt_threeMonths"></span>
                  </option>
                  <option value="9">
                    <span data-language_txt="txt_oneYear"></span>
                  </option>
                  <option value="10">
                    <span data-language_txt="txt_dateRange"></span>
                  </option>
                </select>
              </div>
              <div className="p-1">
                <label className="form-label" data-language_lbl="lbl_date"></label>
                <Flatpickr
                  className="form-control datetimepicker flatpickr-input"
                  value={fromDate}
                  options={{ dateFormat: "d-m-y", disableMobile: false }}
                  onChange={([fromDate]) => {
                    this.setFromDate({ fromDate });
                  }}
                />
              </div>
              <div className="p-1">
                <label className="form-label" data-language_lbl="lbl_toDate"></label>
                <Flatpickr
                  className="form-control datetimepicker flatpickr-input"
                  value={toDate}
                  options={{ dateFormat: "d-m-y", disableMobile: false }}
                  onChange={([toDate]) => {
                    this.setToDate({ toDate });
                  }}
                />
              </div>
            </div>
          </MobileView>

          {/* BÚSQUEDA*/}
          <div className="row " style={{ display: "flex", paddingTop: 20, justifyContent: "center" }}>
            <BrowserView style={{ width: "60%" }}>
              <Tabs
                value={value}
                TabIndicatorProps={{ className: "tabs-Indicator-Color" }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  switch (newValue) {
                    case 0:
                      cancelSearch();
                      break;
                    case 1:
                      filterTerminados();
                      break;
                    case 2:
                      filterEnProceso();
                      break;
                    default:
                      break;
                  }
                }}
              >
                <Tab label={tab1} />
                <Tab label={tab2} />
                <Tab label={tab3} />
              </Tabs>
            </BrowserView>

            <BrowserView className="search-box" style={{ width: "35%" }} data-list='{"valueNames":["title"]}'>
              <input
                className="form-control search-input fuzzy-search"
                type="search"
                aria-label="Search"
                value={searched}
                placeholder={ AppManager.translator.current_lang==="es" ? "Buscar Paciente" : "Search Patient"}
                style={{backgroundImage: `url(${Lupe})`,backgroundRepeat: "no-repeat",backgroundSize: "20px",backgroundPosition: "left 10px center",paddingLeft: "40px"}}
                onChange={(e) => {
                  cancelSearch();
                  requestSearch(e.target.value);
                }}
              />
              <span className="fas fa-search search-box-icon"></span>
            </BrowserView>

            <MobileView>
              <div className="search-box" style={{ width: "100%" }} data-list='{"valueNames":["title"]}'>
                <input
                  className="form-control search-input fuzzy-search"
                  placeholder={ AppManager.translator.current_lang==="es" ? "Buscar" : "Search"}
                  type="search"
                  aria-label="Search"
                  value={searched}
                  onChange={(e) => {
                    cancelSearch();
                    requestSearch(e.target.value);
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
              </div>
            </MobileView>
          </div>
        </div>

        {/* TABLA*/}
        <div className="card-body h-lg-50 navbar-vertical-content" id="scrollable">
          <BrowserView style={{ height: "100%", width: "100%" }}>
            <Paper style={{ overflow: "scroll", height: "100%", width: "100%" }} className="scrollbarNoneCustom">
              <TableContainer sx={{ width: "100%", paddingTop: 3 }} className="scrollbarCustomHorizontalFlat">
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  {
                    
                   isLoading? isLoadingComponent() : (   <TableBody>
                    
                    {
                    rows.map((row) => (
                      // Rows por cada uno de los clientes
                      <ClientRow key={row.Plan} dataPac={row} />
                    ))}
                  </TableBody>)
                  }
               
              
              
                </Table>
              </TableContainer>
            </Paper>
          </BrowserView>

          <MobileView>
            {rows.map((row, indx) => {
              //Por cada estudio

              // const [fecha, hora] = row.FechaEstudio.split("T");
              return (
                <MobileView className="card mb-3" key={row.Folio + indx}>
                  <div
                    className="mt-3"
                    component="th"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <div scope="row">{iconStatusReporte(row.EstadoReporte)}</div>
                    <div component="th" scope="row" style={{ fontWeight: "bold" }}>
                      {row.Descripcion ? row.Descripcion : description}{" "}
                    </div>
                  </div>
                  <div align="center">{row.PrimerModalidad}</div>
                  <div align="center">
                    {/* {fecha} <br></br>
                          {hora} */}
                  </div>
                  {/* SECCION ENLACES*/}
                  <div
                    className="mb-2"
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="hoverButton2 puff-in-center">
                      <img
                        alt="weboptima"
                        src={IconWeb}
                        onClick={() => {
                          openInNewTab(`${urlVisor}?Tipo=Estudio&ID=${row.Folio}`, true);
                        }}
                        className="icons"
                        style={{ width: 53 }}
                      ></img>
                    </div>
                    {/* PDF */}
                    <div
                      className="hoverButton"
                      onClick={() => {
                        setSelectedRow(row);
                        setShowPDF(true);
                      }}
                    >
                      <img src={Icon1} alt="document" className="icons"></img>
                    </div>
                    {/* Download */}
                    <div
                      className="hoverButton"
                      onClick={() => {
                        // row.fecha=fecha
                        setSelectedRow(row);
                        document.getElementById("divDownloadData").style.display = "block";
                      }}
                    >
                      <img src={Icon} alt="download" className="icons"></img>
                    </div>
                    <div
                      className="hoverButton"
                      onClick={() => {
                        setSelectedRow(row);
                        openShareData();
                      }}
                    >
                      {/* Share */}
                      <img src={Icon2} alt="share" className="icons"></img>
                    </div>
                    <Tooltip title="Factura No Disponible">
                      <div className="hoverButton">
                        <img src={IconWithoutPdf} alt="document" className="icons"></img>
                      </div>
                    </Tooltip>
                  </div>
                </MobileView>
              );
            })}
          </MobileView>
        </div>
      </div>
      {showPdf ? (
        <ModalPdf
          title={selectedRow.Descripcion}
          subtitle={selectedRow.PrimerModalidad}
          folio={selectedRow.Folio}
          closeModal={() => {
            setShowPDF(false);
            setSelectedRow({});
          }}
        ></ModalPdf>
      ) : (
        <></>
      )}
      {
        userData&&Object.keys(userData).length!==0?<DownloadData
        folio={selectedRow.Folio}
        modalidad={selectedRow.PrimerModalidad}
        fecha={selectedRow.fecha}
        userFolio={userData.Folio}
        urlDescarga={urlDescarga}
      ></DownloadData>:<></>
      }

      {showShareModal&&userData&&Object.keys(userData).length!==0 ? (
        <ShareData
          urlBase={urlVisor}
          userId={userData.Folio}
          idEstudio={selectedRow.Folio}
          closeModal={() => {
            setShareModal(false);
            setSelectedRow({});
          }}
        ></ShareData>
      ) : (
        <></>
      )}

 {showCuentaModal ? (
        <EstadoDeCuentaModal 
        fechaInicio={fromDate}
        fechaFin={toDate}
        urlDescarga={urlDescarga}
        closeModal={() => {
          setshowCuentaModal(false); 
        }}></EstadoDeCuentaModal>
      ) : (
        <></>
      )}
  
   
    </>
  );
}
