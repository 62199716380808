import TamaraLogo from "./img/Tamara/tamara.png";
import TamaraPublicidad from "./img/Tamara/background.png";

import PoliPublicidad from "./img/Policlinica/background.jpeg";
import PoliclinicaLogo from "./img/Policlinica/logo.png";

import cedisaLogo from "./img/Cedisa/logoOrig.jpeg";
import cedisaPublicidad from "./img/Cedisa/publicidad.jpg";

import abreuLogo from "./img/Abreu/logo.png";
import abreuPublicidad from "./img/Abreu/publicidad.jpg";

import moderno from "./img/CMModerno/logo.png";
import modernoPublicidad from "./img/CMModerno/publicidad.jpeg";

import adLogo from "./img/AD/logo.png";
import adPublicidad from "./img/AD/publicidad.png";

import frayLogo from "./img/Fray Juan/logo.png";
import frayPublicidad from "./img/Fray Juan/publicidad.png";

import HigeaLogo from "./img/Higea/logo.png";
import HigeaPublicidad from "./img/Higea/publicidad.png";

import defaultImg from "./img/defaultImg.png";
import defaultPublicidad from "./img/publicidad.jpg";

import cadiLogo from "./img/Cadi/logo.png";
import cadiPublicidad from "./img/Cadi/publicidad.jpg";

import DrDLogo from "./img/DrD/logo.png";
import DrDPublicidad from "./img/DrD/publicidad.png";

import CMBOLogo from "./img/CMBO/CMBO_LOGO.png";
import CMBOPublicidad from "./img/CMBO/Publicidad_1.jpg";

import IDEMLogo from "./img/IDEM/logo.png";
import IDEMPublicidad from "./img/IDEM/publicidad.png";


import RADLogo from "./img/RADNET/logo.png";
import RADPublicidad from "./img/RADNET/publicidad.png";


const getConfiguration = (cliente = "CMMODERNO", production = true, withHeaders = false) => {
  //Se llamada desde systemState.js
  cliente = cliente.toUpperCase();
  const version = "v1.2.1.3";
  switch (cliente) {
    //Clientesx
    case "POLICLINICA":
      return {
        version: version,
        nombre: "Policlínica Metropolitana", //Titulo de dashboard
        logo: PoliclinicaLogo, //Logo de dashboard y login
        publicidad: PoliPublicidad, // Imagen de publicidad en dashboard
        showIdentificaciónBtn: false, // Login por tipo de credencial?
        url: production
          ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
          : "http://www.policlinicametropolitana-apps.com:30080", //Servidor
        urlWebpage: "https://policlinicametropolitana.org", //Onclick de publicidad
        theme: "theme-yellow",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
      };
    case "TAMARA":
      return {
        version: version,
        nombre: "Támara Imágenes Diagnósticas",
        logo: TamaraLogo,
        publicidad: TamaraPublicidad,
        showIdentificaciónBtn: true,
        url: production
          ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
          : "https://tamara.cmr-apps.com",
        urlWebpage: "https://tamaraimagenes.com",
        theme: "theme-red",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
      };
    case "CEDISA":
      return {
        version: version,
        nombre: "CEDISA",
        logo: cedisaLogo,
        verPublicidad: false,
        publicidad: cedisaPublicidad,
        showIdentificaciónBtn: false,
        url: production
          ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
          : "https://portalresultados.cedisa.do",
        urlWebpage: "https://www.cedisa.do/index.php",
        theme: "theme-hardblue",
        production: production,
        withHeaders: withHeaders,
        agendarCitas: true,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "ABREU":
      return {
        version: version,
        nombre: "",
        logo: abreuLogo,
        publicidad: abreuPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://190.167.197.220",
        urlWebpage: "https://www.clinicaabreu.com.do",
        theme: "theme-aqua",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "CMMODERNO":
      return {
        version: version,
        nombre: "Centro Medico Moderno",
        logo: moderno,
        publicidad: modernoPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://190.167.197.220",
        urlWebpage: "https://cmm.do",
        theme: "theme-bluelight",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "AD":
      return {
        version: version,
        nombre: "Central de Especialidades Medicas AD",
        logo: adLogo,
        publicidad: adPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://",
        urlWebpage: "https://asociadocs.com/",
        theme: "theme-blue",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "FRAY":
      return {
        version: version,
        nombre: "HOSPITAL Fray Juan De San Miguel",
        logo: frayLogo,
        publicidad: frayPublicidad,
        showIdentificaciónBtn: false,
        url: production
          ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
          : "http://www.policlinicametropolitana-apps.com:30080",
        urlWebpage: "https://www.hospitalfrayjuan.com/sitio/",
        theme: "theme-blue",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "HIGEA":
      return {
        version: version,
        nombre: "CENTRO DIAGNÓSTICO HIGEA", //Titulo de dashboard
        logo: HigeaLogo, //Logo de dashboard y login
        publicidad: HigeaPublicidad, // Imagen de publicidad en dashboard
        showIdentificaciónBtn: false, // Login por tipo de credencial?
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://200.76.159.141", //Servidor
        urlWebpage: "https://www.centrodiagnosticohigea.com/", //Onclick de publicidad
        theme: "theme-bluelight",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
      };
    case "CADI":
      return {
        version: version,
        nombre: "CADI",
        logo: cadiLogo,
        publicidad: cadiPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "https://portal.cadi.do",
        urlWebpage: "https://www.cadi.do",
        theme: "theme-blue",
        verPublicidad: false,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: true,
        facturacion: false,
      };
    case "DOC":
      return {
        version: version,
        nombre: "",
        logo: DrDLogo,
        publicidad: DrDPublicidad,
        showIdentificaciónBtn: false,
        url: production
          ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
          : "https://drdiagnostico.cmr-apps.com",
        urlWebpage: " https://doctordiagnostico.com.mx/",
        theme: "theme-whiteblue",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: true,
        facturacion: true,
        // http://www.cmr-apps.com
      };
    case "CMBO":
      return {
        version: version,
        nombre: "Centro Medico Bournigal",
        logo: CMBOLogo,
        publicidad: CMBOPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://www.cmr-apps.com",
        urlWebpage: " https://doctordiagnostico.com.mx/",
        theme: "theme-greenwhitered",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "IDEM":
      return {
        version: version,
        nombre: "Imágenes Diagnósticas de Especialidades Médicas",
        logo: IDEMLogo,
        publicidad: IDEMPublicidad,
        showIdentificaciónBtn: false,
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://www.cmr-apps.com",
        urlWebpage: "",
        theme: "theme-bluemedium",
        verPublicidad: true,
        production: production,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
        // http://www.cmr-apps.com
      };
    case "RADNET":
        return {
          version: version,
          nombre: "RADNET CENTRO DE IMÁGENES",
          logo: RADLogo,
          publicidad: RADPublicidad,
          showIdentificaciónBtn: false,
          url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://www.cmr-apps.com",
          urlWebpage: "",
          theme: "theme-radgreen",
          verPublicidad: true,
          production: production,
          withHeaders: withHeaders,
          agendarCitas: false,
          facturacion: false,
          // http://www.cmr-apps.com
        };
    default:
      return {
        version: version,
        nombre: "",
        logo: defaultImg,
        publicidad: defaultPublicidad,
        showIdentificaciónBtn: false,
        //url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
        urlWebpage: "https://www.cmr-rx.com",
        theme: "theme-blue",
        //LOCAL TEST
        //url: production ? "" :"http://www.cmr-apps.com",
        url: production ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : "http://www.cmr-apps.com",
        verPublicidad: true,
        withHeaders: withHeaders,
        agendarCitas: false,
        facturacion: false,
      };
  }
};

export default getConfiguration;
