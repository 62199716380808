import { useContext } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

//Se importan vistas
import Login from "./views/Login/login";
import Dashboard from "./views/Dashboard/dashboard";

import PrintView from "./views/printView.jsx";
import SplashScreen from "./views/Splash/SplashScreen.jsx";

//Context
import typeScriptContext from "./AppState/SystemConfig/appContext";

//Styles and global animations
import "./animations.css";
import "./styles/_main.scss";
import UserAccess from "./Global Components/utils/qrAccess";
import ModalSedes from "./views/Dashboard/components/Modal/sedes/sedesModal";





function App() {
  const { AppManager } = useContext(typeScriptContext);


  const Private = ({  Component }) => {
    let authenticated =  false;

    if(AppManager.userToken != null){
      authenticated= true
    }
    else if(AppManager.companyToken != null){
      authenticated= true
    }
  
    return authenticated ? <Component /> : <Navigate to="/login" />;
  };
  
  return (
    <Router basename="/">
      {/* // Declaracion de rutas y renderizado de paginas  */}
      <Routes>
        <Route exact path="/" element={<Private  Component={Dashboard} />}/>
        <Route
          path="/dashboard"
          element={<Private  Component={Dashboard} />}
        />
        
        <Route exact path="/Qrlogin" element={<UserAccess />} />       
        <Route exact path="/login" element={<Login />} />     
         

        <Route path="/pdf" element={<PrintView></PrintView>} />
        <Route path="/loading" element={<SplashScreen></SplashScreen>} />
        <Route path="*" element={<Navigate to="/" replace />} />
       
      </Routes>
    </Router>
  );
  
}


export default App;
