
export namespace MyUtilities {

    /**
     * Convierte Funciones para que existan en un contexto determinado.
     * @param fn
     * @param context
     * @param boundArgs
     */
    export function Bind(fn, context, boundArgs) {

        if (Function.prototype.bind !== undefined) {
            return Function.prototype.bind.apply(fn, [context].concat(boundArgs || []));
        }

        var bound = function () {

            // Join the already applied arguments to the now called ones (after converting to an array again).
            var args = (boundArgs || []).concat(Array.prototype.slice.call(arguments, 0));

            // If not being called as a constructor
            if (!(this instanceof bound)) {
                // return the result of the function called bound to target and partially applied.
                return fn.apply(context, args);
            }
            // If being called as a constructor, apply the function bound to self.
            fn.apply(this, args);
        };
        // Attach the prototype of the function to our newly created function.
        bound.prototype = fn.prototype;
        return bound;
    }

    export function IsNullOrUndefined(objeto): Boolean
    {
        let resultado = true;

        if (objeto != null)
            resultado = false;

        if (typeof objeto !== 'undefined' && objeto !== null)
            resultado = false;

        return resultado;
    }

    export function DivideIfNotZero(numerator: number, denominator: number): number {
        if (denominator === 0 || isNaN(denominator)) {
            return 0;
        }
        else {
            return numerator / denominator;
        }
     }
}