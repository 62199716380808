import React, { useContext } from 'react'
import typeScriptContext from "../../../AppState/SystemConfig/appContext";
import { CircularProgress } from "@mui/material"; 

function Publicidad({isloaded}) {
    
  const { AppConfiguration } = useContext(typeScriptContext);
  return (
    <div id="divGralData" className="col-xl-12 col-xxl-12 ps-lg-2 fade-in mb-3"
    style={{ height: "auto", minHeight: "15rem" }}>
    <div className="card h-100"
      style={{
        borderRadius: "25px",
        backgroundImage: isloaded?`url(${AppConfiguration.publicidad})`:"",
        backgroundSize: "100% 100%",
        // opacity:0.7,
        cursor:"pointer",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
      onClick={()=>{
        window.open(AppConfiguration.urlWebpage, '_blank');
      }}>
      <div className="bg-holder bg-card"></div>
      <div className="card-body position-relative">
     { !isloaded?(
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={70} color="warning"></CircularProgress>
      </div>
      ):<></>}

      </div>
    </div>
  </div>
  )
}

export default Publicidad