import React, { useContext, useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { Link } from "react-router-dom";

import SetTableByTokenType from "./components/tableDetector.js";

//NavBar Icons
import help from "./img/help.png";
import singout from "./img/singout.png";
import account from "./img/myaccount.png";

//Modal
import ChangePass from "./components/changePassword/changePassword";

import "./styles.css";

import typeScriptContext from "../../AppState/SystemConfig/appContext";

import Profile from "./components/Profiles/profile.jsx";
import ProfileCompany from "./components/Profiles/ProfileCompany.jsx";

import Publicidad from "./components/publicidad.jsx";
import ModalSedes from "./components/Modal/sedes/sedesModal.js";

function Dashboard() {
  const { AppManager, AppConfiguration } = useContext(typeScriptContext);

  const [profile, setProfile] = useState({});

  const [isSedesLoading, setIsSedesloading] = useState(false);
  prompt.setPerfil = (arry) => {
    setProfile(arry); //Local
  };

  const Openhelp = () => {
    window
      .open(window.location.origin + "/Expediente/help/PortalPacientes.html", "_blank", "location=yes,scrollbars=yes,status=yes")
      .focus();
  };

  useEffect(() => {
    if (!AppManager.loadingProfile && (Object.keys(profile).length == 0 || !profile)) {
      AppManager.loadProfile(prompt);
    }
    AppManager.translator.changeLabelsTitle();
    ajustarPaneles();
    return () => {};
  }, [AppManager, AppManager.translator,profile]);

  window.onresize = function () {
    ajustarPaneles();
  };

  function ajustarPaneles() {
    let elemtns = document.getElementById("divElementsDashboard");
    let elemProfile = document.getElementById("divGralProfile");
    let elemProfileRow = document.getElementById("divProfileRow");
    let elemStudies = document.getElementById("divStudies");
    let elemntImgProfile = document.getElementById("divProfileImage");
    let elemntDataGral = document.getElementById("divGralData");

    if (elemStudies != null) {
      if (elemStudies.clientWidth + elemProfile.clientWidth > elemtns.clientWidth) {
        // Is wrapped;
        elemtns.insertBefore(elemntImgProfile, elemStudies);
      }
      //Normal
      else elemProfileRow.insertBefore(elemntImgProfile, elemntDataGral);
    }
  }

  //Render Profile elemento depending if we have NIT credential or not
  function GetProfileComponent() {
    if (AppManager.userToken != null && AppManager.loginByNIT.toString() === "true") {
      return <ProfileCompany companyData={profile}></ProfileCompany>;
    } else {
      return <Profile userData={profile}></Profile>;
    }
  }

  return (
    <main className="main" id="top">
      <div className={AppConfiguration.theme}>
        <div className="container" data-layout="container">
          <div>
            <div className="navbar navbar-light navbar-glass navbar-top navbar-expand">
              <div>
                <button
                  className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarVerticalCollapse"
                  aria-controls="navbarVerticalCollapse"
                  aria-expanded="false"
                  aria-label="Toggle Navigation"
                >
                  <span className="navbar-toggle-icon">
                    <span className="toggle-line"></span>
                  </span>
                </button>
              </div>
              <a className="navbar-brand me-1 me-sm-3" href="#">
                <div className="d-flex align-items-center" style={{ padding: 8 }}>
                  <img className="me-2" src={AppConfiguration.logo} alt="" style={{ width: 130 }} />
                </div>
              </a>
              <BrowserView>
                <div className="d-flex align-items-center ">
                  <label 
                    id="logoTitle"
                    style={{ color: "rgb(19, 37, 60)", fontSize: 19, marginTop: 10}}
                  >
                    {AppConfiguration.nombre}
                  </label>
                </div>
              </BrowserView>
              <BrowserView
                className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"
                style={{
                  justifyItems: "flex-end",
                  justifyContent: "flex-end",
                  flex: "1 1 auto",
                  display: "flex",
                }}
              >
                {AppManager.sedesTokens && AppManager.sedesTokens.length !== 0 ? (
                  <div className="nav-item" style={{ marginRight: 25, display: "flex", alignItems: "center" }}>
                    <Link
                      to="/"
                      onClick={() => {
                        AppManager.showSedes();
                      }}
                    >
                      <p
                        data-language_txt="txt_Sedes"
                        style={{
                          color: "rgb(19, 37, 60)",
                          fontSize: 15,
                          marginLeft: 10,
                          paddingTop: 15,
                        }}
                      >
                        Sedes
                      </p>
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <div className="nav-item" style={{ marginRight: 25, display: "flex", alignItems: "center" }}>
                  <Link to="/" onClick={Openhelp}>
                    <p
                      data-language_txt="txt_Help"
                      style={{
                        color: "rgb(19, 37, 60)",
                        fontSize: 15,
                        marginLeft: 10,
                        paddingTop: 15,
                      }}
                    >
                      Help
                    </p>
                  </Link>
                </div>
                <div className="nav-item">
                  <Link to="/">
                    {" "}
                    <p
                      data-language_txt="txt_Cuenta"
                      style={{
                        color: "rgb(19, 37, 60)",
                        fontSize: 15,
                        marginRight: 25,
                        marginLeft: 10,
                        paddingTop: 15,
                        textAlign: "center",
                      }}
                      onClick={() => {
                        AppManager.showChangePassword();
                      }}
                    >
                      My account
                    </p>
                  </Link>
                </div>
                <div className="nav-item">
                  <Link to="/">
                    {" "}
                    <p
                      data-language_txt="txt_CerrarSesion"
                      style={{
                        color: "rgb(19, 37, 60)",
                        fontSize: 15,
                        marginLeft: 10,
                        paddingTop: 15,
                        textAlign: "center",
                      }}
                      onClick={() => {
                        console.log("Logout");
                        AppManager.clearToken();
                        window.location.reload();
                      }}
                    >
                      Log out
                    </p>
                  </Link>
                </div>
              </BrowserView>
              <MobileView
                className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center "
                style={{
                  justifyItems: "flex-end",
                  justifyContent: "flex-end",
                  flex: "1 1 auto",
                  display: "flex",
                }}
              >
                <div className="nav-item">
                  <img className="me-2" src={help} alt="" width="40" onClick={Openhelp} />
                </div>
                <div
                  className="nav-item"
                  onClick={() => {
                    console.log("Change password");
                    AppManager.showChangePassword();
                  }}
                >
                  <img className="me-2" src={account} alt="" width="40" />
                </div>
                <div
                  className="nav-item"
                  onClick={() => {
                    console.log("Logout");
                    AppManager.clearToken();
                    window.location.reload();
                  }}
                >
                  <img className="me-2" src={singout} alt="" width="40" />
                </div>
              </MobileView>
            </div>

            <div id="divElementsDashboard" className="row g-0">
              <div id="divStudies" className="col-lg-8 pe-lg-2 mb-3">
                {/* <Table
                  userData={profile}
                  data={estudios}
                  urlServicio={AppManager.urlServicio}
                  urlVisor={AppManager.urlVisor}
                  urlDescarga={`${AppManager.urlUltimaApi}/GetStudyZip`}
                ></Table> */}
                <SetTableByTokenType profile={profile}></SetTableByTokenType>
              </div>
              <div id="divGralProfile" className="col-lg-4">
                <div id="divProfileRow" className="row g-0 h-100">
                  <div id="divProfileImage" className="col-xl-12 col-xxl-12 ps-lg-2 mb-3" style={{ height: "auto" }}>
                    <GetProfileComponent/>
                  </div>
                  {/* Publicidad */}
                  {AppManager.publicidad ? <Publicidad isloaded={profile && profile.lenght != 0 ? true : false}></Publicidad> : <></>}
                </div>
              </div>
            </div>
            {/* footer */}
            <div>
              <div className="row g-0 justify-content-between fs--1 mt-3">
                <div className="col-12 col-sm-auto text-center">
                  <p className="mb-0 text-600">
                    <span data-language_txt="txt_thanksForUse">Gracias por usar INFOSALUD.</span>{" "}
                    <span className="d-none d-sm-inline-block">| </span>
                    <br className="d-sm-none" /> 2023 &copy;{" "}
                    <a href="https://www.cmr-rx.com" target="_blank">
                      CMR
                    </a>
                  </p>
                </div>
                <div className="col-12 col-sm-auto text-center">
                  <p className="mb-0 text-600">{AppConfiguration.version}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePass userData={profile}></ChangePass>
      <ModalSedes></ModalSedes>
    </main>
  );
}

export default Dashboard;
