import React, { useContext, useEffect } from "react";
import svg from "./img/2_gray.gif";
import ParticlesJS from "./particles.jsx";
//Context
import typeScriptContext from "../../AppState/SystemConfig/appContext";
import Cookies from "js-cookie";
import "./styles.css";

function SplashScreen() {
  const { AppManager } = useContext(typeScriptContext);
  const userToken = Cookies.get("Token");
  const authenticated = AppManager.userToken||userToken != null ? true : false;

  useEffect(() => {
    setTimeout(() => {
      if (authenticated) {
        window.location.replace("#/dashboard");
      } else {
        window.location.replace("#/login");
      }
    }, 3000);

    return () => {};
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#FAFAFA",
      }}
    >
      <ParticlesJS></ParticlesJS>
      <img src={svg} style={{ width: "400px", marginBottom: 10 }} className="fade-in noselect "></img>
    </div>
  );
}

export default SplashScreen;
