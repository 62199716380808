import React,{ useContext, useState} from 'react'
import SystemContext from '../../../../AppState/SystemConfig/appContext';
import CircularProgress from '@mui/material/CircularProgress'
import {DropdownButton,Dropdown} from 'react-bootstrap/';
import {isMobile} from "react-device-detect";
import { useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';

function ConsultaFolio(props) {
  const {AppManager,AppConfiguration} =useContext(SystemContext);
  const [dropDownValue,setDropDownValue]=useState('');
  const [loading, setLoading] = useState(false); 
  const [idCarnete, setidCarnete] = useState(0); 
  const [showCitas, setShowCitas] = useState(false); 
  const [showFacturacion, setShowFacturacion] = useState(false);   
  const [txtMensajeError, setTxtMensajeError] = useState("");
  const [valMonto,setValMonto] = useState(null);
  const [classNameMonto, setClassNameMonto] = useState('');
  const [classNameFolio, setClassNameFolio] = useState('');  

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();   
   }, [AppManager]);

  //Click Login
  const clickBuscarFactura = (event)=>{  
    event.preventDefault();
    if (!AppManager.reqLoadingPending)
    {
      let folio = $('#card-folio').val(); 
      if (AppManager.isDebug)             
        console.log("Folio-monto", folio,valMonto);
      if(folio !=="" && valMonto !==0 && valMonto !==undefined && valMonto !==null) 
      {
        setLoading(true); 
        AppManager.getFacturaFolioMonto(folio,valMonto,prompt)  
      }
      
      if (valMonto == null || valMonto ==0 || valMonto == undefined) 
      {
        setClassNameMonto('is-invalid'); 
      }

      if(folio =="" || folio == undefined){
        setClassNameFolio('is-invalid');
      }
    }else{
      setLoading(false);
    }    
  } 

  const onConsultaFactura = (event)=>{
    try{ 
    $('#card-folio').val("");    
    setValMonto(null);  
    setClassNameMonto('');
    setClassNameFolio(''); 
    mensajeCodigoError('0');
    props.muestraConsultaFactura(); 
    }
    catch(e){
      console.log('onConsultaFactura: ' + e);
    }
  }

  prompt.setFacturaFolioMonto = (informacion) => {
    try { 
      mensajeCodigoError('0');
      setLoading(false);  
      if(informacion.Data !== null)
      {
        //Limpia elementos del panel 
        $('#card-folio').val("");    
        setValMonto(null);  
        //Abre la ventana de facturación
        props.muestraFacturacion(informacion.Data);      
      }
      else if(informacion.Error !== null)
      {  
        const input = document.getElementById("card-folio");
        input.focus();
        input.select(); 
        mensajeCodigoError(informacion.Error.Code);
      }
      else{
        const input = document.getElementById("card-folio");
        input.focus();
        input.select(); 
        mensajeCodigoError("-1");
      }
    }
    catch(e){
      console.log('' + e);
    }
  }

  function mensajeCodigoError(codigo){
    try{
    var mensaje = '';
     switch(codigo){
      case "1000":
        setTxtMensajeError(AppManager.translator.current_lang == "es" ?
        "El ticket ya fue facturado" :
        "The ticket has already been invoice");        
        break;
      case "2000":
        setTxtMensajeError(AppManager.translator.current_lang == "es" ?
          "No se encontró información del ticket":
          "No ticket information found");
        break;
      case "3000":
        setTxtMensajeError(AppManager.translator.current_lang == "es" ?
        "Este ticket no puede ser facturado debido a que el pago del servicio se cubrió por otra entidad":
        "This ticket cannot be invoiced because the payment for the service was covered by another entity");
        break;
      case "0":
        setTxtMensajeError("");
        break;        
      default:  
        setTxtMensajeError(AppManager.translator.current_lang == "es" ?
        "No se pudo procesar su solicitud":
        "Your request could not be processed");
        break;
    }   
  }
  catch(e){
    console.log(e);
  }    
  }

  const handleOnValueChange = (_value, name, _values) => {
    mensajeCodigoError("0");
    if (_value == null || _value ==0 || _value == undefined) 
    {
      setClassNameMonto('is-invalid');
      setValMonto(0); 
    }else{
      setClassNameMonto('is-valid');
    setValMonto(_value);
    }
    if (AppManager.isDebug)             
      console.log('handleOnValueChange monto');
  };

  const handleOnFolioChange = (event) => {    
    mensajeCodigoError("0");
    if(event.target.value == undefined || event.target.value =="")
      setClassNameFolio('is-invalid');
    else
      setClassNameFolio('is-valid');
  }

  return (
    <div> 
     <div className="row flex-between-center notraslate">
        <div className="col-auto">
          <h3 data-language_txt="txt_Facturacion">Facturación electrónica</h3>
        </div>
      </div> 
      <form className='needs-validation' noValidate>  
      <div className="mb-2">
        <label className="form-label" data-language_lbl="lbl_folio"></label>
        <input className={`form-control ${classNameFolio}`} id="card-folio" required onChange={handleOnFolioChange}/>
      </div> 
      <div className="mb-2">
        <label className="form-label" data-language_lbl="lbl_monto"></label> 
        <CurrencyInput className={`form-control ${classNameMonto}`}
        id="card-monto"  
        prefix= '$'
        decimalSeparator="." groupSeparator=","  
        defaultValue={valMonto}
        decimalsLimit={2}
        onValueChange={handleOnValueChange}
      />
      </div>  
      {/* mensaje de error */}
      <div style={{height:"48px"}}> 
          <span style={{color:"red",fontWeight:"bolder",fontSize:"12px"}}>{txtMensajeError}</span>
      </div> 
      <div className="mb-3">
      <button className="id-btn d-block w-100 mt-1" type="submit" onClick={clickBuscarFactura}><div style={{display:"flex",alignItems:"center",justifyContent:"center",padding:5}}>
        <span data-language_txt="txt_buscar">Buscar</span>
         {loading?<CircularProgress id="circularLogin"size={20} color='inherit' style={{marginLeft:"10px"}}/>:<></>}</div></button>
      </div>
    </form>

    <a style={{cursor:"pointer"}} className="col-auto id-link-basic" onClick={()=>{
       props.backSingnin();
       AppManager.changeLanguaje(AppManager.translator.current_lang);
      }}><span data-language_txt="txt_BackRetry" className="fs--1">Regresar a inicio de sesión</span></a>
    {/*Consulta factura */}
    <div className="mb-4 mt-4" style={{display:"flex",justifyContent:"center"}}>
      <a style={{cursor:"pointer",fontWeight:"bolder",textDecoration: "underline"}} className="col-auto id-link-basic" onClick={()=>{
       onConsultaFactura();
      }}>
      <span data-language_txt="txt_ConsultaFactura" style={{fontSize:"14px"}} className="fs--1">Consultar factura</span></a>
    </div>
  </div> 
  )
 
}

export default ConsultaFolio