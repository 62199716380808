import React, { useState, useContext, useEffect } from "react";  
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";  
import DatePicker from "react-flatpickr";
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined'; 
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { isMobile } from "react-device-detect";

function EstudioFechaHora({estudio,onCloseEstudio,onChangeDate,onchangeTime,horasDisponibles}) { 
 
  const { AppManager,AppConfiguration } = useContext(typeScriptContext);   

  useEffect(() => {
    AppManager.translator.changeLabelsTitle();  
    return () => {};
  }, [AppManager]);
  
  const dataTimeP = { 
    options: {
      enableTime:true,
      noCalendar:true,
      dateFormat:"H:i",
    }
  }

  const dataTimeCP = { 
    options: {
      minDate:"today", 
      dateFormat: "Y-m-d"
    }
  }

  const ComboSelectTime = ()=> {
    return (
      <Stack spacing={2} style={{width:"230px"}}>
      <Autocomplete
        id="cmbSelectTimeStudyCita" 
        onInputChange={((event, value) => {  
          if(value !== null){ 
            onchangeTime(estudio.idEstudio,option.FechaInicio);
       
          }})}
        options={
          horasDisponibles.map((option) => new Date(option.FechaInicio).toLocaleTimeString()
          )}
        renderInput={(params) => <TextField {...params} />}/> 
    </Stack> 
    );
  }

  const onChangeTime = event => {  
    try{
      var value = event.target.value;
      if(value !== null){ 
        onchangeTime(estudio.idEstudio,value); 
      }
    }
    catch(e){
    console.log('onChangeTime' + e);
    }
  }

  return (
    <div className="cardEstudio" style={{margin:5,maxHeight:"260px"}} id={estudio.idEstudio}> 
    <div className="titleStudyCita" style={{paddingLeft:"10px",minHeight:40,alignItems:"center",maxHeight:"150px",display:"flex",flexDirection:"row",paddingTop:"5px"}}>
      <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
      <div className="scrollbarCustom" style={{fontWeight:"bold",textAlign:"left",paddingLeft:5,width:"85%",overflow:"scroll",overflowX:"hidden",overflowY:"auto"}}>{estudio.Descripcion}</div>
    </div> 
    <div  style={{display:"flex",flexDirection:"row",flex:"1 1 auto",flexWrap:"nowrap",marginBottom:"10px"}}>
      <div className="col" style={{marginLeft:"10px",alignItems:"flex-start",display:"flex",flexDirection:"column"}}>
        { isMobile ? 
        <div style={{display:"flex",flexDirection:"column",minWidth:"100px",flex:1,alignItems:"flex-start",flexWrap:"wrap",justifyContent:"center"}}>
         <div style={{display:"flex",flexWrap:"wrap"}}>
            <label style={{fontSize:"12px"}} data-language_lbl="lbl_Costo">Costo $:</label>
            <label style={{fontSize:"12px"}}>{estudio.Precio}</label></div>
          <div style={{display:"flex",flexWrap:"wrap"}}>
            <label style={{fontSize:"12px"}} data-language_lbl="lbl_DescuentoAbr">Descto. $:</label>
            <label style={{fontSize:"12px"}}>{estudio.Descuento !== null && estudio.Descuento !== undefined ? estudio.Descuento :0}</label></div>
        </div>
        :
        <div style={{display:"flex",flexDirection:"column",flex:1,minWidth:"100px",alignItems:"flex-start",flexWrap:"wrap",justifyContent:"center"}}>
          <div style={{display:"flex",flexWrap:"wrap"}}><label data-language_lbl="lbl_Costo">Costo $:</label><label>{estudio.Precio}</label></div>
          <div style={{display:"flex",flexWrap:"wrap"}}><label data-language_lbl="lbl_Descuento2">Descuento $:</label><label>{estudio.Descuento !== null && estudio.Descuento !== undefined ? estudio.Descuento :0}</label></div>
        </div>
        }
        <div style={{display:"flex",flex:"0 1 auto",alignItems:"center"}}>
          { isMobile ?
          <label style={{marginBottom:"0px",fontWeight:"bold",color:"green",cursor:"pointer"}} data-language_lbl="lbl_Eliminar" className="btnEliminarSmall d-flex flex-center transition-base" onClick={()=>onCloseEstudio(estudio.idEstudio)}>Eliminar</label> 
          :
          <label style={{marginBottom:"0px",fontWeight:"bold",color:"green",cursor:"pointer"}} data-language_lbl="lbl_Eliminar" className="btnEliminar d-flex flex-center transition-base" onClick={()=>onCloseEstudio(estudio.idEstudio)}>Eliminar</label> 
          }
        </div>
      </div>
      <div className="col" style={{alignItems:"flex-start",flexWrap:"wrap",alignItems:"center",marginRight:"10px"}}>
        <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
          <label data-language_lbl="lbl_Fecha">Fecha:</label>
          <DatePicker id={estudio.idEstudio + "Date"} format='yyyy-MM-dd' onChange={(date) =>onChangeDate(estudio.idEstudio,date)} options={dataTimeCP.options} className="form-control"></DatePicker>
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",marginTop:"10px"}}>
          <label data-language_lbl="lbl_HoraInicioFin">Hora inicio-fin:</label>
          <select id={"selEstudioFechaAg" + estudio.idEstudio}
            onChange={(e)=>{onChangeTime(e)}}  className="form-select" style={{lineHeight:1.7,fontSize:isMobile? "12px":"auto"}}>  
          {horasDisponibles !== null && horasDisponibles.length > 0 ? 
          horasDisponibles.map((item,index) =>{
            return(
              <option key={index+1} value={item.IdDivision + '_' + item.FechaInicio}> 
                { new Date(item.FechaInicio).toLocaleTimeString() + ' - ' + new Date(item.FechaFinal).toLocaleTimeString()}
                </option> 
          )})
          : 
            <option key={0} value={"Ninguno"} defaultChecked>
              {AppManager.translator.current_lang==="es" ?  "No disponible" : "Not available"}
            </option>  
          }
        </select>
        </div>
        
      </div>
    </div>
 </div> 
);
}
export default EstudioFechaHora;
