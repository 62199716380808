import React, { useContext,useState } from 'react'
import SystemContext from '../../../AppState/SystemConfig/appContext';
import CircularProgress from '@mui/material/CircularProgress'
import { red } from '@mui/material/colors';


function RetrievePass(props) {
  /* Forgot props*/
  /* closeForgot: funcion que cambia el estado del variable del login a false */
  const {AppManager} =useContext(SystemContext);
  const [email,setEmail]=useState("")
  const [loading,setloading]=useState(false)
  const [error,seterror]=useState(false)

  const passwordSendedStatus = (response) => {
    setloading(false)
    if(response){
      seterror(false)
      props.openConfirmRet()
    }
  };
  prompt.passwordSendedStatus = passwordSendedStatus;

  const sendCorreo=()=>{
    
    if(email!=""&&email.length>7){
      setloading(true)
      AppManager.forgotPassword(prompt,email)
    }
   else{
    seterror(true)
   }
  }


  const onChangeInput=(event)=>{
    setEmail(event.target.value);
  }
  
  return (
<form>
    <h3 data-language_txt="txt_TitleRetry">¿No puedes iniciar sesión?</h3>
    <div className="mb-3">
      <label className="form-label" htmlFor="card-username" data-language_lbl="lbl_titleSendLink">Capture su correo electrónico para enviar un enlace:</label>
      <input className="form-control" id="card-email" type="email" value={email} onChange={onChangeInput}/>
   {error? <span style={{color:"red",fontWeight:"700",fontSize:"13px"}}>*Correo no valido</span>:<></>}
    </div>
    <div className="mb-3" style={{cursor:"pointer"}}>
      <button data-language_btn="btn_SendLink" className="id-btn d-block w-100 mt-3" type="button" style={{height:"35px"}} onClick={sendCorreo}>Enviar enlace de recuperación{loading?<CircularProgress size={20} style={{marginLeft:15}}></CircularProgress>:<></>}</button>
    </div>
    <a style={{cursor:"pointer"}} className="col-auto id-link-basic" onClick={()=>{
       props.backForgot();
       AppManager.changeLanguaje(AppManager.translator.current_lang);
      }}><span data-language_txt="txt_BackRetry" className="fs--1">Regresar a inicio de sesión</span></a>
  </form>

  )
}

export default RetrievePass