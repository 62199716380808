/// <reference path ="../../../node_modules/@types/jquery/jquery.d.ts"/>
// @ts-ignore
import { ManagerIdiomas } from "./ManagerIdiomas.ts";
// @ts-ignore
import { ManagerModales } from "./ManagerModales.ts";
// @ts-ignore
import { ManagerRequest } from "./ManagerRequest.ts";
// @ts-ignore
import { MyUtilities } from "./MyUtilities.ts";

import Cookies from "js-cookie";

export interface Sede {
  IdSucursal:number;
  Nombre: string;
  UrlServicio: string;
  Activo: boolean;
  token: string;
}

// /* eslint-disable @typescript-eslint/class-name-casing */
// /* eslint-disable @typescript-eslint/camelcase */
// /* eslint-disable @typescript-eslint/no-inferrable-types */

export class AppManager {
  urlRoot: string; //Dominio
  urlServicio: string; //URI
  urlUltimaApi: string; //Servicio de Descarga
  urlVisor: string; //Servicio del VISOR
  requests: ManagerRequest;
  modals: ManagerModales;
  translator: ManagerIdiomas;
  userToken: null; // Sesión
  username: string;
  password: string;
  tableObject: null; //DOM
  profileObject: null; //DOM
  pdfObject: null; //DOM
  linkObject: null; //DOM
  passwordObject: null; //DOM
  passwordSendedObject: null; //DOM
  carteraVencida: null; //DOM
  reqLoadingPending: boolean; //Si esta en proceso de carga el login
  loginByCredential: boolean; //Tipo de login
  loginByNIT: boolean; //Tipo de Dashboard
  sedesURLS: Array<Sede>; // sedes o servidores adicionales
  sedesloader: boolean; // sedes o servidores adicionales
  sedesTokens: Array<any>; // sedes o servidores adicionales
  isSettingStudies: boolean; //Estan cargando los estudios
  publicidad: boolean;
  sedesPrincipalIsLoaded: boolean; //Se cargo la sede principal
  sedesActivas: number; //Cantidad resultante de contar las sedes activas en el array de sedesURLS
  sedesInactivas: number;
  isDebug: boolean; //Muestra mensajes de debug
  lastSede: string; //Ultima sede de la lista de sedes
  loadingProfile: boolean; //Cargando perfil
  gSedeActual:Sede;
  modalitiesObject:null;
  estudiosModalityObject:null;
  horariosModalityObject:null;
  sucursalesObject:null;
  estudiosAgendaObject:null;
  pacienteAgendaObject:null;
  ladasAgendaObject:null;
  planesAgendaObject:null; 
  citaAgendaObject:null; 
  citaFacturaMontoObject:null;
  catalogoEntidadesObject:null;
  catalogoMunicipiosObject:null;  
  catalogoCPObject:null;
  catalogoRegimenObject:null;
  catalogoCFDIObject:null;
  catalogoPagoSATObject:null;
  timbreFacturaObject:null;
  consultaFacturasObject:null;
  correoFacturaObject:null;
  descargaFacturaObject:null;
  codigoPaisObject:null;

  constructor({ urlServer, loginByCredential, publicidad,produccion,withHeaders }) {
    try {

      this.isDebug = !produccion;
      //URL servicio que se obtiene del config.js
      this.urlRoot = urlServer;
      this.loginByCredential = loginByCredential;
      this.translator = new ManagerIdiomas("es");
      this.sedesloader = false;
      this.sedesActivas = 0;
      this.sedesInactivas = 0;
      this.loadingProfile = false;
      this.isSettingStudies = false;
      this.sedesPrincipalIsLoaded = false;
      this.initURLs();
      this.publicidad = publicidad;
      this.reqLoadingPending = false;
      const NIT=Cookies.get("loginByNIT");
      if(NIT){
        this.loginByNIT = NIT;
      }
      else{
        this.loginByNIT = false;
      }
      this.userToken = Cookies.get("Token");
      let array = Cookies.get("TokenSedes"); //Se obtiene el array de tokens de sedes actual
      if (array && array !== "") {
        this.sedesTokens = JSON.parse(array);
      } else {
        this.sedesTokens = [];
      }

      this.modals = new ManagerModales();
      let callbackError = MyUtilities.Bind(this.onRequestError, this, null);
      
      this.requests = new ManagerRequest(callbackError,withHeaders);

      console.log("App Manager Loaded");
    } catch (ex) {}
  }
  initURLs() {
    try {
      this.urlServicio = `${this.urlRoot}/HisWebServicios/Portal/ServicioPortal.svc/`;
      /////CONEXION LOCAL PRUEBAS
      //this.urlServicio = `http://localhost/HisWebServicios/Portal/ServicioPortal.svc/`;
      this.urlVisor = `${this.urlRoot}/WebUltimateGL/App/Vistas/index.html`;
      this.urlUltimaApi = `${this.urlRoot}/WebUltimateGL/Servicio/Stream/Imagenes.svc`;
    } catch (ex) {}
  }
  initSedes() {
    try {
      //NUEVO (SETTEMOS LAS SEDES DESDE EL SERVICIO)

      //Si dejamos el array en blanco, se cargaran las sedes desde el servicio principal  (como antes)
      this.sedesloader = true;

      let callbackOnSedesRecived = MyUtilities.Bind(this.onSedesRecived, this, null);
      this.requests.postSedes(this.urlServicio, callbackOnSedesRecived);
    } catch (ex) {}
  }
  onSedesRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]);

      let parsed = JSON.parse(JSON.parse(respuesta));

      let arrayData = JSON.parse(parsed["ServidoresAdicionalesResult"])["data"];
      // console.log("Sedes recibidas: " + arrayData.length);
      if (this.isDebug) {
        console.log("Sedes recibidas: ", arrayData);
      }
      // @ts-ignore: Object is possibly 'null'.
      this.sedesURLS = arrayData;
      this.sedesloader = false;
      if(this.tableObject){this.loadStudies(this.tableObject);}
      // this.solicitarTokensSedes(this.username,this.password);
      //  const loader= document.getElementById("sedesLoader")
      //   if(loader){
      //     loader.style.display = "none";
      //     document.getElementById("sedesLoaderTxt").innerText = "Sedes Cargadas";
      //   }
    } catch (ex) {
      console.log(ex);
    }
  }
 
  onPDFDownloaded(info: any) {
    try {
      if (info && Object.keys(info).length > 0) {
        const respuesta = info["response"];

        var file = null;
        if (info["response"].size !== 0) {
          file = window.URL.createObjectURL(respuesta);
        }

        // @ts-ignore: Object is possibly 'null'.
        this.pdfObject!.setPDF(file);
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  setToken(token:any){
    
    Cookies.set("Token", token);
    this.userToken = token; //Se guarda el token principal Como anteriormente se hacia
  }
  onUserLogged(info: any) {
    try {
      const sedeActual = info["sede"];
      if (info && Object.keys(info).length > 0) {
        const respuesta = info["response"];
    
      
        if (respuesta.TokenResult && respuesta.TokenResult !== "") {
          
          this.username = info["usuario"];
          this.password = info["password"];
          this.requests.resetIntentos(sedeActual);
          if (sedeActual === "Principal") {
            Cookies.set("Token", respuesta.TokenResult);
            this.userToken = respuesta.TokenResult; //Se guarda el token principal Como anteriormente se hacia

            let array = Cookies.get("TokenSedes"); //Se obtiene el array de tokens de sedes actual
            if (array) {
              this.sedesTokens = JSON.parse(array);
              console.log("Sedes antes de peticion", this.sedesTokens);
            }

            window.location.replace("#/loading");
          } else {
            //En caso de que sea una sede diferente a la principal
            let array = Cookies.get("TokenSedes"); //Se obtiene el array de tokens de sedes actual

            if (!array || array === "") {
              console.log("Creando Token Sedes");
              Cookies.set("TokenSedes", JSON.stringify([{ sede: sedeActual, token: respuesta.TokenResult }]));
            } else {
              array = JSON.parse(array);

              if (array && array.length > 0) {
                // Si Existe otro token antes.  Caso mayor a 1 sede
                Cookies.set("TokenSedes", JSON.stringify([{ sede: sedeActual, token: respuesta.TokenResult }, ...array]));
              }
            }
          }
          // this.solicitarTokensSedes(this.username,this.password);
        } 
        if (respuesta.TokenByCredentialResult && respuesta.TokenByCredentialResult !== "") {
          
          Cookies.set("Token", respuesta.TokenByCredentialResult);
          this.userToken = respuesta.TokenByCredentialResult; //Se guarda el token principal Como anteriormente se hacia
          this.gSedeActual = sedeActual;
          window.location.replace("#/loading");
        }
        $("#divErrorData").css("display", "block");
        // setTimeout(() => {  //Enviar mensaje de datos inválidos
        //   $("#divErrorData").css("display", "block");}, 1500);
      } else {
        
        if(this.requests.getIntentos(sedeActual)===3){
          // // $("#divErrorData").css("display", "block");
        }
        //Enviar mensaje de datos inválidos
        // if(this.sedesURLS.length==0){
        //   $("#divErrorData").css("display", "block");
        //  }
      }

      //Seteamos el loading a false
      this.reqLoadingPending = false;
      $("#circularLogin").css("display", "none");
    } catch (ex) {
      console.log(ex);
    }
  }
  onLinkRecived(info: any) {
    let respuesta = JSON.parse(info["response"]).data!;
    // @ts-ignore: Object is possibly 'null'.
    this.linkObject!.setDOMURL(respuesta);
  }
  onInformacionCarteraVencidaRecived(info: any) {
    let respuesta = JSON.parse(info["response"]).ObtieneInformacionEstadisticaCuentasCobrarResult!;
    // @ts-ignore: Object is possibly 'null'.
    this.carteraVencida!.setDOMURL(JSON.parse(respuesta));
  }
  onRequestError(info?: any) {
    try {
      $("#divErrorConnection").css("display", "grid");
      return;
      // console.log(info);
    } catch (ex) {
    } finally {
      this.reqLoadingPending = false;
    }
  }
  onPassChanged(info: any) {
    let respuesta = info["response"];
    let parsed = JSON.parse(respuesta);
    let arrayData = JSON.parse(parsed["PasswordModifyResult"]);
 
    // @ts-ignore: Object is possibly 'null'.
    this.passwordObject!.setStatusPassword(arrayData["Success"]);
  }
  onPasswordSended(info: any) {
    let respuesta = info["response"];
    let parsed = JSON.parse(respuesta);
    let arrayData = JSON.parse(parsed["PasswordSendResult"]);

    // @ts-ignore: Object is possibly 'null'.
    this.passwordSendedObject!.passwordSendedStatus(arrayData["Success"]);
  }
  onSedeLogged(info: any) {
    try {
      console.log("Sede Logeada");

      if (info && Object.keys(info).length > 0) {
        const respuesta = info["response"];
        const sedeActual = info["sede"];
        if (respuesta.TokenResult && respuesta.TokenResult !== "") {
          this.requests.resetIntentos(sedeActual);

          console.log(`Respuesta de (${sedeActual})`, respuesta);
          this.userToken = respuesta.TokenResult;
          Cookies.set("Token", respuesta.TokenResult);
          window.location.replace("#/loading");
          this.gSedeActual = sedeActual;
        }
       
        else{  //Si no se logeo la sede por que es igual a vacio
          if (this.lastSede && (sedeActual === this.lastSede)) {
            this.reqLoadingPending = false;
            $("#circularLogin").css("display", "none");
           
            $("#divErrorData").css("display", "block");
            
          }else{
            // $("#divErrorData").css("display", "none");
            // alert(this.lastSede+sedeActual.toString());
          }

        }
        //Enviar mensaje de datos inválidos
      } else {
        //Enviar mensaje de datos inválidos
        // $("#divErrorData").css("display", "block");
      }

      // //Seteamos el loading a false
      // this.reqLoadingPending = false;
      // $("#circularLogin").css("display", "none");
    } catch (ex) {
      console.log(ex);
    }
  }
  solicitarTokensSedes(usuario: string, password: string) {
    console.log("Solicitando tokens de sedes");

    //Hacemos petición de token a las demás sedes
    if (this.sedesURLS && this.sedesURLS.length > 0) {
      //Si existen sedes
      console.log("Existen sedes");

      if (usuario && password && usuario != "" && password != "") {
        //Si hay password y usuario

        let callbackTokenSedeLogged = MyUtilities.Bind(this.onSedeLogged, this, null);
        let sedesPorIteradas = []; //Se guarda para saber cuales sedes se iteraron
        for (let index = 0; index < this.sedesURLS.length; index++) {
          this.requests.resetIntentos(this.sedesURLS[index].Nombre);
          if (this.sedesURLS[index].Activo) {
            console.log(
              `Solicitando token a: ( ${this.sedesURLS[index].Nombre} )` +
                this.sedesURLS[index].UrlServicio +
                "HisWebServicios/Portal/ServicioPortal.svc"
            );
            this.requests.postToken(
              `${this.sedesURLS[index].UrlServicio}HisWebServicios/Portal/ServicioPortal.svc/`,
              usuario,
              password,
              callbackTokenSedeLogged,
              this.sedesURLS[index].Nombre
            );

            sedesPorIteradas.push(this.sedesURLS[index].Nombre);
          }
        }
        this.lastSede = sedesPorIteradas[sedesPorIteradas.length - 1]; //Guardamos la ultima sede para saber cuando mandar el error
      } else {
        console.log("Sin credenciales para solicitar tokens de sedes");
      }
    } else {
      console.log("No existen sedes");
    }
  }
  clickLogin(carnete: number, usuario: string, password: string) {
    try {
      //   console.log(this.sedesURLS);
      if (!this.reqLoadingPending) {
    
        if (usuario.length > 0 && password.length > 0) {
          this.requests.resetIntentos("Principal");
          this.reqLoadingPending = true;
          $("#circularLogin").css("display", "block");
          let callbackPacienteLogged = MyUtilities.Bind(this.onUserLogged, this, null);
          if (this.loginByCredential) {
            //Si es logeo por credencial cambiamos request
            if (carnete == 17) {
              this.loginByNIT = true;
              Cookies.set("loginByNIT", true);
            } else {
              this.loginByNIT = false;
              Cookies.set("loginByNIT", false);
            }
            this.requests.postTokenByCredential(`${this.urlServicio}/`, usuario, password, carnete, callbackPacienteLogged);
          } else {
            //Si es logeo: sin tipos de credenciales
            this.requests.postToken(`${this.urlServicio}`, usuario, password, callbackPacienteLogged, "Principal"); //Hacemos petición a la sede Principalal
       
           
            if(this.sedesURLS && this.sedesURLS.length!=0){
            this.solicitarTokensSedes(usuario, password); //Hacemos petición de token a las demás sedes
           }
          

           // console.log("Solicitando token: " + this.urlServicio);
          }
        } else {
          //Si no ingreso nada
          $("#divErrorData").css("display", "block");
        }
       
      }
      this.reqLoadingPending = false;
    } catch (ex) {
      $("#circularLogin").css("display", "none");
      console.log(ex);
    }
  }
  loadStudies(reactObject: any) {
    try {
    // console.log("Cargando estudios desde el appManager");
      
      this.tableObject = reactObject;
   
      
      if (this.isDebug) {
        console.log("=============================Cargando estudios====================================");
      }

      let callbackOnStudiesRecived = MyUtilities.Bind(this.onStudiesRecived, this, null);
      this.isSettingStudies = true;
      
      if (this.loginByNIT.toString()=="true") {
     
        let token = this.userToken;
        console.log("Nit")
        this.requests.postStudiesbyCompany(reactObject.actualDate, this.urlServicio, token, callbackOnStudiesRecived);
      } else {
        //console.log("Normal");
        
        //=======================================Iteracion de Sede Principal=============================================
        if (!this.sedesPrincipalIsLoaded) {
          let token = this.userToken;
          this.requests.postStudies(this.urlServicio, token, callbackOnStudiesRecived, "Principal"); //Sede principal
          this.sedesPrincipalIsLoaded = true; //Si ya se cargo la sede principal
        }

        //=======================================Fin==============================================

        //=======================================Iteracion de Sedes==============================================
        if (this.sedesURLS && this.sedesURLS.length > 0) {
          //Si hay mas de una sede

          if (this.isDebug) {
            console.log("Sedes detectadas al cargar estudios", this.sedesURLS);
          }
          //Tenemos que iterar cada sede
          for (let index = 0; index < this.sedesURLS.length; index++) {
            const sede = this.sedesURLS[index];
            // console.log("Iterando sede: "+sede.name+"")
            if (sede.Activo) {
              this.requests.postStudies(
                `${sede.UrlServicio}HisWebServicios/Portal/ServicioPortal.svc/`,
                this.userToken,
                callbackOnStudiesRecived,
                sede.Nombre
              );
              // console.log("Sede activa: "+sede.Nombre+"")
              this.sedesActivas++;
            } else {
              this.sedesActivas++;
              //  console.log("Sede inactiva: "+sede.Nombre+"")
            }
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  onStudiesRecived(info: any) {
    try {
     
      let respuesta = JSON.stringify(info["response"]);
      let parsed = JSON.parse(JSON.parse(respuesta));

      let arrayData = [];
      if (this.loginByNIT.toString()=="true") {
        arrayData = JSON.parse(parsed["ImagingAnalysisByEmpresaResult"]);
      } else {
      
        
        arrayData = JSON.parse(parsed["ImagingAnalysisListWithTokenResult"]);
        if (this.isDebug) {
          console.log("onStudiesRecived Nueva data de: ", info["sede"], arrayData);
        }
        if (info["sede"] == "Principal") {
          this.sedesPrincipalIsLoaded = true; //Si ya se cargo la sede principal
        }
        for (const estudio of arrayData) {
          //Agregamos la sede a cada estudio
          estudio["sede"] = info["sede"];
        }
      }

      // @ts-ignore: Object is possibly 'null'.
      this.tableObject.setStudies!({sede:info["sede"],data:arrayData});
      this.isSettingStudies = false;
      info = null;
      // function deleteCookie(name:string) {
      //   document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // }
      
      // Uso
      // deleteCookie('folio');
      // deleteCookie('TokenDos');
      // deleteCookie('Token');
      // deleteCookie('csrftoken');
    } catch (ex) {
      console.log(ex);
    }
  }
  loadPDF(reactObject: any, folio: string, urlSede: string) {
    try {
      let callbackonPDFDownloaded = MyUtilities.Bind(this.onPDFDownloaded, this, null);
      let token = this.userToken;
      this.requests.postReporte(urlSede, token, callbackonPDFDownloaded, folio);
      this.pdfObject = reactObject;
    } catch (ex) {

    }
  }
  loadPDFEstadodeCuenta(reactObject: any, idEstudio: string, fechaInicio: string, fechaFin: string, tipo: string) {
    try {
      let callbackonPDFDownloaded = MyUtilities.Bind(this.onPDFDownloaded, this, null);
      let token = this.userToken;
      this.requests.postEstadoDeCuenta(this.urlServicio, token, callbackonPDFDownloaded, idEstudio, fechaInicio, fechaFin, tipo);
      this.pdfObject = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  loadProfile(reactObject: any) {
    try {
      let callbackonProfileRecived = MyUtilities.Bind(this.onProfileRecived, this, null);
      let token = this.userToken;
      this.loadingProfile=true;
      if (this.loginByNIT.toString()=="true") {
     
        this.requests.postProfileEmpresa(this.urlServicio, token, callbackonProfileRecived);
      } else {
        this.requests.postProfilePaciente(this.urlServicio, token, callbackonProfileRecived);
      }
      this.profileObject = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  onProfileRecived(info: any) {
    try {
      let arrayData = []; //LLenaremos con la respuesta
      let respuesta = JSON.stringify(info["response"]);
      let parsed = JSON.parse(JSON.parse(respuesta));

      if (this.loginByNIT.toString()==="true") {
        arrayData = JSON.parse(parsed["EmpresaResult"]);
      } else {
        arrayData = JSON.parse(parsed["PacienteResult"]);
      }
      this.loadingProfile=false;
      // @ts-ignore: Object is possibly 'null'.
      this.profileObject!.setPerfil(arrayData); 
    
    } catch (ex) {
      console.log(ex);
    }
  }
  loadShareableLink(reactObject: any, userId: string, idEstudio: string, meses: number,urlUltimaSelected:string) {
    try {
      let callbackOnLinkRecived = MyUtilities.Bind(this.onLinkRecived, this, null);
      this.requests.getShareableURL(urlUltimaSelected, userId, idEstudio, meses, callbackOnLinkRecived);
      this.linkObject = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  loadInformacionCarteraVencida(reactObject: any) {
    try {
      let callbackOnLinkRecived = MyUtilities.Bind(this.onInformacionCarteraVencidaRecived, this, null);
      this.requests.postInfoCarteraVencida(this.urlServicio, this.userToken, callbackOnLinkRecived);
      this.carteraVencida = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  changePassword(reactObject: any, correo: string, password: string) {
    try {
      let callbackonPassChanged = MyUtilities.Bind(this.onPassChanged, this, null);
      let token = this.userToken;
      this.requests.postchangePass(this.urlServicio, token, correo, password, callbackonPassChanged);
      this.passwordObject = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  forgotPassword(reactObject: any, correo: string) {
    try {
      let callbackonPasswordSended = MyUtilities.Bind(this.onPasswordSended, this, null);
      this.requests.postforgotPassword(this.urlServicio, correo, callbackonPasswordSended);
      this.passwordSendedObject = reactObject;
    } catch (ex) {
      alert(ex);
    }
  }
  animationStart() {
    try {
      $("#divLoader").css("display", "grid");
      $("#divLoaderAnimation").addClass("loader");
    } catch (ex) {}
  }
  animationStop() {
    try {
      $("#divLoader").css("display", "none");
      $("#divLoaderAnimation").removeClass("loader");
    } catch (ex) {}
  }
  changeLanguaje(lang: string) {
    console.log("change Language");
    try {
      if (lang === "en") {
        $("#itemLangEN").css("font-weight", "bold");
        $("#itemLangEN").css("text-decoration", "underline");
        $("#itemLangES").css("font-weight", "normal");
        $("#itemLangES").css("text-decoration", "none");
      } else {
        $("#itemLangEN").css("font-weight", "normal");
        $("#itemLangEN").css("text-decoration", "none");
        $("#itemLangES").css("font-weight", "bold");
        $("#itemLangES").css("text-decoration", "underline");
      }
      this.translator = new ManagerIdiomas(lang);
      this.translator.changeLabelsTitle();
    } catch (ex) {}
  }
  showAllProfile() {
    try {
      let elementosVisuales = document.getElementById("divProfileRow");
      if (elementosVisuales.children.length == 2) {
        $("#divGralData").css("display", "none");
        $("#perfilCreciente").css("height", "100%");
      } else {
        $("#perfilCreciente").css("height", "auto");
      }

      $("#btnShowProfile").css("display", "none");
      $("#btnHideProfile").css("display", "flex");
      //Animar
      $("#perfilCreciente").removeClass("fade-in");
      $("#perfilCreciente").addClass("fade-in");

      $("#fotoPerfil").removeClass("slide-in-blurred-top");
      $("#fotoPerfil").addClass("slide-in-blurred-top");
    } catch (ex) {}
  }
  hideProfile(mobile:boolean) {
    try {
      let elementosVisuales = document.getElementById("divProfileRow");
      if (elementosVisuales.children.length == 2) {
        $("#divGralData").css("display", "block");
      }

      $("#perfilCreciente").css("height", this.publicidad ? (mobile ? "0" : "20vh"): (mobile ? "0" : "50vh"));
      $("#btnShowProfile").css("display", "flex");
      $("#btnHideProfile").css("display", "none");
      //Animar
      $("#fotoPerfil").removeClass("slide-in-blurred-top");
      $("#perfilCreciente").removeClass("fade-in");
    } catch (ex) {}
  }
  clearToken() {
    console.log("Cerrando Sesión");
    this.loginByNIT = false;
    this.userToken = null;
    this.tableObject = null;
    this.profileObject = null;
    this.username = "";
    this.password = "";
    this.lastSede = null;
    Cookies.remove("Token");
    Cookies.remove("TokenSedes");
    Cookies.remove("loginByNIT");
    window.location.replace("#/login");
  }
  showChangePassword() {
    try {
      document.getElementById("divChangePassword").style.display = "block";
      document.getElementById("divChangePassword").style.opacity = "1";
    } catch (ex) {}
  }
  showSedes() {
    try {
      document.getElementById("divSedes").style.display = "block";
      document.getElementById("divSedes").style.opacity = "1";
    } catch (ex) {}
  }

  /////////////////////////////
  //AGENDAR ESTUDIOS 
  /////////////////////////////

   getModalidadesSucursal(sucursalUrl:string,reactObject:any){
    try{
      let callbackModalitiesRecived = MyUtilities.Bind(this.onModalitiesRecived, this, null);
      let url = sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.getModalidades(url, callbackModalitiesRecived);
      this.modalitiesObject = reactObject;
    }
    catch(ex){ 
      console.log('getModalidadesSucursal: ' + ex);
    }
   } 

   onModalitiesRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetModalidadesSucursalesResult"]).Data;  

      if (this.isDebug) {
        console.log("Modalidades recibidas: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.modalitiesObject!.setModalidades(arrayData);
    } catch (ex) {
      console.log('onModalitiesRecived: ' + ex);
    }
  }

  getEstudiosModalidad(sucursalUrl:string,modalidad:string,reactObject:any){
    try{
      let callbackEstudiosModalityRecived = MyUtilities.Bind(this.onEstudiosModalityRecived, this, null);
      let url = sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.getEstudiosModalidad(url,modalidad,callbackEstudiosModalityRecived);
      this.estudiosModalityObject = reactObject;
    }
    catch(ex){ 
      console.log('getEstudiosModalidad: ' + ex);
    }
   } 

   onEstudiosModalityRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetEstudiosModalidadResult"]).Data;  

      if (this.isDebug) {
        console.log("Estudios por modalidad recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.estudiosModalityObject!.setEstudiosModalidad(arrayData);
    } catch (ex) {
      console.log('onEstudiosModalityRecived: ' + ex);
    }
  }

  getHorariosDisponiblesModalidad(sucursalUrl:string,fecha:string,idEstudio:string,reactObject:any){
    try{
      let callbackHorariosModalityRecived = MyUtilities.Bind(this.onHorariosDisponiblesModalidad, this, idEstudio);
      let url = sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.getHorariosModalidad(url,fecha,idEstudio,callbackHorariosModalityRecived);
      this.horariosModalityObject = reactObject;
    }
    catch(ex){ 
      console.log('getHorariosDisponiblesModalidad: ' + ex);
    }
   } 

   onHorariosDisponiblesModalidad(idEstudio:string,info: any,) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetHorariosDisponiblesModalidadResult"]).Data;  

      if (this.isDebug) {
        console.log("Horarios por modalidad recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.horariosModalityObject!.setHorariosModalidad(arrayData,idEstudio);
    } catch (ex) {
      console.log('onHorariosDisponiblesModalidad: ' + ex);
    }
  }

  getSucursales(reactObject:any){
    try{
      let callbackSucursalesRecived = MyUtilities.Bind(this.onSucursalesDisponibles, this, null);
      let url = this.urlServicio;
      this.requests.getSucursales(url,callbackSucursalesRecived);
      this.sucursalesObject = reactObject;
    }
    catch(ex){ 
      console.log('getHorariosDisponiblesModalidad: ' + ex);
    }
   } 

   onSucursalesDisponibles(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetSucursalesResult"]).Data;  
      if (this.isDebug) {
        // console.log("onSucursalesDisponibles recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.sucursalesObject!.setSucursalesDisponibles(arrayData);
    } catch (ex) {
      console.log('onSucursalesDisponibles: ' + ex);
    }
  }

  setHorariosEstudiosAgenda(sucursalUrl:string,idPaciente:string,estudios:[],reactObject:any){
    try{
      let callbackHorariosEstudiosRecived = MyUtilities.Bind(this.onSetHorariosEstudiosAgenda, this, null);
      let url =  sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
  
      this.requests.setHorariosEstudiosAgenda(url,idPaciente,estudios,callbackHorariosEstudiosRecived);
      this.estudiosAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('setHorariosEstudiosAgenda: ' + ex);
    }
   } 

   onSetHorariosEstudiosAgenda(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null; 
      dataResult = JSON.parse(parsed["SetSolicitudesEstudiosResult"]).Data;  

      if (this.isDebug) {
        console.log("onSetHorariosEstudiosAgenda recibidos: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.estudiosAgendaObject!.setEstudiosAgenda(dataResult);
    } catch (ex) {
      console.log('onSetHorariosEstudiosAgenda: ' + ex);
    }
  }

  //ActualizaPaciente?folio={folio}&idPaisLada={idPaisLada}&telefono={telefono}&correo={correo}&poliza={poliza}&idPlan={idPlan}
  setDatosPacienteAgenda(sucursalUrl:string,folio:string,idLada:number,telefono:string,correo:string,poliza:string,idPlan:number,reactObject:any){
    try{
      let callbackDatosPacienteAgendaRecived = MyUtilities.Bind(this.onSetDatosPacienteAgendaRecived, this, null);
      let url =  sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.setDatosPacienteAgenda(url,folio,idLada,telefono,correo,poliza,idPlan,callbackDatosPacienteAgendaRecived);
      this.pacienteAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('setDatosPacienteAgenda: ' + ex);
    }
   } 

   onSetDatosPacienteAgendaRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["ActualizaPacienteResult"]).Data;  

      if (this.isDebug) {
        console.log("onSetDatosPacienteAgendaRecived recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.pacienteAgendaObject!.setDatosPaciente(arrayData);
      ///////////////////////////////
      //Si se actualizó con éxito, actualiza los datos de la credencial
      // @ts-ignore: Object is possibly 'null'.
       this.loadProfile(this.profileObject);
    } catch (ex) {
      console.log('onSetDatosPacienteAgendaRecived: ' + ex);
    }
  }

  getCatalogoPaisesLada(sucursalUrl:string,reactObject:any){
    try{
      let callbackLadasRecived = MyUtilities.Bind(this.onSetLadasAgendaRecived, this, null);
      let url =  sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      // @ts-ignore: Object is possibly 'null'.
      const callbackLadasRecivedError=()=>{ if(document.getElementById("btnCloseAgendaCita")){document.getElementById("btnCloseAgendaCita")!.click()}}
      this.requests.getLadasPais(url,callbackLadasRecived,callbackLadasRecivedError);
      this.ladasAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogoPaisesLada: ' + ex);
    }
   } 

   onSetLadasAgendaRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetCatalogoPaisesLadaResult"]).Data;  

      if (this.isDebug) {
        console.log("onSetLadasAgendaRecived recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.ladasAgendaObject!.setLadasAgenda(arrayData);
    } catch (ex) {
      console.log('onSetLadasAgendaRecived: ' + ex);
    }
  }

  getCatalogoPlanesAseguramiento(sucursalUrl:string,reactObject:any){
    try{
      let callbackPlanesAsegRecived = MyUtilities.Bind(this.onSetPlanesAgendaRecived, this, null);
      let url =  sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.getCatalogoPlanesAseguramiento(url,callbackPlanesAsegRecived);
      this.planesAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogoPlanesAseguramiento: ' + ex);
    }
   } 

   onSetPlanesAgendaRecived(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let arrayData = []; 
      arrayData = JSON.parse(parsed["GetCatalogoPlanesAseguramientoResult"]).Data;  

      if (this.isDebug) {
        console.log("onSetPlanesAgendaRecived recibidos: ", arrayData);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.planesAgendaObject!.setPlanesAgenda(arrayData);
     } catch (ex) {
      console.log('onSetPlanesAgendaRecived: ' + ex);
    }
  }

  setCitaSinPaciente(folio:string,primerNombre:string,segundoNombre:string,apellidoPaterno:string,
    apellidoMaterno:string,idLada:number,telefono:string,correo:string,poliza:string,idPlan:number,
                      estudios:[],reactObject:any){
    try{


      var datosCita = {datos: JSON.stringify({Folio:folio,
                   PrimerNombre: primerNombre,
                   SegundoNombre: segundoNombre,
                   ApellidoPaterno: apellidoPaterno,
                   ApellidoMaterno: apellidoMaterno,
                   correo:correo,
                   IdLada:idLada,
                   Telefono: telefono,
                   IdPlan:idPlan,
                   Poliza:poliza,
                   Estudios:estudios})};
      let callbackCitaSinPacienteRecived = MyUtilities.Bind(this.onSetCitaSinPaciente, this, null);

      let url =  this.urlServicio;
      this.requests.setNuevaCitaSinPaciente(url,JSON.stringify(datosCita),callbackCitaSinPacienteRecived);
      this.citaAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('setCitaSinPaciente: ' + ex);
    }
   } 

   onSetCitaSinPaciente(info: any) {
    try {
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null; 
    
      dataResult = JSON.parse(parsed["SetNuevaCitaSinPacienteResult"]).Data;  

      if (this.isDebug) {
        console.log("onSetCitaSinPaciente: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.citaAgendaObject!.setCitaNoPaciente(dataResult);
    } catch (ex) {
      console.log('onSetCitaSinPaciente: ' + ex);
    }
  }
  getDatosPacienteByFolioandPhone(folio:string,telefono:string,reactObject:any){
    try{
      const sucursalUrl=`${this.urlServicio}`
     const callbackError=()=>{
      reactObject.onUserSearched(null)
    
     }
      let callbackDatosPacienteAgendaRecived = MyUtilities.Bind(this.onGetDatosPacienteByFolioandPhone, this, null);
      let url =  sucursalUrl == "" || sucursalUrl === null || sucursalUrl === undefined ? this.urlServicio : sucursalUrl;
      this.requests.searchCedulaFromUser(url,folio,telefono,callbackDatosPacienteAgendaRecived,callbackError);
      this.pacienteAgendaObject = reactObject;
    }
    catch(ex){ 
      console.log('getDatosPacienteByFolioandPhone: ' + ex);
    }
   } 

   onGetDatosPacienteByFolioandPhone(info: any) {
    try {
   
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let resultObject = {}; 
      resultObject = JSON.parse(parsed["GetPacienteByFolioResult"]).Data.Result;  
    
        
      if (this.isDebug) {
        console.log("onSetDatosPacienteAgendaRecived recibidos: ", resultObject);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.pacienteAgendaObject!.onUserSearched(resultObject);


    } catch (ex) {
      console.log('onSetDatosPacienteAgendaRecived: ' + ex);

         // @ts-ignore: Object is possibly 'null'.
      this.pacienteAgendaObject!.onUserSearched([]);
    }
  }

  /////////////////////////
  //FACTURACION
  ///////////////////////// 
  getFacturaFolioMonto(folio:string,monto:string,reactObject:any){
    try{

      let callbackFacturaFolioMontoRecived = MyUtilities.Bind(this.onGetFacturaFolioMonto, this, null);
      let url =  this.urlServicio;
      this.requests.getFacturaPorFolioMonto(url,folio,monto,callbackFacturaFolioMontoRecived);
      this.citaFacturaMontoObject = reactObject;
    }
    catch(ex){ 
      console.log('getFacturaFolioMonto: ' + ex);
    }
   } 

   onGetFacturaFolioMonto(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetInformacionCuentaResult"]);   
      if (this.isDebug) {
        console.log("onGetFacturaFolioMonto: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.citaFacturaMontoObject!.setFacturaFolioMonto(dataResult);
    } catch (ex) {
      console.log('onGetFacturaFolioMonto: ' + ex);
    }
  }
  /////////////////////
  //Catalgo entidades
  getCatalogosEntidades(reactObject:any){
    try{

      let callbackCatalogoEntidadesRecived = MyUtilities.Bind(this.onGetCatalogoEntidadesFactura, this, null);
      let url =  this.urlServicio;
      this.requests.getCatalogoEntidadesFacturacion(url,callbackCatalogoEntidadesRecived);
      this.catalogoEntidadesObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogosEntidades: ' + ex);
    }
   } 

   onGetCatalogoEntidadesFactura(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetCatalogoEntidadesResult"]);   
      if (this.isDebug) {
        console.log("onGetCatalogoEntidadesFactura: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.catalogoEntidadesObject!.setCatalogoEntidades(dataResult);
    } catch (ex) {
      console.log('onGetCatalogoEntidadesFactura: ' + ex);
    }
  }

  //////////////////
  //Get Catalogos municipios
  getCatalogosMunicipios(idEstado:string,reactObject:any){
    try{

      let callbackCatalogoMunicipiosRecived = MyUtilities.Bind(this.onGetCatalogoMunicipios, this, null);
      let url =  this.urlServicio;
      this.requests.getCatalogoMunicipiosFacturacion(url,idEstado,callbackCatalogoMunicipiosRecived);
      this.catalogoMunicipiosObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogosEntidades: ' + ex);
    }
   } 

   onGetCatalogoMunicipios(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetCatalogoMunicipiosResult"]);   
      if (this.isDebug) {
        console.log("onGetCatalogoMunicipios: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.catalogoMunicipiosObject!.setCatalogoMunicipios(dataResult);
    } catch (ex) {
      console.log('onGetCatalogoMunicipios: ' + ex);
    }
  }

  //////////////////
  //Get Catalogo codigos postales
  getCatalogosCP(idMunicipio:string,reactObject:any){
    try{

      let callbackCatalogoCPRecived = MyUtilities.Bind(this.onGetCatalogoCP, this, null);
      let url =  this.urlServicio;
      this.requests.getCatalogoCPFacturacion(url,idMunicipio,callbackCatalogoCPRecived);
      this.catalogoCPObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogosCP: ' + ex);
    }
   } 

   onGetCatalogoCP(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetCatalogoCodigosPostalesResult"]);   
      if (this.isDebug) {
        console.log("GetCatalogoCodigosPostalesResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.catalogoCPObject!.setCatalogoCP(dataResult);
    } catch (ex) {
      console.log('onGetCatalogoCP: ' + ex);
    }
  }

  //////////////////////////////
  //Get Catalogo regimen fiscal
  getCatalogoRegimenFiscal(reactObject:any){
    try{

      let callbackCatalogoRegimenRecived = MyUtilities.Bind(this.onGetCatalogoRegimenFiscal, this, null);
      let url =  this.urlServicio;
      this.requests.getCatalogoRegimenFiscalFacturacion(url,callbackCatalogoRegimenRecived);
      this.catalogoRegimenObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogoRegimenFiscal: ' + ex);
    }
   } 

   onGetCatalogoRegimenFiscal(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetCatalogoRegimenFiscalResult"]);   
      if (this.isDebug) {
        console.log("GetCatalogoRegimenFiscalResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.catalogoRegimenObject!.setCatalogoRegimen(dataResult);
    } catch (ex) {
      console.log('onGetCatalogoRegimenFiscal: ' + ex);
    }
  }

  //////////////////////////////
  //Get Catalogo uso del CDFI
  getCatalogoUsoCFDI(reactObject:any){
    try{

      let callbackCatalogoCFDIRecived = MyUtilities.Bind(this.onGetCatalogoCFDI, this, null);
      let url =  this.urlServicio;
      this.requests.getCatalogoCFDIFacturacion(url,callbackCatalogoCFDIRecived);
      this.catalogoCFDIObject = reactObject;
    }
    catch(ex){ 
      console.log('getCatalogoRegimenFiscal: ' + ex);
    }
   } 

   onGetCatalogoCFDI(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetCatalogoUsosCFDIResult"]);   
      if (this.isDebug) {
        console.log("GetCatalogoUsosCFDIResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.catalogoCFDIObject!.setCatalogoCFDI(dataResult);
    } catch (ex) {
      console.log('onGetCatalogoCFDI: ' + ex);
    }
  }

   //////////////////////////////
  //Timbrar la factura
  getTimbrarFactura(folio:string, rfc:string, razonSocial:string, idRegimen:string, 
    idEntidad:string, idMunicipio:string, idCodigoPostal:string, usoCFDI:string, correo:string,
    reactObject:any){
    try{

      let callbackTimbreRecived = MyUtilities.Bind(this.onGetTimbreFactura, this, null);
      let url =  this.urlServicio;
      this.requests.getTimbrarFactura(url,folio,rfc,razonSocial,idRegimen,idEntidad,idMunicipio,
        idCodigoPostal,usoCFDI,correo,callbackTimbreRecived);
      this.timbreFacturaObject = reactObject;
    }
    catch(ex){ 
      console.log('getTimbrarFactura: ' + ex);
    }
   } 

   onGetTimbreFactura(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["TimbrarFacturaResult"]);   
      if (this.isDebug) {
        console.log("TimbrarFacturaResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.timbreFacturaObject!.setTimbrarFactura(dataResult);
    } catch (ex) {
      console.log('onGetTimbreFactura: ' + ex);
    }
  }

  //////////////////////////////
  //Consultar facturas
  getConsultarFacturas(rfc:string, nombrePaciente:string, fechaInicio:string, 
    fechaFin:string,reactObject:any){
    try{

      let callbackFacturasRecived = MyUtilities.Bind(this.onGetConsultaFacturas, this, null);
      let url =  this.urlServicio;
      this.requests.getListaFacturas(url,rfc,nombrePaciente,fechaInicio,fechaFin,callbackFacturasRecived);
      this.consultaFacturasObject = reactObject;
    }
    catch(ex){ 
      console.log('getConsultarFacturas: ' + ex);
    }
   } 

   onGetConsultaFacturas(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetListaFacturasResult"]);   
      if (this.isDebug) {
        console.log("GetListaFacturasResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.consultaFacturasObject!.setConsultaFacturas(dataResult);
    } catch (ex) {
      console.log('onGetConsultaFacturas: ' + ex);
    }
  }

  //////////////////////////////
  //Enviar factura a correo
  getEnviarCorreoFactura(correo:string, idFactura:string,reactObject:any){
    try{

      let callbackCorreoFacturaRecived = MyUtilities.Bind(this.onGetEnviarCorreoFactura, this, null);
      let url =  this.urlServicio;
      this.requests.getEnviarFacturaCorreo(url,correo,idFactura,callbackCorreoFacturaRecived);
      this.correoFacturaObject = reactObject;
    }
    catch(ex){ 
      console.log('getEnviarCorreoFactura: ' + ex);
    }
   } 

   onGetEnviarCorreoFactura(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["EnviarFacturaCorreoResult"]);   
      if (this.isDebug) {
        console.log("EnviarFacturaCorreoResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.correoFacturaObject!.setResultadoCorreoFactura(dataResult);
    } catch (ex) {
      console.log('onGetEnviarCorreoFactura: ' + ex);
    }
  }

   //////////////////////////////
  //Descargar archivos factura 
  getDescargaArchivosFactura(idFactura:string,reactObject:any){
    try{

      let callbackDescargaFacturaRecived = MyUtilities.Bind(this.onGetDescargarFactura, this, null);
      let url =  this.urlServicio;
      this.requests.getDescargarFactura(url,idFactura,callbackDescargaFacturaRecived);
      this.descargaFacturaObject = reactObject;
    }
    catch(ex){ 
      console.log('getDescargaArchivosFactura: ' + ex);
    }
   } 

   onGetDescargarFactura(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["DescargarArchivosFacturaResult"]);   
      if (this.isDebug) {
        console.log("DescargarArchivosFacturaResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.descargaFacturaObject!.setResultadoDescargaFactura(dataResult);
    } catch (ex) {
      console.log('onGetDescargarFactura: ' + ex);
    }
  }

  //////////////////////////////
  //Get codigo país 
  getIdentificadorPais(reactObject:any){
    try{

      let callbackCodigoPaisRecived = MyUtilities.Bind(this.onGetCodigoPais, this, null);
      let url =  this.urlServicio;
      this.requests.getIdPais(url,callbackCodigoPaisRecived);
      this.codigoPaisObject = reactObject;
    }
    catch(ex){ 
      console.log('getDescargaArchivosFactura: ' + ex);
    }
   } 

   onGetCodigoPais(info: any) {
    try { 
      let respuesta = JSON.stringify(info["response"]); 
      let parsed = JSON.parse(JSON.parse(respuesta)); 
      let dataResult =null;  
      dataResult = JSON.parse(parsed["GetIdPaisHospitalResult"]);   
      if (this.isDebug) {
        console.log("CodigoPaisResult: ", dataResult);
      }  
      // @ts-ignore: Object is possibly 'null'.
      this.codigoPaisObject!.setCodigoPais(dataResult);
    } catch (ex) {
      console.log('onGetCodigoPais: ' + ex);
    }
  }

}
