import React, { useState, useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import InvoiceIcon from '@mui/icons-material/Receipt';  
import FacturaItem from "./facturaItem"; 
import dayjs from "dayjs";
import DatePicker from "react-flatpickr";
import MsgInfoData from "./msgInfoData";  
import {isMobile} from "react-device-detect";

function ModalConsultaFacturacion({closeModalConsultaFacturacion}) {
  const { AppManager, AppConfiguration } = useContext(typeScriptContext);
  const [itemIndex, setItemIndex] = useState(1); 
  const [firstLoad, setFirstLoad] = useState(true);   
  const [valueRFC, setValueRFC] = useState(""); 
  const [valueCorreo, setValueCorreo] = useState("");  
  const [valueNombre, setValueNombre] = useState("");  
  const [valueFechaInicio, setValueFechaInicio] = useState("");  
  const [valueFechaFin, setValueFechaFin] = useState(""); 
  const [muestraFiltroNombre,setMuestraFiltroNombre] = useState(false);
  const [facturasDisponibles,setFacturasDisponibles] = useState([]);
  const [isloadingFacturas,setIsLoadingFacturas] = useState(false);
  const [isloadingEnvioCorreo,setIsLoadingEnvioCorreo] = useState(false);
  const [isloadingDescargaFactura,setIsLoadingDescargaFactura] = useState(false); 
  const [idFacturasSeleccionadas, setIdFacturasSeleccionadas] = useState([]); 
  const [showBotonDescarga, setShowBotonDescarga] = useState(false);

  //////////Mensajes de información  
  const [isMensaje, setIsMensaje] = useState(false);  
  const [isMensajeError, setIsMensajeError] = useState(false);  
  const [mensaje, setMensaje] = useState(""); 
  const [tituloMensaje, setTituloMensaje] = useState(""); 

  //////////////////////
  // RESULTADOS PROMPT
  //////////////////////  

  prompt.setConsultaFacturas = (data) => {
    try {
      setFacturasDisponibles([]);
      setIsLoadingFacturas(false);
      if (data.Data !== null && data.Data.length > 0) {   
        setFacturasDisponibles(data.Data);      
        pintaNextItem(2);     
      }else if(data.Error !== null && data.Error !== ""){ 
        if(data.Error.Code === "0x002")
        {  if(AppManager.translator.current_lang === "es")
            onShowMensaje("Información","Ocurrio un error en la consulta...",false); 
          else
            onShowMensaje("Information","An error occurred in the data search...",false); 
        }
        else
          onShowMensaje("",data.Error.Message,true); 
      }
      else{
        const input = document.getElementById("inputRFCConsulta");
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Información","No se encontrarón facturas...",false); 
        else
          onShowMensaje("Information","No invoices found...",false); 
      }  
    } catch (e) {
      if(AppManager.translator.current_lang === "es")
        onShowMensaje("Información","Ocurrio un error en la consulta...",false); 
      else
        onShowMensaje("Information","An error occurred in the data search...",false); 
      setIsLoadingFacturas(false); 
    }
  };

  prompt.setResultadoCorreoFactura=(data) =>{
    try {  
      setIsLoadingEnvioCorreo(false);
      if (data.Data !== undefined && data.Data !== null) {   
           if(data.Data.Success == true){    
            if(AppManager.translator.current_lang === "es")         
              onShowMensaje("Envío exitoso","Se ha enviado el correo...",false); 
            else
              onShowMensaje("Successful email sending","The email has been sent...",false); 
           }else if(data.Data.Error !== null && data.Data.Error !== ""){ 
            if(AppManager.translator.current_lang === "es")
              onShowMensaje("Error en datos",data.Data.Error.Message,true); 
            else
              onShowMensaje("Data error",data.Data.Error.Message,true); 
           }
           else{
            if(AppManager.translator.current_lang === "es")
              onShowMensaje("Error","Ocurrió un error al enviar la(s) factura(s) al correo...",true); 
            else
              onShowMensaje("Error","An error occurred while sending the invoice(s) to the email...",true); 
           }
      }else{ 
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Error","Ocurrió un error al enviar la(s) factura(s) al correo...",true); 
        else
          onShowMensaje("Error","An error occurred while sending the invoice(s) to the email...",true);          
      }
    } catch (e) { 
      console.log('setResultadoCorreoFactura' + e);
    }
  }

  function str2bytes (str) {
    var bytes = new Uint8Array(str.length);
    for (var i=0; i<str.length; i++) {
       bytes[i] = str.charCodeAt(i);
     }
     return bytes;
  }

  prompt.setResultadoDescargaFactura=(data) =>{
    try{
      setIsLoadingDescargaFactura(false);
      if (data.Data !== undefined && data.Data !== null) { 
        var fileName = 'Files' + Date.now() + '_' + '.zip';
        var base64String = Uint8Array.from(window.atob(data.Data), (v) => v.charCodeAt(0));
        var blob = new Blob([ base64String ], {type:"application/zip"});
        //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        //Create a DOMString representing the blob and point the link element towards it
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        //programatically click the link to trigger the download
        a.click();
        //release the reference to the file by revoking the Object URL
        window.URL.revokeObjectURL(url); 
        
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Descarga exitoso","Se ha(n) descargado la(s) factura(s)...",false); 
        else
          onShowMensaje("Successful download","The invoice(s) has been downloaded!",false);  
      }else if(data.Error !== null && data.Error !== ""){ 
        if(AppManager.translator.current_lang === "es")         
          onShowMensaje("Error en descarga",data.Error.Message,true); 
        else
          onShowMensaje("Download error",data.Error.Message,true); 
      }else{
        if(AppManager.translator.current_lang === "es")         
          onShowMensaje("Error","Ocurrió un error al descargar los archivos de la(s) factura(s)...",true); 
        else
          onShowMensaje("Error","An error occurred while downloading the invoice(s) files...",true); 
      } 
    }
    catch(e){
      console.log('setResultadoDescargaFactura' + e);
    }
  }
 
  /////////////////////
  // TAB ITEMS
  /////////////////////

  const changeTabNext = () => {
    try {
      setMensaje("");
      var numItem = itemIndex + 1;
      switch (itemIndex) {
        //DATOS DE ENTRADA
        case 1: {
          pintaNextItem(numItem);              
          break;
        } 
      }

      if (numItem < 4) {
        pintaNextItem(numItem);
      } else {
        setItemIndex(3);
      }

    } catch (e) {
      // console.log('changeTabNext' + e);
    }
  };

  const pintaNextItem = (numItem) => {
    try {
      //Item previo
      var item = document.getElementById("tabItem" + (numItem - 1) + "_ConsultaFactura");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0";
      }

      //Siguiente item
      item = document.getElementById("tabItem" + numItem + "_ConsultaFactura");
      if (item !== null) {
        item.style.display = "block";
        item.style.opacity = "1";
      }
      setItemIndex(numItem);
    } catch (e) {
      // console.log('pintaNextItem' + e);
    }
  };

  const changeTabPrev = () => {
    var numItem = itemIndex - 1;
    setItemIndex(numItem);
    setMensaje("");
    if (numItem > 0) {
      //Item next
      var item = document.getElementById("tabItem" + itemIndex + "_ConsultaFactura");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0";
      }

      //Item actual
      item = document.getElementById("tabItem" + numItem + "_ConsultaFactura");
      if (item !== null) {
        item.style.display = "block";
        item.style.opacity = "1";
      } 
    } else {
      setItemIndex(1);
    } 
  }; 

  /////////////////////////
  // METODOS
  /////////////////////////

  const dateOptionsDate = { 
    options: {
      maxDate:"today", 
      dateFormat: "Y-m-d"
    }
  }

  function onShowMensaje(titulo,mensaje,error){
    try{  
          onResetMensaje();
          ////////////////
          //Agrega datos al mensaje
          setIsMensaje(true);
          setMensaje(mensaje);
          setIsMensajeError(error);
          setTituloMensaje(titulo);
    }
    catch(e){
      console.log(e);
    }
  }

  function onResetMensaje(){
    //////////////////
    //Reset mensaje
    setIsMensaje(false);
    setMensaje("");
    setIsMensajeError(false);
    setTituloMensaje("");
  }

  function onBuscarFacturas(){
    try{   
      if(valueRFC == "" || valueFechaInicio === "" || valueFechaFin === "" || (valueRFC.length === 12 && valueNombre === "")){
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Información","Los datos proporcionados son incorrectos!",false); 
        else
          onShowMensaje("Information","The data entered is incorrect!",false); 
         return;
      }       
      setIsLoadingFacturas(true);
      AppManager.getConsultarFacturas(valueRFC,valueNombre,valueFechaInicio,valueFechaFin,prompt);        
    }
    catch(e){
      console.log(e);
    }
}

  function onEnviarFacturaCorreo(){
    try{  
      var validator = $('#inputCorreoConsultaFactura')[0].validity.valid; 
      if (!validator) 
      {  
        const input = document.getElementById("inputCorreoConsultaFactura");
        input.focus();
        input.select(); 
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Información","El correo proporcionado no es válido!",false);  
        else  
          onShowMensaje("Information","The email entered is incorrect!",false);  
      }
      else if (idFacturasSeleccionadas.length === 0){
        if(AppManager.translator.current_lang === "es")
          onShowMensaje("Información","Seleccione una o varias factura(s)!",false);  
        else
          onShowMensaje("Information","Select an invoice!",false);  
      }
      else{
          setIsLoadingEnvioCorreo(true);          
          var ids = idFacturasSeleccionadas.join(); 
          console.log('Facturas string:' + ids);
          AppManager.getEnviarCorreoFactura(valueCorreo,ids,prompt);
      }
    }
    catch(e){
      console.log(e);
    }
  }

  function onDescargarArchivosFactura(){
      try{  
            if (idFacturasSeleccionadas.length === 0){
              if(AppManager.translator.current_lang === "es")
                onShowMensaje("Información","Seleccione una factura!",false);  
              else
                onShowMensaje("Information","Select any invoice(s)!",false);  
            }else{
              setIsLoadingDescargaFactura(true);
              var ids = idFacturasSeleccionadas.join(); 
              console.log('Facturas string:' + ids);
              AppManager.getDescargaArchivosFactura(ids,prompt);
            } 
      }
      catch(e){
        console.log('onDescargarArchivosFactura' + e);
      }
  }
  
  const onChangeStartDate= (fecha) => {
    try {
      fecha = dayjs(fecha).format("YYYY-MM-DD");
      if (AppManager.isDebug)   
        console.log('Fecha Inicio:' + fecha);
      ////////TO DO VALIDAR QUE SEA MENOR QUE LA FECHA FINAL
      setValueFechaInicio(fecha);
    }
    catch(e){
      console.log(e);
    }
  }

  const onChangeEndDate= (fecha) => {
    try {
      fecha = dayjs(fecha).format("YYYY-MM-DD");
      if (AppManager.isDebug)   
        console.log('Fecha Fin:' + fecha);
      ////////TO DO VALIDAR QUE SEA MAYOR QUE LA FECHA INICIAL
      setValueFechaFin(fecha);
    }
    catch(e){
      console.log(e);
    }
  }
  
  const closeModal = () => {
    closeModalConsultaFacturacion();
    document.getElementById("divModalConsultaFacturacion").style.display = "block";
    document.getElementById("divModalConsultaFacturacion").style.opacity = "0";
  };
  
  const handleRFCChange = (e) => {
    try {      
      setValueRFC(e.target.value);
      if (e.target.value !== null&& e.target.value.length === 13) 
      { 
          setMuestraFiltroNombre(false);
          setValueNombre("");
      }else if (e.target.value !== null && e.target.value.length === 12) 
      { 
         setMuestraFiltroNombre(true); 
         setValueNombre("");
      }
    } catch (e) {}
  };

  const handleEmpresaChange = (e) =>{
    try {     
      setValueNombre(e.target.value);          
    } catch (e) {}
  }

  const handleCorreoChange = (e) => {
    try {
      if (e.target.value !== null&& e.target.value.length >0) 
      { 
          setValueCorreo(e.target.value);
      } else{
        setValueCorreo("");
      }
    } catch (e) {}
  };

  function onCancelarConsulta(){  
    setIdFacturasSeleccionadas([]);
    setValueRFC("");
    setValueNombre("");
    setValueCorreo("");
    setValueFechaFin("");
    setValueFechaInicio("");
    onResetMensaje();
    changeTabPrev();

    $('#startDate').val(""); 
    $('#endDate').val("");  
    $('#inputRFCConsulta').val(""); 
    const input = document.getElementById("inputRFCConsulta");
    input.focus();

    $('#nombreConsulta').val("");  
    $('#inputCorreoConsultaFactura').val("");
  };

  const revisaItemFacturaSeleccionada =(item)=>{ 
    if(idFacturasSeleccionadas.length > 0){
        if(idFacturasSeleccionadas.filter(x=>x === item).length > 0)
            return true;
        else
            return false;
    }else
    return false; 
  }

  const revisaFactura=(item)=>{
    try{
      
      //console.log('Id factura seleccionada:' + item);    
      var data = idFacturasSeleccionadas.filter(x=>x === item);
      if(data.length > 0 && data !==undefined){
        //Remover el item
        if (AppManager.isDebug)   
            console.log('Id factura deseleccionada:' + item);  
        var data = idFacturasSeleccionadas.filter(x=>x !== item);
        if (AppManager.isDebug)   
            console.log('Facturas:' + data);
        setIdFacturasSeleccionadas(data);
      }else{
        //Agregar el item
        idFacturasSeleccionadas.push(item);
        if (AppManager.isDebug)   
            console.log('Se añade el id de factura:' + item);
      }
    
    }
    catch(e)
    {
      console.log(e);
    }
  }

  ////////////////
  // USE EFFECT
  ////////////////
  useEffect(() => {
     AppManager.translator.changeLabelsTitle();
 
    if (firstLoad) {
      try {
        setFirstLoad(false); 
      } catch (e) {
        // console.log(e);
      }
    }
    return () => {};
  });

  return (
    <div className={AppConfiguration.theme}>
      <MsgInfoData isOpen={isMensaje} mensaje={mensaje} titulo={tituloMensaje} error={isMensajeError} close={() => setIsMensaje(false)}></MsgInfoData>
      <div
        id="divModalConsultaFacturacion"
        className="modal container-fluid fade-in top-50 position-absolute start-50 translate-middle"
        style={{
          zIndex: 1,
          display: "block",
          width: "100vw",
          height: "100vh",
        }}>
        <div className="row justify-content-center pt-6" style={{ alignItems: "center", height: "100%" }}>
          <div className="col-sm-10 col-lg-9 col-xxl-8">
            <div className="card theme-wizard mb-5" id="wizard">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                id="btnCloseModalConsultaFactura"
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="card-header bg-light pt-3 pb-2">
                <ul className="nav justify-content-between">
                  <li className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-cir">
                        <InvoiceIcon></InvoiceIcon>
                        </span>
                      </span>
                      <span data-language_txt="txt_ConsultaFactura" className="d-md-block mt-1 fs--1 colorP">
                        Consultar Factura
                      </span>
                    </a>
                  </li>
                </ul> 
              </div>
              
                <div className="card-body" style={{ padding: "0px !important" }}>  
                  {/*CONTENIDO */}
                  <div className="tab-content" style={{ height: "420px" }}>
                    {/* ********************* */}
                    {/* PASO 1 CONSULTA DATOS */}
                    {/* ********************* */}
                    <div id="tabItem1_ConsultaFactura" style={{padding:"0px"}} className="tab-pane container active px-sm-0 " role="tabpanel">
                      <form className="needs-validation " noValidate>
                        {/*rfc*/}
                        <div style={{marginLeft:"5px"}}>
                            <label className="form-label" htmlFor="RFC">
                              {AppManager.translator.current_lang === "es" ?  "RFC*" : "Tax ID*"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important", width: "100%" }}
                                onChange={handleRFCChange}
                                defaultValue={valueRFC}
                                className="form-control"                
                                id="inputRFCConsulta"/> 
                        </div>
                        <label className="mb-4 form-label" style={{fontSize:"10px"}}>
                          {AppManager.translator.current_lang === "es" ?  
                          "Ingrese su RFC con homoclave sin guiones, por ejemplo: EEOO97104JUI": 
                          "Enter TAX ID with homoclave without hyphens, for example: EEOO97104JUI"}                            
                        </label>                        

                        {/*fecha*/}
                        <div className="row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                          {/*Inicio*/} 
                          <div className="mb-3 col" style={{marginLeft:"5px",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                            <label className="form-label"data-language_lbl="lbl_FechaInicio">Fecha Inicio:</label>
                            <DatePicker id="startDate" value={valueFechaInicio} format='yyyy-MM-dd' onChange={(date) =>onChangeStartDate(date)} options={dateOptionsDate.options} className="form-control"></DatePicker>
                          </div>
                          {/*Fin*/} 
                          <div  className="mb-3 col" style={{marginLeft:"5px",display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
                            <label className="form-label" data-language_lbl="lbl_FechaFin">Fecha Fin:</label>
                            <DatePicker id="endDate" value={valueFechaFin} format='yyyy-MM-dd' onChange={(date) =>onChangeEndDate(date)} options={dateOptionsDate.options} className="form-control"></DatePicker>
                          </div>
                        </div>

                        {/*nombre*/}
                        {muestraFiltroNombre ? 
                        <div className="mb-3" style={{ display: "flex", flexDirection:"column", justifyContent: "" }}> 
                              <label className="form-label" htmlFor="nombre">
                              {AppManager.translator.current_lang === "es" ?  "Nombre del paciente:" : "Patient Name:"}
                              </label>
                              <input onChange={handleEmpresaChange} defaultValue={valueNombre}
                                style={{ fontSize: "11px !important", width: "100%" }}
                                className="form-control"                  
                                id="nombreConsulta"/>  
                        </div>  :
                        <></> 
                        }

                        {/*Buscar*/}
                        <div className="mb-5 mt-5" style={{display:"flex",justifyContent:"center"}}>
                          <button id="btnBuscar" onClick={onBuscarFacturas} style={{width:"200px"}} className="btn btn btn-primary" type="button"> 
                          <span data-language_txt="txt_buscar">Buscar</span>
                          </button>                     
                        </div>      
                    </form>
                    </div>
                    {/* ************************** */}
                    {/*   PASO 2 LISTA FACTURAS    */}
                    {/* ************************** */}
                    <div
                      id="tabItem2_ConsultaFactura"
                      className="tab-pane container fade px-sm-0"
                      style={{ height: "420px",padding:"0px",overflowY: "auto",overflowX:"hidden"}}
                      role="tabpanel">
                      <form style={{paddingLeft:"5px",paddingRight:"5px"}} className="needs-validation " noValidate>
                        <div style={{display:"flex",flexDirection:"column",height: "420px"}}>
                       {/*Botones de descarga y envio */}
                       <div className="mb-3" style={{display:"flex",justifyContent:"center"}}>
                        <button id="btnEnviaCorreo" onClick={onEnviarFacturaCorreo} style={{width:"200px"}} className="btn btn btn-primary" type="button"> 
                          <span data-language_txt="txt_EnviarCorreo">Enviar vía correo</span>
                        </button> 
                        <button id="btnDescargaFactura" onClick={onDescargarArchivosFactura} style={{marginLeft:"10px",width:"200px"}} className="btn btn btn-primary" type="button"> 
                          <span data-language_txt="txt_DescargaArchivos">Descargar PDF y XML</span>
                        </button> 
                      </div>
                  
                      {/*Correo */}
                      <div className="mb-3" style={{marginLeft:"10px"}}>
                            <label className="form-label" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Correo electrónico*" : "Email*"}
                              </label>
                              <input onChange={handleCorreoChange}
                                style={{ fontSize: "11px !important", width: "100%" }}
                                className="form-control" required type="email"
                                pattern="^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"                    
                                id="inputCorreoConsultaFactura"  
                              /> 
                      </div>

                      {/*Lista de facturas */}           
                      {(facturasDisponibles !== null || !isUndefined(facturasDisponibles)) && facturasDisponibles.length > 0 ? 
                        (
                          <div 
                            className="fondoEstudiosCita scrollbarCustom"
                            style={{
                              flex:"1",
                              marginBottom: "20px",
                              overflow: "scroll",
                              overflowY: "auto",
                              overflowX: "hidden",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap"}}>
                            {facturasDisponibles.map((item,index) => {
                              return (
                                <FacturaItem
                                  key={index+1}
                                  factura={item} 
                                  value ={item.IdFactura}
                                  checked={revisaItemFacturaSeleccionada(item.IdFactura)} 
                                  setter={(event)=>revisaFactura(item.IdFactura)}
                                ></FacturaItem>
                              );
                            })} 
                        </div>) : 
                        (
                        <div style={{
                            marginBottom: "20px",
                            height: "240px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}>
                          <div className="colorP" style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "16px" }}>
                            {AppManager.translator.current_lang === "es"
                              ? "No hay facturas relacionados con la búsqueda..."
                              : "There are no invoices related to filter search..."}
                          </div>
                        </div>
                      )}   
                     </div>
                    </form> 
                    </div>  
                  </div> 
                  {/*ICONO CARGANDO */}
                  {isloadingFacturas || isloadingEnvioCorreo || isloadingDescargaFactura ?
                    <div style={{background:"#f9fafd",top:"100px",display: "flex",position:"absolute", height:"520px",opacity:"0.7", justifyContent: "center", alignItems: "center", left:"0px", width: "100%"}}>
                    <CircularProgress size={60}></CircularProgress>
                  </div> :
                  <></>
                  }
              </div> 

              {/*FOOTER*/} 
              <div className="card-footer bg-light" style={{height:"70px",display:"inline-flex",justifyContent:"space-between",alignItems:"center"}}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <span style={{marginBottom:"0px !important",height:"25px",overflow:"hidden"}}
                      id="btn_backhomepage"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      {" "}
                      {AppManager.translator.current_lang === "es" ?
                         (isMobile ? "Regresar" :"Regresar a página principal") :
                         (isMobile ? "Back" : "Return to main page")}
                    </span>  
                  </div>
                  {itemIndex === 2 ?
                  <div style={{display:"flex",justifyContent:"center"}}>
                    {isMobile ? 
                     <button id="btnRegresarConsultaF" onClick={onCancelarConsulta} style={{width:"120px"}} className="btn btn btn-primary" type="button"> 
                      <span data-language_txt="txt_Cancelar">Cancelar</span>
                    </button> :
                     <button id="btnRegresarConsultaF" onClick={onCancelarConsulta} style={{width:"200px"}} className="btn btn btn-primary" type="button"> 
                     <span data-language_txt="txt_Cancelar">Cancelar</span>
                    </button>
                    }
                  </div> : <></>
                  }
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalConsultaFacturacion;
