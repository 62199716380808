// @ts-ignore
import { MyUtilities } from "./MyUtilities.ts";
import _, { set } from "lodash";
import Cookies from "js-cookie";

interface RequestToken {
  name: string;
  intentos: number;
}

export class ManagerRequest {
  callbackError: any;
  intentosSedes: number = 0; //Login
  intentosTokens: RequestToken[] = [
    {
      name: "Principal",
      intentos: 0
    }
  ];
  withHeaders: boolean = false;
  withCredentials: boolean = false;

  constructor(callbackError: any, withHeaders: boolean) {
    try {
      this.intentosSedes = 0;
      this.withHeaders = withHeaders;
      this.callbackError = callbackError;
      Cookies.set("intentosTokens", JSON.stringify( [
        {
          name: "Principal",
          intentos: 0
        }
      ]));
    } catch (ex) {}
  }
  _addIntentos(sede: string) {
    if (sede) {
      const tokens=Cookies.get("intentosTokens");
     if(tokens){
      const cpy = _.cloneDeep(JSON.parse(tokens));

      if (cpy && cpy.length !== 0) {
        //Por cada sede
        let filtered = [];
        for (let index = 0; index < cpy.length; index++) {
          const element = cpy[index];
          // console.log("Elemento",element);

          if (element["name"].toLowerCase() === sede.toLowerCase()) {
            filtered = element;
            // console.log("Se detecto ::::::::::",element["name"].toLowerCase());

            element.intentos += 1;
            //  console.log("Intentos de la sede",element.intentos, sede);
            break;
          }
          //Se encontró la sede
        }
        if (filtered.length === 0) {
          //Si no existe la sede en el array, la agregamos
          cpy.push({ name: sede, intentos: 1 });
          //   console.log("Intentos de la sede",1,sede);
        }
        this.intentosTokens = cpy;
        Cookies.set("intentosTokens", JSON.stringify(cpy));
      }
     }
    }
  }
  getIntentos(sede: string) {
    const intentos=Cookies.get("intentosTokens");
    if(intentos){
    const actualState = JSON.parse(intentos).findIndex((item: RequestToken) => {
      return item.name === sede;
    }); //Filtramos los intentos de la sede actual
    if (actualState !== -1) {
      return this.intentosTokens[actualState].intentos;
    }
  }
    return 0;
  }
  resetIntentos(sede: string) {
    if (sede) {
      const intentos=Cookies.get("intentosTokens");
      if(intentos){
        const cpy = _.cloneDeep(JSON.parse(intentos));

      //Por cada sede
      let filtered = [];
      for (let index = 0; index < cpy.length; index++) {
        const element = cpy[index];
        // console.log("Elemento",element);

        if (element["name"].toLowerCase() === sede.toLowerCase()) {
          filtered = element;
          // console.log("Se detecto ::::::::::",element["name"].toLowerCase());

          element.intentos = 0;
          // console.log("Intentos de la sede",element.intentos);
          break;
        }
        //Se encontró la sede
      }

      this.intentosTokens = cpy;
      Cookies.set("intentosTokens", JSON.stringify(cpy));
      }
    }
  }
  postToken(urlServicio: string, usuario: string, password: string, callbackResponse: any, sede: string) {
    try {
      //Ver cantidad de intentos de la sede actual
      $("#circularLogin").css("display", "block");
   

        $("#divErrorData").css("display", "none");
        let xmlHttp = new XMLHttpRequest();
        let url = urlServicio + "Token?username=" + usuario + "&password=" + password;
        xmlHttp.open("POST", url);
        xmlHttp.responseType = "json";
        xmlHttp.withCredentials = this.withCredentials;
        xmlHttp["extraInfo"] = this;
        xmlHttp["url"] = `${urlServicio}HisWebServicios/Portal/ServicioPortal.svc/Token?username=${usuario}&password=${password}`;
        xmlHttp["urlServicio"] = urlServicio;
        xmlHttp["intentos"] = 0;
        xmlHttp["usuario"] = usuario;
        xmlHttp["password"] = password;
        xmlHttp["sede"] = sede;
        xmlHttp["_addIntentos"] = this._addIntentos;
        xmlHttp["getIntentos"] = this.getIntentos;
        xmlHttp["resetIntentos"] = this.resetIntentos;
        xmlHttp["initialTime"] = new Date();
        xmlHttp["resendReq"] =  MyUtilities.Bind(this.postToken, this, null);
        xmlHttp["callbackResponse"] = callbackResponse;
        xmlHttp["callbackError"] = (error: any) => {
          const sede = error["sede"];
       
          this._addIntentos(sede);  // Asegurando el contexto correcto usando una función de flecha
          console.log(`Reintentando para la sede: ${sede}, intento número: ${this.getIntentos(sede)}`);
          
          if (this.getIntentos(sede) < 3) {  // Asegurarse de que no se exceda el límite de reintentos
              this.postToken(urlServicio, usuario, password, callbackResponse, sede);  // Reintento
          } else {
              console.log(`Se alcanzó el máximo de intentos para la sede: ${sede}`);
              $("#circularLogin").css("display", "none");
              $("#divErrorConnection").css("display", "grid")
          }

        };

        xmlHttp.timeout = 15000; // Set timeout to 4 seconds (4000 milliseconds)
        xmlHttp["ontimeout"] = function (this, error) {
        
          this["_addIntentos"](sede);  // Asegurando el contexto correcto usando una función de flecha
          console.log(`Reintentando para la sede: ${sede}, intento número: ${this["getIntentos"](sede)}`);
          
          if (this["getIntentos"](sede) < 3) {  // Asegurarse de que no se exceda el límite de reintentos
            this["resendReq"](urlServicio, usuario, password, callbackResponse, sede);  // Reintento
          } else {
              console.log(`Se alcanzó el máximo de intentos para la sede: ${sede}`);
              $("#circularLogin").css("display", "none");
              $("#divErrorConnection").css("display", "grid");
          }
        };

       
        // xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
        xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
        xmlHttp.onerror = (error: any) => {
            $("#circularLogin").css("display", "none");
            $("#divErrorConnection").css("display", "grid");
        };
        xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
        xmlHttp.send();
      
    } catch (ex) {
      console.log(ex.toString());
      
      $("#circularLogin").css("display", "none");
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postTokenByCredential(urlServicio: string, usuario: string, password: string, credential: Number, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + "TokenByCredential?username=" + usuario + "&password=" + password + "&credencial=" + credential;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp.open("POST", url);
      xmlHttp.responseType = "json";
      xmlHttp["extraInfo"] = this;
      xmlHttp["url"] = url;
      xmlHttp["initialTime"] = new Date();
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["usuario"] = usuario;
      xmlHttp["password"] = password;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postSedes(urlServicio: string, callbackResponse: any) {
    try {
      if (this.intentosSedes < 3) {
        //console.log("Solicitando sedes");

        let xmlHttp = new XMLHttpRequest();
        let url = urlServicio + "ServidoresAdicionales";
        url = "https://portalresultados.cedisa.do/HisWebServicios/Portal/ServicioPortal.svc/ServidoresAdicionales";
        xmlHttp.open("POST", url);
        xmlHttp.withCredentials = this.withCredentials;
        // xmlHttp.setRequestHeader("Token", token);
        xmlHttp["intentos"] = 0;
        xmlHttp["callbackResponse"] = callbackResponse;
        xmlHttp.timeout = 14000; // Set timeout to 4 seconds (4000 milliseconds)
        xmlHttp.ontimeout = function () {};
        xmlHttp["callbackError"] = (error: any) => {
          // document.getElementById("sedesLoaderTxt").innerText = "Intentando de nuevo...";
          //document.getElementById("sedesLoader")!.style.display = "none";
          this.intentosSedes = this.intentosSedes + 1;
          this.postSedes(urlServicio, callbackResponse); //Reintentamos la peticion
        };

        xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
        xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
        xmlHttp.onerror = (error: any) => {
          //  document.getElementById("sedesLoaderTxt").innerText = "Intentando de nuevo...";
          //  document.getElementById("sedesLoader")!.style.display = "none";
          // this.intentosSedes = this.intentosSedes + 1;
          // this.postSedes(urlServicio, callbackResponse); //Reintentamos la peticion
        };
        xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
        xmlHttp.send();
      } else {
        //NO hay mas intentos
        //console.log("No mas IntentosSedes");
        this.resetIntentos("Principal");

        //document.getElementById("sedesLoaderTxt").innerText = "Sin sedes encontradas";
        //document.getElementById("sedesLoader")!.style.display = "none";
      }
    } catch (ex) {
      //  $('#divErrorConnection').css('display','grid');
      // document.getElementById("sedesLoaderTxt").innerText = "Sin sedes encontradas";
      // document.getElementById("sedesLoader")!.style.display = "none";
    }
  }
  postStudies(urlServicio: string, token: string, callbackResponse: any, nameSede: string) {
    try {
      let xmlHttp = new XMLHttpRequest();

      let url = `${urlServicio}ImagingAnalysisListWithToken?Token=${token}`;
      xmlHttp.open("POST", url);
      if (this.withHeaders) {
        xmlHttp.setRequestHeader("Token", token);
      }
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.timeout = 14000;
      // xmlHttp.withCredentials=false;
      xmlHttp["sede"] = nameSede;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postStudiesbyCompany(date: Object, urlServicio: string, token: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + `ImagingAnalysisByEmpresa?inicio=${date["inicio"]}&fin=${date["fin"]}`;
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Token", token);
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  getShareableURL(urlServicio: string, userFolio: string, idEstudio: string, meses: number, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}/AcortaUrl?MiUsuario=1&Tipo=Estudio&Id=${idEstudio}&Meses=${meses}`;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp.open("GET", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postInfoCarteraVencida(urlServicio: string, token: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + "ObtieneInformacionEstadisticaCuentasCobrar";
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Token", token);
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postReporte(urlServicio: string, token: string, callbackResponse: any, folio: string) {
    try {
      let xmlHttp = new XMLHttpRequest();

      let url = urlServicio + `/HisWebServicios/Portal/ServicioPortal.svc/ImagingReportFull?folio=${folio}&Token=${token}`;

      xmlHttp.open("GET", url);
      xmlHttp.responseType = "blob";
      xmlHttp.withCredentials = this.withCredentials;
      if (this.withHeaders) {
        xmlHttp.setRequestHeader("Authorization", `Bearer ${token}`);
        xmlHttp.setRequestHeader("Content-Type", `application/json`);
      }
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["intentos"] = 0;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postEstadoDeCuenta(
    urlServicio: string,
    token: string,
    callbackResponse: any,
    idPlan: string,
    fechaInicio: string,
    fechaFin: string,
    modo: string
  ) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + `EstadoCuentaFacturasPlan?idPlan=${idPlan}&inicio=${fechaInicio}&fin=${fechaFin}&modo=${modo}`;
      xmlHttp.open("POST", url);
      xmlHttp.responseType = "blob";
      xmlHttp.setRequestHeader("Authorization", `Bearer ${token}`);
      xmlHttp.setRequestHeader("Content-Type", `application/json`);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4) {
          if (xmlHttp.status === 200) {
            // La petición se completó con éxito
            var response = xmlHttp.responseText;
            // Hacer algo con la respuesta recibida
            callbackResponse && callbackResponse(response);
          } else {
            // Ocurrió un error durante la petición
            console.error("Error en la petición: " + xmlHttp.status);
          }
        }
      };
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postProfilePaciente(urlServicio: string, token: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + "Paciente";
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Token", token);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postProfileEmpresa(urlServicio: string, token: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = urlServicio + "Empresa";
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Token", token);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postchangePass(urlServicio: string, token: string, correo: string, password: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}/PasswordModify?newPassword=${password}&correo=${correo}&inmediato=1`;
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Token", token);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }
  postforgotPassword(urlServicio: string, correo: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}/PasswordSend?correo=${correo}&inmediato=1`;
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      $("#divErrorConnection").css("display", "grid");
    }
  }

  ///////////////////////////////////
  // METODOS GENERALES DEL RESPONSE
  ///////////////////////////////////

  onXmlHttpResponse() {
    try {
      // console.log("Inicio: " + this["intentos"]);
      if (this["status"] < 200 || this["status"] >= 304) {
        if (this["intentos"] && this["intentos"] < 3) {
          let req = this as unknown as XMLHttpRequest;
          this["intentos"] = this["intentos"] + 1;

          let peticionResend = this["resendReq"];
          peticionResend(req);
        } else {
          let callbackError = this["callbackError"];
          if (callbackError != null) callbackError(this);
          else {
          }
        }
      } else {
        let callbackResponse = this["callbackResponse"];
        if (callbackResponse != null) {
          callbackResponse(this);
        } else {
        }

        /*let fechaFinalCargaImagen = new Date().getTime();
            const respuesta = this["response"];
            respuesta.TokenResult
            if (arrayBuffer) {
                const serieLoader = this["extraInfo"];
                let fechaInicialResponse = new Date(this['time']).getTime();
            }*/
      }
    } catch (e) {
      // console.log("onXmlHttpResponse: " + e);
    }
  }

  reSendRequest(objeto: XMLHttpRequest) {
    let xmlHttp = new XMLHttpRequest();
    let url = objeto["url"];

    xmlHttp.open("POST", url);
    xmlHttp.responseType = "json";

    xmlHttp["extraInfo"] = objeto["extraInfo"];
    xmlHttp["url"] = url;
    xmlHttp["initialTime"] = objeto["initialTime"];
    xmlHttp["callbackResponse"] = objeto["callbackResponse"];
    xmlHttp["callbackError"] = objeto["callbackError"];
    xmlHttp["intentos"] = objeto["intentos"];
    xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
    xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
    xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
    xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
    xmlHttp.send();
  }

  onXmlHttpError() {
    //let callbackError = this['callbackError'];
    //callbackError(this);
  }

  onXmlHttpChange() {
    //let callbackError = this['callbackError'];
    // let xhr = this as unknown as XMLHttpRequest;
    // let text = xhr.response;
    //callbackError(this);
  }

  ///////////////////////////
  // AGENDAR ESTUDIOS
  ///////////////////////////
  getModalidades(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetModalidadesSucursales`;

      xmlHttp.open("POST", url);

      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }
  searchCedulaFromUser(urlServicio: string, folio: string, telefono: string, callbackResponse: any, callbackError: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetPacienteByFolio?folio=${folio}&telefono=${telefono}`;

      xmlHttp.open("POST", url);

      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }
  getEstudiosModalidad(urlServicio: string, modalidad: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetEstudiosModalidad?idModalidad=` + modalidad;

      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  getHorariosModalidad(urlServicio: string, fecha: string, idEstudio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetHorariosDisponiblesModalidad?fecha=` + fecha + `&idEstudio=` + idEstudio;

      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  getSucursales(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetSucursales`;

      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }
  removeCharacters(str: string) {
    if (str.length < 2) {
      // Si la cadena es demasiado corta para remover la segunda y penúltima posición
      return str;
    } else if (str.length === 2) {
      // Si la cadena solo tiene dos caracteres, remover ambos
      return "";
    } else {
      // Remover la segunda y penúltima posición
      return str.substring(0, 1) + str.substring(2, str.length - 2) + str.substring(str.length - 1);
    }
  }
  removeFirstAndLast(str) {
    if (str.length <= 2) {
      // Si la cadena es de dos caracteres o menos, devolver una cadena vacía
      return "";
    } else {
      // Remover el primer y último carácter
      return str.substring(1, str.length - 1);
    }
  }

  setHorariosEstudiosAgenda(urlServicio: string, idPaciente: string, estudios: [], callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();

      let jsonData = JSON.stringify(estudios);
      // jsonData=JSON.stringify(this.removeCharacters(jsonData));

      let url = `${urlServicio}` + `SetSolicitudesEstudios?idPaciente=` + idPaciente + `&estudios=` + jsonData;
      xmlHttp.open("POST", url);
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  setDatosPacienteAgenda(
    urlServicio: string,
    folioPaciente: string,
    idLada: number,
    telefono: string,
    correo: string,
    poliza: string,
    idPlan: number,
    callbackResponse: any
  ) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url =
        `${urlServicio}` +
        `ActualizaPaciente?folio=` +
        folioPaciente +
        `&idPaisLada=` +
        idLada +
        `&telefono=` +
        telefono +
        `&correo=` +
        correo +
        `&poliza=` +
        poliza +
        `&idPlan=` +
        idPlan;
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  getLadasPais(urlServicio: string, callbackResponse: any, callbackError: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoPaisesLada`;

      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  getCatalogoPlanesAseguramiento(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoPlanesAseguramiento`;
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {}
  }

  setNuevaCitaSinPaciente(urlServicio: string, datos: any, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `SetNuevaCitaSinPaciente`;
      xmlHttp.open("POST", url);
      xmlHttp.setRequestHeader("Content-Type", "application/json");
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      //xmlHttp.send();
      xmlHttp.send(datos);
    } catch (ex) {
      console.log(ex);
    }
  }

  ///////////////////////
  // FACTURA
  ///////////////////////

  getFacturaPorFolioMonto(urlServicio: string, folio: string, monto: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetInformacionCuenta?folio=${folio}&monto=${monto}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getCatalogoEntidadesFacturacion(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoEntidades`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getCatalogoMunicipiosFacturacion(urlServicio: string, idEstado: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoMunicipios?idEntidad=${idEstado}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getCatalogoCPFacturacion(urlServicio: string, idMunicipio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoCodigosPostales?idMunicipio=${idMunicipio}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getCatalogoRegimenFiscalFacturacion(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoRegimenFiscal`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getCatalogoCFDIFacturacion(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetCatalogoUsosCFDI`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getTimbrarFactura(
    urlServicio: string,
    folio: string,
    rfc: string,
    razonSocial: string,
    idRegimen: string,
    idEntidad: string,
    idMunicipio: string,
    idCodigoPostal: string,
    usoCFDI: string,
    correo: string,
    callbackResponse: any
  ) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url =
        `${urlServicio}` +
        `TimbrarFactura?folio=${folio}&rfc=${rfc}&razonSocial=${razonSocial}&idRegimen=${idRegimen}&idEntidad=${idEntidad}&idMunicipio=${idMunicipio}&idCodigoPostal=${idCodigoPostal}&usoCFDI=${usoCFDI}&correo=${correo}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getListaFacturas(urlServicio: string, rfc: string, nombrePaciente: string, fechaInicio: string, fechaFin: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url =
        `${urlServicio}` + `GetListaFacturas?rfc=${rfc}&nombrePaciente=${nombrePaciente}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp.withCredentials = this.withCredentials;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getEnviarFacturaCorreo(urlServicio: string, correo: string, idFactura: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `EnviarFacturaCorreo?correo=${correo}&idsFactura=${idFactura}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getDescargarFactura(urlServicio: string, idFactura: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `DescargarArchivosFactura?idsFactura=${idFactura}`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }

  getIdPais(urlServicio: string, callbackResponse: any) {
    try {
      let xmlHttp = new XMLHttpRequest();
      let url = `${urlServicio}` + `GetIdPaisHospital`;
      console.log(url);
      xmlHttp.open("POST", url);
      xmlHttp["callbackResponse"] = callbackResponse;
      xmlHttp["callbackError"] = this.callbackError;
      xmlHttp["intentos"] = 0;
      xmlHttp["resendReq"] = MyUtilities.Bind(this.reSendRequest, this, null);
      xmlHttp.onreadystatechange = MyUtilities.Bind(this.onXmlHttpChange, xmlHttp, null);
      xmlHttp.onerror = MyUtilities.Bind(this.onXmlHttpError, xmlHttp, null);
      xmlHttp.onload = MyUtilities.Bind(this.onXmlHttpResponse, xmlHttp, null);
      xmlHttp.send();
    } catch (ex) {
      console.log(ex);
    }
  }
}
