import React from 'react'
import { useState,useEffect,useContext  } from 'react';
import typeScriptContext from '../../../../AppState/SystemConfig/appContext';
import {CopyToClipboard} from 'react-copy-to-clipboard';
 import CircularProgress from "@mui/material/CircularProgress";

function ShareData({urlBase,userId,idEstudio,closeModal}) {
  const { AppManager } = useContext(typeScriptContext);
  const [meses ,setMeses]=useState("1")
  const [keyCompartible, setURL] = useState("");
  const [statusCopy ,setStatus]=useState(AppManager.translator.current_lang !== "es" ?"Copy URL":"Copiar URL")
  const [colorstate,setColor]=useState("btn-primary")
  const [isloading, setIsLoading] = useState(true);
 
  const setDOMURL = (sharableUrl) => {
    setIsLoading(false);
    setURL(sharableUrl); //Local
  };
  prompt.setDOMURL = setDOMURL;
  useEffect(() => {
    AppManager.translator.changeLabelsTitle();

 
    return () => {
    
    }
  }, [AppManager, AppManager.translator])
  
  useEffect(() => {
    AppManager.translator.changeLabelsTitle();

    AppManager.loadShareableLink(prompt,userId,idEstudio,meses,urlBase+"/WebUltimateGL/Servicio/Stream/Imagenes.svc/")
    document.addEventListener('copy', function(e){
      let data=document.getElementById("textAreaShareData")
   
        document.getElementById("textAreaShareData").select()
        e.clipboardData.setData('text/plain', data.textContent);
        setColor("btn-success")
        setStatus("Copied URL!")
      
        AppManager.translator.changeLabelsTitle();
      e.preventDefault(); // We want our data, not data from any selection, to be written to the clipboard
  });

    return () => {
    
    }
  }, [AppManager, AppManager.translator])
  

const handleCopy=(text, result)=>{
      if(result){
        document.getElementById("textAreaShareData").select()
        setColor("btn-success")
        setStatus("Copied URL!")
      }
      else{
        setColor("btn-warning")
      setStatus("Try Again")
      }
  }
  

  const closeShareData = () => {  
    closeModal() 
    document.getElementById("divShareData").style.display = "none";    
    document.getElementById("divShareData").style.opacity = "0";   
    setStatus("Copy URL")
    setColor("")
  };
  const onChangeSelect = (value)=>{
    AppManager.loadShareableLink(prompt,userId,idEstudio,value.target.value,urlBase+"/WebUltimateGL/Servicio/Stream/Imagenes.svc/")
    setColor("btn-primary")
    setStatus("Copy URL")
    setMeses(value.target.value)
  }
 
 return ( 
  <div id="divShareData" className="modal fade-in" tabIndex="1" role="dialog" aria-hidden="true" style={{backgroundColor:"#0000007a",display:"block"}}>
  <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth:"500px"}}>
    <div className="modal-content position-relative">
      <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
        <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close" onClick={closeShareData}></button>
      </div>
      {isloading? <div style={{height:300,display:"flex",justifyContent:"center",alignItems:"center"}}>
          <CircularProgress></CircularProgress>
        </div>:
      <div className="modal-body p-0">
     
        <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
          <h4 className="mb-1" data-language_txt="txt_ShareTitle">{AppManager.translator.current_lang !== "es" ?"Share link with URL":"Compartir enlace con URL"}</h4>
        </div>

       <div className="p-4 pb-0 row">  
          <div className="col-md-7">
            <label className="form-label" data-language_txt="txt_ShareDescription">{AppManager.translator.current_lang === "es" ?"Para compartir un enlace a este estudio, copie la siguiente URL:":"To share a link to this study, please copy the following URL:"}</label>     
          </div>      
          <div className="col-md-4">
          
            <label className="form-label" htmlFor="validationCustom04" data-language_lbl="lbl_Duration">{AppManager.translator.current_lang !== "es" ?"Duration":"Duración"}</label>
            <select className="form-select" required="" onChange={onChangeSelect} value={meses} style={{width:"11rem"}}>
              <option value="1">{AppManager.translator.current_lang === "es" ? "1 mes": "One month"}</option>
              <option value="3">{AppManager.translator.current_lang === "es" ? "3 meses": "Three months"}</option>
              <option value="6">{AppManager.translator.current_lang === "es" ? "6 meses": "Six months"}</option>              
            </select> 
          </div>
          <textarea  id="textAreaShareData"className='p-4 mt-3 form-control' aria-label="With textarea" value={`${urlBase}WebUltimateGL/App/Vistas/index.html?key=${keyCompartible}`}></textarea>
      </div>
           
      <div className="p-4 pb-0 row">  
          <div>
            <label className="form-label" data-language_txt="txt_ShareLeyend">{AppManager.translator.current_lang === "es" ?"Pulse Ctrl + c o el botón 'Copiar' para copiar al portapapeles.":"Press Ctrl + c or 'Copy' button to copy to clipboard"}</label>     
          </div>
      </div>
      <div className='p-4 pb-0 row'>
        <div className="mb-3">
         <CopyToClipboard text={`${urlBase}WebUltimateGL/App/Vistas/index.html?key=${keyCompartible}`} onCopy={handleCopy}>
            <button data-language_btn="btn_CopyURL" className={`btn ${colorstate} d-block mb-3 me-3 position-absolute bottom-0 end-0`} type="submit" >{statusCopy}</button>
          </CopyToClipboard>
         </div>
      </div>   

      </div> 
}
    </div>
  
   
  </div>
</div>
  ) 
}

export default ShareData