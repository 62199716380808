import React, { useState, useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import typeScriptContext from "../../../../AppState/SystemConfig/appContext";
import InvoiceIcon from '@mui/icons-material/Receipt';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DataUserIcon from '@mui/icons-material/AssignmentInd';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete"; 
import { isUndefined} from "lodash";
import EstudioItem from "./estudioItem";  
import FormControl from '@mui/material/FormControl';
import MsgInfoData from "./msgInfoData"; 
import { Description } from "@mui/icons-material";
import {isMobile} from "react-device-detect";
import { Number } from "core-js";

function ModalFacturacion({ datos,closeModalFacturacion}) {
  const { AppManager, AppConfiguration } = useContext(typeScriptContext);
  const [itemIndex, setItemIndex] = useState(1); 
  const [isLoadingMunicipios, setIsLoadingMunicipios] = useState(false);
  const [isLoadingEntidades, setIsLoadingEntidades] = useState(false);  
  const [isLoadingCP, setIsLoadingCP] = useState(false);  
  const [isLoadingRegimen, setIsLoadingRegimen] = useState(false);  
  const [isLoadingCFDI, setIsLoadingCFDI] = useState(false);    
  const [isLoadingTimbreFactura, setIsLoadingTimbreFactura] = useState(false);    
  const [isloadingEnvioCorreo,setIsLoadingEnvioCorreo] = useState(false); 
  const [isLoadingDescargaFactura, setIsLoadingDescargaFactura] = useState(false);
  const [municipiosDisponibles, setMunicipiosDisponibles] = useState([]); 
  const [entidadesDisponibles, setEntidadesDisponibles] = useState([]); 
  const [codigoPostalDisponibles, setCodigoPostalDisponibles] = useState([]); 
  const [regimenFiscalDisponibles, setRegimenFiscalDisponibles] = useState([]); 
  const [usoCFDIDisponibles, setUsoCFDIDisponibles] = useState([]);  
  const [usoCFDIFiltrados, setUsoCFDIFiltrados] = useState([]);  
  const [idMunicipioSeleccionado, setIdMunicipioSeleccionado] = useState(-1);
  const [idEntidadSeleccionada, setIdEntidadSeleccionada] = useState(-1);
  const [idCPSeleccionado, setIdCPSeleccionado] = useState(-1);
  const [idRegimenSeleccionado, setIdRegimenSeleccionado] = useState(-1);
  const [idCFDISeleccionado, setIdCFDISeleccionado] = useState(-1);
  const [idFacturaGenerada,setIdFacturaGenerada] = useState(-1); 
  const [firstLoad, setFirstLoad] = useState(true);    
   
  const [valueEntidad, setValueEntidad] = useState("");
  const [valueMunicipio, setValueMunicipio] = useState("");
  const [valueCP, setValueCP] = useState("");
  const [valueRegimenFiscal, setValueRegimenFiscal] = useState("");
  const [valueCFDI, setValueCFDI] = useState(""); 
  const [valueRFC, setValueRFC] = useState(""); 
  const [valueCorreo, setValueCorreo] = useState(""); 
  const [valueRazon, setValueRazon] = useState("");    
  const [showBotonCFDI, setShowBotonCFDI] = useState(false);
  const [showBotonDescarga, setShowBotonDescarga] = useState(false); 
  //////////Mensajes de información
  const [isMensaje, setIsMensaje] = useState(false);  
  const [isMensajeError, setIsMensajeError] = useState(false);  
  const [mensaje, setMensaje] = useState(""); 
  const [tituloMensaje, setTituloMensaje] = useState(""); 
  //////////Propiedades de validacion
  const[classEmailValid,setClassEmailValid] = useState("");
  const[classCFDIValid,setClassCFDIValid] = useState("");
  const[classCPValid,setClassCPValid] = useState("");
  const[classMunicipioValid,setClassMunicipioValid] = useState("");
  const[classEntidadValid,setClassEntidadValid] = useState("");
  const[classRFCValid,setClassRFCValid] = useState("");
  const[classRegimenValid,setClassRegimenValid] = useState("");
  const[classRazonValid,setClassRazonValid] = useState("");


  ////////////////////
  // COMBO BOX
  ////////////////////
  const ComboSelect = () => {
    return (
      <Stack spacing={2}>
        <Autocomplete
          id="cmbSelectStudy"
    
          onInputChange={(event, value) => { 
            if (value !== null) {
              var datosEstudio = estudiosPorModalidad.find((e) => e.Descripcion === value);
              var existeItem =
                estudiosSeleccionados !== null && estudiosSeleccionados.length > 0
                  ? estudiosSeleccionados.find((s) => s.Descripcion === value)
                  : null;
              if (!isUndefined(datosEstudio)) {
                if (existeItem === null || isUndefined(existeItem)) {
                  setEstudiosSeleccionados([
                    ...estudiosSeleccionados,
                    {
                      idEstudio: datosEstudio.idEstudio,
                      Descripcion: datosEstudio.Descripcion,
                      Preparacion: datosEstudio.Preparacion,
                      Precio: datosEstudio.Precio,
                      Descuento: datosEstudio.Descuento,
                      modalidad: datosEstudio.Modalidad,
                    },
                  ]);
                  if (AppManager.isDebug) console.log(estudiosSeleccionados);
                }
              }
            }
          }}
          options={estudiosPorModalidad.map((option) => option.Descripcion)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    );
  }; 
   
  const ComboSelectEntidades = () => {
    if(isLoadingEntidades){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete className={`MuiOutlinedInput-root ${classEntidadValid}`}  
        freeSolo 
        value={valueEntidad}
        style={{ minWidth:200}}
        id="cmbSelectEntidades"
        onChange={(event, value) => { 
        asignaEntidadFactura(value); 
       }}
        onInputChange={(event, valueInput) => {
        }}
        options={entidadesDisponibles.map((option) => option.Valor)}
        renderInput={(params) => <TextField {...params} placeholder={AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };

  const ComboSelectMunicipio = () => {
    if(isLoadingMunicipios){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete className={`MuiOutlinedInput-root ${classMunicipioValid}`}  
        freeSolo
        value={valueMunicipio}
        style={{minWidth: 200 }}
        id="cmbSelectMunicipio"
        onChange={(event, value) => {
          asignaMunicipioFactura(value);
        }}
        onInputChange={(event, valueInput) => {         
        }}
        options={municipiosDisponibles.map((option) => option.Valor)}
        renderInput={(params) => <TextField {...params} placeholder={AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };

  const ComboSelectCP = () => {
    if(isLoadingCP){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete className={`MuiOutlinedInput-root ${classCPValid}`}  
        freeSolo
        value={valueCP} 
        style={{minWidth: 200 }}
        id="cmbSelectCP"
        onChange={(event, value) => {
          asignaCPFactura(value);
        }}
        onInputChange={(event, valueInput) => {         
        }}
        options={codigoPostalDisponibles.map((option) => option.Valor)}
        renderInput={(params) => <TextField {...params} placeholder={AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };

  const ComboSelectRegimen = () => {
    if(isLoadingRegimen){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete className={`MuiOutlinedInput-root ${classRegimenValid}`}  
        style={{minWidth: 250 }}
        freeSolo
        value={valueRegimenFiscal} 
        id="cmbSelectRegimen"
        onChange={(event, value) => {
          asignaRegimenFactura(value);
        }}
        onInputChange={(event, valueInput) => {         
        }}
        options={regimenFiscalDisponibles.map((option) => option.Valor)}
        renderInput={(params) => <TextField {...params} placeholder={AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };

  const ComboSelectCFDI = () => {
    if(isLoadingCFDI){
      return <CircularProgress size={20}></CircularProgress>
    }
    return (
      <Autocomplete className={`MuiOutlinedInput-root ${classCFDIValid}`}  
        style={{minWidth: 250,border:"0px" }}
        freeSolo
        value={valueCFDI} 
        id="cmbSelectUsoCFDI"
        onChange={(event, value) => {
          asignaUsoCFDI(value);
        }}
        onInputChange={(event, valueInput) => {         
        }}
        options={usoCFDIFiltrados.map((option) => option.Valor)}
        renderInput={(params) => <TextField {...params} placeholder={AppManager.translator.current_lang === "es" ? "Buscar..." :"Search..."}/>}
      />
    );
  };
 
  //////////////////////
  // RESULTADOS PROMPT
  //////////////////////  

  prompt.setCatalogoEntidades = (data) => {
    try {
      setEntidadesDisponibles([]);
      setIsLoadingEntidades(false);
      if (data.Data !== undefined && data.Data.length > 0) {   
        setEntidadesDisponibles(data.Data);   
      }
    } catch (e) {
      setIsLoadingEntidades(false); 
    }
  };

  prompt.setCatalogoMunicipios = (data) => {
    try {
      setMunicipiosDisponibles([]);
      setIsLoadingMunicipios(false);
      if (data.Data !== undefined && data.Data.length > 0) {   
        setMunicipiosDisponibles(data.Data);        
      }
    } catch (e) {
      setIsLoadingMunicipios(false); 
    }
  };

  prompt.setCatalogoCP = (data) => {
    try {
      setCodigoPostalDisponibles([]);
      setIsLoadingCP(false);
      if (data.Data !== undefined && data.Data.length > 0) {   
        setCodigoPostalDisponibles(data.Data);        
      }
    } catch (e) {
      setIsLoadingCP(false); 
    }
  };

  prompt.setCatalogoRegimen = (data) => {
    try {
      setRegimenFiscalDisponibles([]);
      setIsLoadingRegimen(false);
      if (data.Data !== undefined && data.Data.length > 0) {   
        setRegimenFiscalDisponibles(data.Data);        
      }
    } catch (e) {
      setIsLoadingRegimen(false); 
    }
  };

  prompt.setCatalogoCFDI = (data) => {
    try {
      setUsoCFDIDisponibles([]);
      setUsoCFDIFiltrados([]);
      setIsLoadingCFDI(false);
      if (data.Data !== undefined && data.Data.length > 0) {   
        setUsoCFDIDisponibles(data.Data);     
      }
    } catch (e) {
      setIsLoadingCFDI(false); 
    }
  };

 prompt.setTimbrarFactura = (data) => {
  try {
    setIsLoadingTimbreFactura(false);
    if (data.Data !== undefined && data.Data !== null) {   
      setIdFacturaGenerada(data.Data.IdFactura);  
      pintaNextItem(3);    
      setShowBotonDescarga(true);  
    }else if(data.Error !== null && data.Error.Message !==null){ 
      if(AppManager.translator.current_lang === "es")
        onShowMensaje("Error en datos",data.Error.Message,true); 
      else
        onShowMensaje("Data error",data.Error.Message,true); 
    }
  }catch(e){
    console.log('TimbrarFactura' + e);
  }
 } 

 prompt.setResultadoCorreoFactura=(data) =>{
  try {  
    setIsLoadingEnvioCorreo(false);
    if (data.Data !== undefined && data.Data !== null) {   
         if(data.Data.Success == true){      
          if(AppManager.translator.current_lang === "es")         
            onShowMensaje("Envío exitoso","Se ha enviado el correo...",false); 
          else
            onShowMensaje("Successful email sending","The email has been sent...",false); 
         }else if(data.Data.Error !== null && data.Data.Error !== ""){ 
          if(AppManager.translator.current_lang === "es")
            onShowMensaje("Error en datos",data.Data.Error.Message,true); 
          else
            onShowMensaje("Data error",data.Data.Error.Message,true); 
         }
         else{
          if(AppManager.translator.current_lang === "es")
            onShowMensaje("Error","Ocurrió un error al enviar la factura al correo...",true); 
          else
            onShowMensaje("Error","An error occurred while sending the invoice to the email...",true); 
         }
    }else{ 
      if(AppManager.translator.current_lang === "es")
          onShowMensaje("Error","Ocurrió un error al enviar la factura al correo...",true); 
      else
          onShowMensaje("Error","An error occurred while sending the invoice to the email...",true);        
    }
    } catch (e) { 
      console.log('setResultadoCorreoFactura' + e);
    }
  }

  prompt.setResultadoDescargaFactura=(data) =>{
    try{
        setIsLoadingDescargaFactura(false);
        if (data.Data !== undefined && data.Data !== null) { 
          if (data.Data !== undefined && data.Data !== null) { 
            var fileName = 'Files' + Date.now() + '_' + idFacturaGenerada + '.zip';
            var base64String = Uint8Array.from(window.atob(data.Data), (v) => v.charCodeAt(0));
            var blob = new Blob([ base64String ], {type:"application/zip"});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url); 
            if(AppManager.translator.current_lang === "es")
              onShowMensaje("Descarga exitoso","Se ha descargado la factura...",false); 
            else
              onShowMensaje("Successful download","The invoice has been downloaded!",false);  
        }else if(data.Error !== null && data.Error !== ""){ 
          if(AppManager.translator.current_lang === "es")         
            onShowMensaje("Error en descarga",data.Error.Message,true); 
          else
            onShowMensaje("Download error",data.Error.Message,true); 
        }else{
          if(AppManager.translator.current_lang === "es")         
            onShowMensaje("Error","Ocurrió un error al descargar los archivos de la factura...",true); 
          else
            onShowMensaje("Error","An error occurred while downloading the invoice files...",true);                    
        }
      }
    }
    catch(e){
      console.log('setResultadoDescargaFactura' + e);
    }
  }
 
  /////////////////////
  // TAB ITEMS
  /////////////////////

  const changeTabNext = () => {
    try {
      setMensaje("");
      var numItem = itemIndex + 1;
      switch (itemIndex) {
        //DATOS DE ENTRADA
        case 1: {
          pintaNextItem(numItem);            
          //Se obtendran los catálogos
          setIsLoadingEntidades(true);
          AppManager.getCatalogosEntidades(prompt); 
          setIsLoadingRegimen(true);
          AppManager.getCatalogoRegimenFiscal(prompt);
          setIsLoadingCFDI(true);
          AppManager.getCatalogoUsoCFDI(prompt);          
          setShowBotonCFDI(true);           
          break;
        } 
      }

      if (numItem < 4) {
        pintaNextItem(numItem);
      } else {
        setItemIndex(3);
      }

    } catch (e) {
      // console.log('changeTabNext' + e);
    }
  };

  const pintaNextItem = (numItem) => {
    try {
      //Item previo
      var item = document.getElementById("tabItem" + (numItem - 1) + "_Factura");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0";
      }

      //Siguiente item
      item = document.getElementById("tabItem" + numItem + "_Factura");
      if (item !== null) {
        item.style.display = "block";
        item.style.opacity = "1";
      }
    
      var itemMenuActivo = document.getElementById("Paso" + numItem + "_Factura_1");
      var itemMenuInactivo = document.getElementById("Paso" + numItem + "_Factura_0");
      if (itemMenuActivo !== null) itemMenuActivo.style.display = "block";
      if (itemMenuInactivo !== null) itemMenuInactivo.style.display = "none";

      setItemIndex(numItem);
  
    } catch (e) {
      // console.log('pintaNextItem' + e);
    }
  };

  const changeTabPrev = () => {
    var numItem = itemIndex - 1;
    setItemIndex(numItem);
    setMensaje("");
    setShowBotonCFDI(false);
    setShowBotonDescarga(false);      
    if (numItem > 0) {
      //Item next
      var item = document.getElementById("tabItem" + itemIndex + "_Factura");
      if (item !== null) {
        item.style.display = "none";
        item.style.opacity = "0";
      }

      //Item actual
      item = document.getElementById("tabItem" + numItem + "_Factura");
      if (item !== null) {
        item.style.display = "block";
        item.style.opacity = "1";
      }

      var itemMenuActivo = document.getElementById("Paso" + itemIndex + "_Factura_1");
      var itemMenuInactivo = document.getElementById("Paso" + itemIndex + "_Factura_0");

      if (itemMenuActivo != null) itemMenuActivo.style.display = "none";
      if (itemMenuInactivo !== null) itemMenuInactivo.style.display = "block";
    } else {
      setItemIndex(1);
    } 
  }; 

  ///////////////////////////
  //  METODOS
  ///////////////////////////
  
  function onShowMensaje(titulo,mensaje,error){
    try{  
          //////////////////
          //Reset mensaje
          setIsMensaje(false);
          setMensaje("");
          setIsMensajeError(false);
          setTituloMensaje("");

          ////////////////
          //Agrega datos al mensaje
          setIsMensaje(true);
          setMensaje(mensaje);
          setIsMensajeError(error);
          setTituloMensaje(titulo);
    }
    catch(e){
      console.log(e);
    }
  }

  function onTimbraFactura(){
  try{ 
          //Validar los datos de entrada, los obligatorios  
          var rfc = $("#inputRFC")[0].value;  
          var razon = $("#inputRazon")[0].value;  
          var correo = $("#inputCorreoFactura")[0].value;    
          var validator = $('#inputCorreoFactura')[0].validity.valid;  
 
          if(idCFDISeleccionado == -1){
            setClassCFDIValid('is-invalid');
          }else{
            setClassCFDIValid('is-valid');
          }

          if(idCPSeleccionado == -1){
            setClassCPValid('is-invalid');
          }else{
            setClassCPValid('is-valid');
          }

          if(idEntidadSeleccionada == -1){
            setClassEntidadValid('is-invalid');
          }else{
            setClassEntidadValid('is-valid');
          }

          if(idMunicipioSeleccionado == -1){
            setClassMunicipioValid('is-invalid');
          }else{
            setClassMunicipioValid('is-valid');
          }

          if(idRegimenSeleccionado == -1){
            setClassRegimenValid('is-invalid');
          }else{
            setClassRegimenValid('is-valid');
          }

          if(rfc ===""){
            setClassRFCValid('is-invalid');
          }else{
            setClassRFCValid('is-valid');
          }
          
          if(razon ===""){
            setClassRazonValid('is-invalid');
          }else{
            setClassRazonValid('is-valid');
          }

          if(correo ==="" || !validator){
            setClassEmailValid('is-invalid');
          }else{
            setClassEmailValid('is-valid');
          }

          if(idCFDISeleccionado == -1 || idCPSeleccionado === -1 || idEntidadSeleccionada === -1 ||
            idMunicipioSeleccionado=== -1 || idRegimenSeleccionado === -1 || rfc === "" || razon === "" ||
            correo === "" || !validator){  
              if(AppManager.translator.current_lang === "es")
                onShowMensaje("Información","Los datos proporcionados son incorrectos!",false); 
              else
                onShowMensaje("Information","The data entered is incorrect!",false); 
              return;
            } 
          setValueRFC(rfc);
          setValueCorreo(correo);
          setValueRazon(razon);
          setIsLoadingTimbreFactura(true);
          AppManager.getTimbrarFactura(datos.Folio,rfc,razon,idRegimenSeleccionado,idEntidadSeleccionada,
            idMunicipioSeleccionado,idCPSeleccionado,idCFDISeleccionado,correo,prompt);  
  }
  catch(e){
    console.log(e);
  }
  }

  function onEnviarFacturaCorreo(){
      try
      {
        setIsLoadingEnvioCorreo(true);
        AppManager.getEnviarCorreoFactura(valueCorreo,idFacturaGenerada,prompt);
      }
      catch(e){
        console.log(e);
      } 
    }

    function onDescargarArchivosFactura(){
      try{  
        if (idFacturaGenerada == -1){
          if(AppManager.translator.current_lang === "es")
            onShowMensaje("Información","Seleccione una factura!",false);  
          else
            onShowMensaje("Information","Select an invoice!",false);   
        }else{
          setIsLoadingDescargaFactura(true);
          AppManager.getDescargaArchivosFactura(idFacturaGenerada,prompt);
        } 
      }
      catch(e){
        console.log('onDescargarArchivosFactura' + e);
      }
  }

  function asignaEntidadFactura(valor){
    try{
        if(valor !== null){ 
          setValueEntidad(valor); 
          var existeItem = entidadesDisponibles.filter(x=>x.Valor == valor);
          if(existeItem !== null)
          { 
            setIdEntidadSeleccionada(existeItem[0].Id);
            ////////////////////
            //Get Municipios
            setIsLoadingMunicipios(true);
            AppManager.getCatalogosMunicipios(existeItem[0].Id,prompt); 
            if (AppManager.isDebug) console.log("Entidad id seleccionada: " + existeItem[0].Id);
            setClassEntidadValid("is-valid");
          }
        }else{           
            setValueEntidad("");
            setIdEntidadSeleccionada(-1);   
            setValueMunicipio("");
            setIdMunicipioSeleccionado(-1);
            setMunicipiosDisponibles([]);   
            setValueCP("");
            setIdCPSeleccionado(-1);
            setCodigoPostalDisponibles([]);  
            setClassEntidadValid("is-invalid");
        }    
      }
      catch(e){
      }
  }

  function asignaMunicipioFactura(valor){ 
    try{
      if(valor !== null){ 
        setValueMunicipio(valor); 
        var existeItem = municipiosDisponibles.filter(x=>x.Valor == valor);
        if(existeItem !== null)
        { 
          setIdMunicipioSeleccionado(existeItem[0].Id); 
          ///////////////////
          //Get Códigos postales
          setIsLoadingCP(true);
          AppManager.getCatalogosCP(existeItem[0].Id,prompt);
          if (AppManager.isDebug) console.log("Municipio id seleccionada: " + existeItem[0].Id);
          setClassMunicipioValid("is-valid");
        }
      }else{           
          setValueMunicipio("");
          setIdMunicipioSeleccionado(-1);    
          setValueCP("");
          setIdCPSeleccionado(-1);
          setClassMunicipioValid("is-invalid");
      }    
    }
    catch(e){
    }
  }

  function asignaCPFactura(valor){ 
    try{
      if(valor !== null){ 
        setValueCP(valor); 
        var existeItem = codigoPostalDisponibles.filter(x=>x.Valor == valor);
        if(existeItem !== null)
        { 
          setIdCPSeleccionado(existeItem[0].Id);  
          setClassCPValid("is-valid");
        }
      }else{           
          setValueCP("");
          setIdCPSeleccionado(-1);    
          setClassCPValid("is-invalid");
      }    
    }
    catch(e){
    }
  }  

  function asignaRegimenFactura(valor){ 
    try{
      if(valor !== null){ 
        setValueRegimenFiscal(valor); 
        var existeItem = regimenFiscalDisponibles.filter(x=>x.Valor == valor);
        if(existeItem !== null)
        { 
          setIdRegimenSeleccionado(existeItem[0].Id);  
          setClassRegimenValid("is-valid");
          //Filtra uso de CFDI
          if (AppManager.isDebug) {
            console.log('****** REGIMEN Fiscal *******');
            console.log(existeItem[0].Valor2); 
          }
          if(existeItem[0].Valor2 !==null){
            //TODOS
            if(Number(existeItem[0].Valor2) ===3){
              setUsoCFDIFiltrados(usoCFDIDisponibles);
            }else{
              //FILTRA POR TIPO
              var usosCFDI = usoCFDIDisponibles.filter(x=>x.ValorId === Number(existeItem[0].Valor2) || x.ValorId ===3);
              setUsoCFDIFiltrados(usosCFDI);
              if (AppManager.isDebug) {
                console.log('****** CFDI Filtrados *******');
                usosCFDI.map((item)=>{
                  console.log(item.Id + '_' + item.Valor + '_' + item.ValorId);
                });   
              }
            }         
          }
        }
      }else{           
          setValueRegimenFiscal("");
          setIdRegimenSeleccionado(-1);    
          setClassRegimenValid("is-invalid");
          setUsoCFDIFiltrados([]);
          //Reset valor CFDI
          setValueCFDI("");
          setIdCFDISeleccionado(-1);    
          setClassCFDIValid("is-invalid");
      }    
    }
    catch(e){
    }
  }

  function asignaUsoCFDI(valor){ 
    try{
      if(valor !== null){ 
        setValueCFDI(valor); 
        var existeItem = usoCFDIFiltrados.filter(x=>x.Valor == valor);
        if(existeItem !== null)
        { 
          setIdCFDISeleccionado(existeItem[0].Id);  
          setClassCFDIValid("is-valid");
        }
      }else{           
          setValueCFDI("");
          setIdCFDISeleccionado(-1);    
          setClassCFDIValid("is-invalid");
      }    
    }
    catch(e){
    }
  }

  const onChangeTextBoxRFC  = (event) => {
    try{
      //console.log('Text change rfc: ' + event.target.value);
      if(event.target.value !== "" && event.target.value.length>11)
        setClassRFCValid("is-valid"); 
      else
        setClassRFCValid("is-invalid"); 
    }
    catch(e){
      console.log(e);
    }
  }

  const onChangeTextBoxEmail  = (event) => {
    try{
      //console.log('Text change email: ' + event.target.value);
      if(event.target.value !== "")
        setClassEmailValid("is-valid"); 
      else
        setClassEmailValid("is-invalid"); 
    }
    catch(e){
      console.log(e);
    }
  }

  
  const onChangeTextBoxRazonSocial = (event) => {
    try{
      //console.log('Text change razon social: ' + event.target.value);
      if(event.target.value !== "")
        setClassRazonValid("is-valid"); 
      else
        setClassRazonValid("is-invalid"); 
    }
    catch(e){
      console.log(e);
    }
  }
 
  ////////////////
  // USE EFFECT
  ////////////////

  useEffect(() => {
     AppManager.translator.changeLabelsTitle();

    if (AppManager.isDebug) { 
    }
    if (firstLoad) {
      try {
        setFirstLoad(false); 
      } catch (e) {
        // console.log(e);
      }
    }
    return () => {};
  });

  return (
    <div className={AppConfiguration.theme}>
      <MsgInfoData isOpen={isMensaje} mensaje={mensaje} titulo={tituloMensaje} error={isMensajeError} close={() => setIsMensaje(false)}></MsgInfoData>
      <div
        id="divModalFacturacion"
        className="modal container-fluid fade-in top-50 position-absolute start-50 translate-middle"
        style={{
          zIndex: 1,
          display: "block",
          width: "100vw",
          height: "100vh",
        }}>
        <div className="row justify-content-center pt-6" style={{ alignItems: "center", height: "100%" }}>
          <div className="col-sm-10 col-lg-9 col-xxl-8">
            <div className="card theme-wizard mb-5" id="wizard">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                id="btnCloseModalFactura"
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  onClick={closeModalFacturacion}
                  aria-label="Close"
                ></button>
              </div>
              <div className="card-header bg-light pt-3 pb-2">
                <ul className="nav justify-content-between">
                  <li className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-cir">
                        <InvoiceIcon></InvoiceIcon>
                        </span>
                      </span>
                      <span data-language_txt="txt_Facturacion" className="d-md-block mt-1 fs--1 colorP">
                        Facturación electrónica
                      </span>
                    </a>
                  </li>
                </ul>
                <ul className="nav justify-content-between">
                  {/* Inactivos*/}
                  <li id="Paso1_Factura_1" className="nav-item">
                    <a className="nav-link active fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span id="Paso1_Factura" className="nav-item-Check">
                        <DataUserIcon></DataUserIcon>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {" "}
                        {AppManager.translator.current_lang === "es" ? "Confirmar Datos" : "Confirm Data"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso2_Factura_0" className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-UnCheck">
                          <PriceCheckIcon></PriceCheckIcon>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1"> 
                        {AppManager.translator.current_lang === "es" ? "Generar factura" : "Generate invoice"}
                      </span>
                    </a>
                  </li>
                  <li id="Paso2_Factura_1" style={{ display: "none" }} className="nav-item">
                    <a className="nav-link fw-semi-bold">
                      <span className="nav-item-circle-parent">
                        <span className="nav-item-Check">
                        <PriceCheckIcon></PriceCheckIcon>
                        </span>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">   
                        {AppManager.translator.current_lang === "es" ? "Generar factura" : "Generate invoice"}
                    </span>
                    </a>
                  </li> 
                </ul>
              </div>
              
                <div className="card-body" style={{ padding: "0px !important" }}>
                  {/*CONTENIDO*/}
                  <div className="tab-content" style={{ height: "420px" }}>
                    {/* ********************* */}
                    {/* PASO 1 CONFIRMA DATOS */}
                    {/* ********************* */}
                    <div id="tabItem1_Factura" style={{padding:0}} className="tab-pane container active px-sm-0 scrollbarCustom" role="tabpanel">
                      <form className="needs-validation " noValidate>
                        {/*Folio*/}
                        <div className="mb-3">
                          <label className="form-label" htmlFor="folio">
                          {AppManager.translator.current_lang === "es" ? "Folio" : "Transaction ID"} 
                          </label>
                          <input
                            style={{ fontSize: "11px !important" }}
                            className="form-control"
                            name="folio" disabled={true}                            
                            readOnly
                            value={datos.Folio} 
                            id="inputFolio"  
                          />
                         </div>

                        {/*nombre*/}
                          <div className="mb-3 " style={{ display: "flex", flexDirection:"column", justifyContent: "" }}> 
                              <label className="form-label" htmlFor="nombre">
                              {AppManager.translator.current_lang === "es" ?  "Nombre del paciente:" : "Patient Name:"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important", width: "100%" }}
                                className="form-control" disabled={true} 
                                name="nombre" readOnly                             
                                id="nombre" 
                                defaultValue={datos.NombrePaciente}/> 
                          </div> 

                        {/*estudios*/}                        
                        {(datos.ListaEstudios !== null || !isUndefined(datos.ListaEstudios)) && datos.ListaEstudios.length > 0 ? 
                        (
                          <div style={{ height: "270px",display:"flex",flexDirection:"column"}}>
                          <div 
                            className="fondoEstudiosCita scrollbarCustom"
                            style={{
                              height: "270px",
                              flex:"1",
                              marginBottom: "10px",
                              overflow: "scroll",
                              overflowY: "auto",
                              overflowX: "hidden",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap"}}>
                            {datos.ListaEstudios.map((item,index) => {
                              return (
                                <EstudioItem
                                  key={index+1}
                                  estudio={item} 
                                ></EstudioItem>
                              );
                            })} 
                        </div> 
                        {/*total*/}
                        <div style={{ display: "flex",height:"90px",justifyContent: "space-between", width: "100%",flexWrap:"wrap"}}>
                          <div className="col" style={{display: "flex",alignItems:"center"}}> 
                            <div className="mb-2 mt-2" style={{ display: "inline-flex",justifyContent:"center"}}>
                            {isMobile ? 
                            <label style={{fontWeight:"bold",marginBottom: "0px",color:"red",maxHeight:"40px",fontSize:"10px",overflow:"hidden"}}>
                            {AppManager.translator.current_lang === "es" ?
                              "**Si los datos no coinciden con su búsqueda, el folio y el monto son incorrectos**" :
                            "**If the data does not match your search, the folio and amount are incorrect**"}</label> :
                            <label style={{fontWeight:"bold",marginBottom: "0px",color:"red",maxHeight:"40px",overflow:"hidden"}}>
                            {AppManager.translator.current_lang === "es" ?
                              "**Si los datos no coinciden con su búsqueda, el folio y el monto son incorrectos**" :
                            "**If the data does not match your search, the folio and amount are incorrect**"}</label>
                            }
                            </div>
                          </div>
                          <div className="col" style={{scale: "0.9",transformOrigin:"top right",display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <div style={{ display: "inline-flex" }}>
                              <label style={{color: "#1864AB", marginBottom:"0px",textOverflow:"ellipsis",textWrap:"nowrap"}}>SubTotal: $</label>
                              <label style={{color: "#1864AB", fontWeight: "bold",textOverflow:"ellipsis",textWrap:"nowrap",marginBottom: "0px",textAlign:"right",width:"70px"}}>
                                {datos.Subtotal.toFixed(2)}
                              </label>
                            </div>
                            <div style={{ display: "inline-flex" }}>
                              <label style={{color:"green", marginBottom: "0px",textOverflow:"ellipsis",textWrap:"nowrap"}}>
                                 IVA: $
                              </label>
                              <label style={{color:"green", fontWeight: "bold",textOverflow:"ellipsis",textWrap:"nowrap",marginBottom: "0px",textAlign:"right",width:"70px"}}>
                                {datos.IVA.toFixed(2)}
                              </label>
                            </div>
                            { datos.Reembolso > 0 ?
                            <div style={{ display: "inline-flex" }}>
                              <label data-language_lbl="lbl_Reembolso" style={{color: "#DA3B01", marginBottom:"0px",textOverflow:"ellipsis",textWrap:"nowrap"}}>- Reembolso: $</label>
                              <label style={{color: "#DA3B01", fontWeight: "bold",textOverflow:"ellipsis",textWrap:"nowrap",marginBottom: "0px",textAlign:"right",width:"70px"}}>
                                {datos.Reembolso.toFixed(2)}
                              </label>
                            </div> : <></> }
                            {datos.Descuento > 0  ?
                            <div style={{ display: "inline-flex" }}>
                              <label data-language_lbl="lbl_Descuento" style={{color: "#DA3B01", marginBottom:"0px",textOverflow:"ellipsis",textWrap:"nowrap"}}>- Descuento: $</label>
                              <label style={{color: "#DA3B01", fontWeight: "bold",textOverflow:"ellipsis",textWrap:"nowrap",marginBottom: "0px",textAlign:"right",width:"70px"}}>
                                {datos.Descuento.toFixed(2)}
                              </label>
                            </div> : <></> }
                            <div style={{ display: "inline-flex" }}>
                               <label style={{ color: "black", fontWeight: "bold", marginBottom: "0px" }}>Total: $</label>
                               <label style={{ color: "black", fontWeight: "bold", marginBottom: "0px",textAlign:"right",width:"70px"}}>
                                  {datos.Total.toFixed(2)}
                               </label>
                            </div>
                          </div>
                        </div>                        
                       
                       </div>) : 
                        (
                        <div style={{
                            marginBottom: "20px",
                            height: "240px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}>
                          <div className="colorP" style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "16px" }}>
                            {AppManager.translator.current_lang === "es"
                              ? "No hay estudios relacionados con el folio de la factura..."
                              : "There are no studies related to the invoice ID..."}
                          </div>
                        </div>
                      )}  
                                  
                    </form>
                    </div>
                    {/* ************************** */}
                    {/*   PASO 2 GENERA FACTURA    */}
                    {/* ************************** */}
                    <div
                      id="tabItem2_Factura"
                      className="tab-pane container fade px-sm-0 scrollbarCustom"
                      style={{ height: "420px", overflow: "scroll", overflowY: "auto",overflowX:"hidden",padding:"0px"}}
                      role="tabpanel">
                      <form style={{paddingLeft:"5px",paddingRight:"5px"}} className="needs-validation " noValidate>
                        <div  className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*Folio*/}
                            <div className="col" style={{marginLeft:"5px"}}> 
                            <label className="form-label" htmlFor="folio" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ? "Folio*" : "Transaction ID*"} 
                            </label>
                            <input
                              style={{ fontSize: "11px !important",opacity:"0.7",minWidth:200}}
                              className="form-control"
                              name="folioNext"
                              readOnly
                              value={datos.Folio} 
                              id="inputFolioFactura"  
                            />
                            </div>
                            {/*Nombre*/}
                            <div className="col" style={{marginLeft:"5px"}}>
                            <label className="form-label" htmlFor="nombre" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Nombre*" : "Name*"}
                              </label>
                              <input
                                style={{ fontSize: "11px !important", width: "100%",opacity:"0.7",minWidth:200}}
                                className="form-control"
                                name="nombreNext" readOnly                             
                                id="inputNombreNext" 
                                value={datos.NombrePaciente}
                              /> 
                            </div>
                          </div>

                          <div  className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*RFC*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}> 
                              <label className="form-label" htmlFor="RFC" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "RFC*" : "Tax ID*"}
                              </label>
                              <input onChange={onChangeTextBoxRFC}
                                style={{ fontSize: "11px !important", width: "100%",height:"38px",minWidth:200}}
                                className={`form-control ${classRFCValid}`}  
                                name="RFC"                               
                                id="inputRFC"  
                              /> 
                            </div>
                            {/*Régimen Fiscal*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}>
                            <label className="form-label" htmlFor="RegimenFiscal" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Régimen Fiscal*" : "Tax Regime*"}
                              </label>
                              <FormControl size="small">
                                <ComboSelectRegimen required></ComboSelectRegimen>
                              </FormControl>
                            </div>
                          </div>
                        {/*Razón social*/}
                        <div className="mb-1" style={{marginLeft:"5px"}}>
                          <label className="form-label" htmlFor="razon" style={{marginBottom:"0px"}}>
                          {AppManager.translator.current_lang === "es" ? "Razón social*" : "Name/Company Name*"} 
                          </label>
                          <input onChange={onChangeTextBoxRazonSocial}
                            style={{ fontSize: "11px !important" }}
                            className={`form-control ${classRazonValid}`}  
                            name="razon"
                            required  
                            id="inputRazon"
                          />
                         </div>
                         
                         <div className="mb-1 row" style={{display:"flex",flexDirection:"row", alignItems:"center",flexWrap:"wrap"}}>
                            {/*estado*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}> 
                              <label className="form-label" htmlFor="estado" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Estado*" : "State*"}
                              </label>  
                              <FormControl size="small">                             
                              <ComboSelectEntidades required></ComboSelectEntidades>
                              </FormControl>
                            </div>
                            {/*municipio*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}>
                            <label className="form-label" htmlFor="municipio" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Municipio*" : "Town*"}
                              </label>
                              <FormControl size="small">
                                <ComboSelectMunicipio required></ComboSelectMunicipio>
                              </FormControl>
                            </div>
                             {/*cp*/}
                             <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}>
                            <label className="form-label" htmlFor="codigo" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Código Postal*" : "Postal Code*"}
                              </label>
                              <FormControl size="small">
                              <ComboSelectCP required></ComboSelectCP>
                              </FormControl>
                            </div>
                          </div>
                          
                         <div className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*Uso del CFDI*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex", flexDirection:"column"}}>
                              <label className="form-label" htmlFor="CFDI" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "CFDI*" : "Digital Tax Invoice*"}
                              </label>
                              <FormControl required size="small">
                              <ComboSelectCFDI required /> 
                              </FormControl>
                            </div>
                            {/*Correo*/}
                            <div className="col" style={{marginLeft:"5px"}}>
                              <label className="form-label" htmlFor="correo" style={{marginBottom:"0px",textWrap:"nowrap"}}>
                              {AppManager.translator.current_lang === "es" ?  "Correo electrónico*" : "Email*"}
                              </label>
                              <input onChange={onChangeTextBoxEmail}
                                style={{marginTop:"-2px",fontSize: "11px !important", width: "100%"}}
                                className={`form-control ${classEmailValid}`}  
                                type="email"
                                name="correoFactura"          
                                pattern="^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$"                    
                                id="inputCorreoFactura"  
                              /> 
                            </div>
                          </div>
                          
                          {/*Forma de pago*/}
                          <div className="mb-1" style={{ marginLeft:"5px",display:"flex", flexDirection:"column", justifyContent: "" }}> 
                              <label className="form-label" htmlFor="pago" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Forma de pago*" : "Method of payment*"}
                              </label> 
                              <input
                                style={{ fontSize: "11px !important", width: "100%",opacity:"0.7"}}
                                className="form-control"  readOnly                             
                                id="inputNombreNext" 
                                defaultValue={datos.FormaPagoSAT}
                              /> 
                          </div> 
                    </form> 
                    </div>     

                    {/* ************************** */}
                    {/*   PASO 3 DESCARGA FACTURA    */}
                    {/* ************************** */}
                    <div
                      id="tabItem3_Factura"
                      className="tab-pane container fade px-sm-0 scrollbarCustom"
                      style={{padding:"0px",height:"420px",overflow: "scroll", overflowY: "auto",overflowX:"hidden"}}
                      role="tabpanel">
                      <form style={{paddingLeft:"5px",paddingRight:"5px"}} className="needs-validation " noValidate>
                        <div  className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*Folio*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}> 
                            <label className="form-label" htmlFor="folio" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ? "Folio*" : "Transaction ID*"} 
                            </label>
                            <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                              {datos.Folio}  
                            </span>
                            </div>
                            {/*Nombre*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" htmlFor="nombre" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Nombre*" : "Name*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                                {datos.NombrePaciente}
                              </span> 
                            </div>
                          </div>

                          <div  className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*RFC*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}> 
                              <label className="form-label" htmlFor="RFC" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "RFC*" : "Tax ID*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                                      
                                {valueRFC}
                              </span> 
                            </div>
                            {/*Régimen Fiscal*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" htmlFor="RegimenFiscal" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Régimen Fiscal*" : "Tax ID*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                                {valueRegimenFiscal}</span>
                            </div>
                          </div>
                        {/*Razón social*/}
                        <div className="mb-1" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                          <label className="form-label" htmlFor="razon" style={{marginBottom:"0px"}}>
                          {AppManager.translator.current_lang === "es" ? "Razón social*" : "Name/Company Name*"} 
                          </label>
                          <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>
                            {valueRazon}
                          </span>
                         </div>
                         
                         <div className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*estado*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}> 
                              <label className="form-label" htmlFor="estado" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Estado*" : "State*"}
                              </label> 
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                              {valueEntidad}</span>
                            </div>
                            {/*municipio*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" htmlFor="municipio" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Municipio*" : "Town*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                               {valueMunicipio}</span>
                            </div>
                             {/*cp*/}
                             <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" htmlFor="codigo" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Código Postal*" : "Postal Code*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                               {valueCP}</span>
                            </div>
                          </div>
                          
                         <div className="mb-1 row" style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                            {/*Uso del CFDI*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}> 
                              <label className="form-label" htmlFor="CFDI" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "CFDI*" : "Digital Tax Invoice*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>                        
                               {valueCFDI}</span>
                            </div>
                            {/*Correo*/}
                            <div className="col" style={{marginLeft:"5px",display:"flex",flexDirection:"column"}}>
                            <label className="form-label" htmlFor="correo" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Correo electrónico*" : "Email*"}
                              </label>
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>
                                {valueCorreo}</span>                              
                            </div>
                          </div>
                          
                          {/*Forma de pago*/}
                          <div className="mb-0" style={{marginLeft:"5px",display: "flex", flexDirection:"column", justifyContent: "" }}> 
                              <label className="form-label" htmlFor="pago" style={{marginBottom:"0px"}}>
                              {AppManager.translator.current_lang === "es" ?  "Forma de pago*" : "Method of payment*"}
                              </label> 
                              <span style={{fontSize:"13px",opacity:"0.7",minWidth:200}}>
                                {datos.FormaPagoSAT}</span> 
                          </div> 
                    </form> 
                    </div>

                  </div>
                  {/*CARGANDO */}
                  {isLoadingCFDI || isLoadingCP || isLoadingEntidades || 
                   isLoadingMunicipios || isLoadingRegimen || isLoadingTimbreFactura ||
                   isloadingEnvioCorreo || isLoadingDescargaFactura ?
                    <div style={{background:"#f9fafd",top:"180px",display: "flex",position:"absolute", height:"530px",opacity:"0.7", justifyContent: "center", alignItems: "center",left:"0px",width: "100%"}}>
                    <CircularProgress size={60}></CircularProgress>
                  </div> :
                  <></>
                  }
                </div>
              
              {/*FOOTER*/}
              {showBotonDescarga ?  
                <div className="mb-4" style={{display:"flex",justifyContent:"center"}}>
                     <button id="btnEnviaCorreo" onClick={onEnviarFacturaCorreo} style={{width:"200px"}} className="btn btn btn-primary" type="button"> 
                      <span data-language_txt="txt_EnviarCorreo">Enviar vía correo</span>
                    </button> 
                    <button id="btnDescargaFactura" onClick={onDescargarArchivosFactura} style={{marginLeft:"10px",width:"200px"}} className="btn btn btn-primary" type="button"> 
                      <span data-language_txt="txt_DescargaArchivos">Descargar PDF y XML</span>
                    </button> 
                </div>  :
                <></>
              }

              {showBotonCFDI && !showBotonDescarga ? 
              <div className="card-footer bg-light" style={{height: "4rem",display: "flex", flexDirection: "row",alignItems:"center"}}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <p style={{margin:"0px",overflow: "hidden"}}
                      id="btn_back" onClick={changeTabPrev}>
                      {" "}
                      {AppManager.translator.current_lang === "es" ?
                            (isMobile ? "Volver" :"Volver atrás") :
                            (isMobile ? "Back" : "Return")}
                  </p> 
                </div>
                <div style={{display:"flex",justifyContent:"center",flex:1}}>
                     <button id="btnGeneraCFDI" onClick={onTimbraFactura} style={{width:"180px"}} className="btn btn btn-primary" type="button"> 
                      <span data-language_txt="txt_GenerarCFDI">Generar CFDI</span>
                    </button> 
                </div>
              </div>  :<></>}
              {!showBotonCFDI && !showBotonDescarga? (
                <div className="card-footer bg-light" style={{ height: "4rem" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <p style={{maxHeight:"25px",overflow: "hidden"}}
                      id="btn_backhomepage"
                      onClick={() => {
                        closeModalFacturacion();
                      }}
                    >
                      {" "}
                      {AppManager.translator.current_lang === "es" ?
                            (isMobile ? "Regresar" :"Regresar a página principal") :
                            (isMobile ? "Back" : "Return to main page")}
                    </p> 
                    <ul className="pager wizard list-inline mb-0"> 
                      <li className="next" style={{ cursor: "pointer" }} onClick={changeTabNext}>
                        <button id="btnAgendaNext" style={{ marginLeft: "10px" }} className="btn btn-primary">
                          <div style={{alignItems:"center",display:"flex"}}>
                            <label data-language_lbl="lbl_Next" style={{overflow:"hidden",marginBottom:"0px"}}>Next</label>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-chevron-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
               
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalFacturacion;
